<template>
    <h3>{{ getLocalized().title }}</h3>
    <div v-html="getLocalized().html"></div>
</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import CategoryTreeItem from "@/components/CategoryTree/CategoryTreeItem.vue";

export default {
    name: "CompanyPage",
    props: {
        page: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            pagedata: {}
        }
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },

    mounted() {
        console.log('get company', toRaw(this.page));
        this.getCompany(this.page)
    },
    methods: {
        getLocalized() {
            var lang = this.store.lang;
            if (this.pagedata[lang])
                return this.pagedata[lang];
            return {title: '', html: ''};
        },
        getCompany(page) {
            console.log('getting company', toRaw(this.page));
            //var company = page;
            if (page.length == 0)
                return;
            this.$axios
                .get("/_pg/company." + this.page + "?c=1", {})
                .then((response) => {
                    console.log('got company', toRaw(response.data));
                    console.log(response.data['company.' + this.page]);
                    if (response.data.hasOwnProperty('company.' + this.page))
                        this.pagedata = response.data['company.' + this.page];
                    else
                        this.pagedata = response.data;
                });
        },
    }
}
</script>

<style scoped>
</style>
