import { createI18n } from "vue-i18n";
import pl from "./pl.json";
import en from "./en.json";

const i18n = createI18n({
  allowComposition: true,
  legacy: false,
  useScope: "global",
  //locale: navigator.language.split('-')[0],
  fallbackLocale: "pl",
  globalInjection: true,
  messages: {
    pl: pl,
    en: en,
  }, // set locale messages
});

export default i18n;
