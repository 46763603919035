<template>
    <tr v-if="item.itemcode"
        ref="tr" :class="{
        odd: Math.floor(item.no) % 2,
        loaded: loaded,
        mb: $isMobile(),
        product: !item.isalt,
        alt: item.isalt,
        alt100: item.match == 100,
        alt50: item.match >= 50,
        altshow: $parent.altshow.includes(item.origitem),
        }"
        class="outer">
        <td class="itemcode" rowspan="2"><a :href="'/p/'+item.itemcode">{{ item.itemcode }}</a> <input
                v-show="store.username" :id="'cust'+item.itemcode"
                ref="customerindex"
                v-model="customerindex"
                :class="{error: error}"
                :placeholder="$t('product.customerindex')"
                type="text"

                v-on:blur="customerIndexBlur">

        </td>

        <td>{{ item.partno }}</td>

        <td class="manufacturer"><a v-if="item.man_www" :href="'http://'+item.man_www" target="_blank">{{
            item.man_name
            }}</a><span
                v-else>{{
            item.man_name
            }}</span>
        </td>
        <td class="availability" rowspan="4">
            <table class="availability">
                <tr v-for="atdate in avail">
                    <th>{{ atdate.date }}</th>
                    <td class="quantity">{{ atdate.quantity }}</td>
                </tr>
            </table>
        </td>
        <td class="price" rowspan="4">cena</td>
        <td class="amount" rowspan="2">
            <input v-model="amount" :placeholder="1" class="amount" type="number"
                   @keypress="isNumber($event)"
            />
            <button class="addtobasket" @click="basketadd">
                <FontistoShoppingBasketAdd/>
            </button>
        </td>
        <td v-for="pid in showcolumnsorder" :key="pid" :data-pid="pid" rowspan="5">
      <span v-if="item.params[pid]"> {{ item.params[pid].val_string }}</span
      ><span v-else><!-- brak parametru -->&nbsp;</span>
        </td>
        <td class="filler" rowspan="5"></td>
    </tr>
    <tr v-if="item.itemcode"
        ref="tr"
        :class="{
        odd: Math.floor(item.no) % 2,
        loaded: loaded,
        mb: $isMobile(),
        product: !item.isalt,
        alt: item.isalt,
        alt100: item.match == 100,
        alt50: item.match >= 50,
        altshow: $parent.altshow.includes(item.origitem),
        }">
        <td class="itemname" colspan="2" rowspan="2">{{ item.itemname }}</td>
    </tr>
    <tr v-if="item.itemcode"
        ref="tr"
        :class="{
        odd: Math.floor(item.no) % 2,
        loaded: loaded,
        mb: $isMobile(),
        product: !item.isalt,
        alt: item.isalt,
        alt100: item.match == 100,
        alt50: item.match >= 50,
        altshow: $parent.altshow.includes(item.origitem),
        }">
        <td rowspan="3">
            <stock-image
                    v-if="item.imgs[0]"
                    :blend="true"
                    :row="'row' + item.num"
                    :src="'/_pimg/' + item.imgs[0] + '?' + Math.random()"
                    alt="Ilustracja produktu"
            />
            <stock-image
                    v-else-if="item.case_foto_code"
                    :row="'row' + item.num"
                    :src="'/_cimg/' + item.case_foto_code"
                    alt="Ilustracja obudowy"
            />
        </td>
        <td class="amount" rowspan="3"><p>{{ $t('product.moq') }}: {{ item.moq }}{{ item.unit }}</p>
            <p>{{ $t('product.multi') }}: {{ item.multi }}{{ item.unit }}</p>
            <p>{{ $t('product.spq') }}: {{ item.spq }}</p></td>

    </tr>
    <tr v-if="item.itemcode"
        ref="tr"
        :class="{
        odd: Math.floor(item.no) % 2,
        loaded: loaded,
        mb: $isMobile(),
        product: !item.isalt,
        alt: item.isalt,
        alt100: item.match == 100,
        alt50: item.match >= 50,
        altshow: $parent.altshow.includes(item.origitem),
        }">
        <td>
            <p v-if="item.withdrawn" class="withdrawn">{{ $t('product.withdrawn') }}</p>
            <p v-else-if="item.deprecated" class="deprecated">{{ $t('product.deprecated') }}</p>
            <p v-if="item.till_in_stock" class="till_in_stock">{{ $t('product.till_in_stock') }}</p>
        </td>

        <td>4/3</td>

    </tr>
    <tr v-if="item.itemcode"
        ref="tr"
        :class="{
        odd: Math.floor(item.no) % 2,
        loaded: loaded,
        mb: $isMobile(),
        product: !item.isalt,
        alt: item.isalt,
        alt100: item.match == 100,
        alt50: item.match >= 50,
        altshow: $parent.altshow.includes(item.origitem),
        }">
        <td class="pdf">
            <a :href="'/_pdf/'+item.product_pdfs_code" target="_blank">
                <teenyiconsPdfOutline v-if="item.product_pdfs_code"/>
            </a>
        </td>

        <td>5/3</td>
        <td><p v-show="item.specialorder" class="specialorder">{{ $t('product.specialorder') }}</p></td>
        <td class="availquery">
            <button @click="productQuery">{{ $t('product.availquery') }}</button>
        </td>

    </tr>
</template>

<script>
import stockImage from "@/components/Stock/StockImage.vue";
import {toRaw, inject} from "vue";
import {useStore} from "@/stores/page";
import FontistoShoppingBasketAdd from "@/components/Icons/FontistoShoppingBasketAdd.vue";
import teenyiconsPdfOutline from "@/components/Icons/TeenyiconsPdfOutline.vue";
import {openModal} from "jenesius-vue-modal";
import ProductQuery from "@/components/Product/ProductQuery.vue";

export default {
    name: "StockItem",
    components: {stockImage, FontistoShoppingBasketAdd, teenyiconsPdfOutline},
    props: {
        item: Object,
        headers: Object,
        hdrorder: Array,
        showcolumns: Array,
        showcolumnsorder: Array,
        no: Number,
        avail: Array,
        customerindex: String
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");

        return {store, mitt}
    },

    mounted() {
        this.loaded = false;
        setTimeout(() => {
            this.loaded = true;
        }, this.$isMobile() ? 0 : this.no * 200);
    },
    data() {
        return {
            loaded: false,
            selected: false,
            customerindex: "",
            customerindexold: "",
            amount: '',
            error: true
        }
    },
    methods: {
        productQuery() {
            openModal(ProductQuery, {itemcode: this.item.itemcode})
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        },
        basketadd() {
            this.mitt.emit('basketadd', {itemcode: this.item.itemcode, amount: this.amount ? Number(this.amount) : 1});
            console.log(this.item.itemcode, this.amount ? this.amout : 1);
        },
        toggleReplacements: function (itemcode) {
            if (!this.$parent.altshow.includes(itemcode))
                this.$parent.altshow.push(itemcode);
            else {
                var pos = this.$parent.altshow.indexOf(itemcode);
                if (~pos) this.$parent.altshow.splice(pos, 1);
            }
        },
        customerIndexBlur: function () {
            this.$axios
                .get('/_uci/' + this.item.itemcode, {params: {custindex: this.customerindex}});
            setTimeout(() => {
                this.error = true;
                this.$refs.customerindex.focus();
                console.log('focus set')
            }, 100)
        }
    },
    computed: {},
    watch: {
        avail() {

        }
    }
};
</script>

<style scoped>
tr.alt:not(.altshow) {
    display: none;
}

.showreplacements {
    text-decoration: underline;
    cursor: pointer;
}

tr.name td {
    text-align: left;
}

@media only screen and (min-width: 1280px) {
    tr.name td div {
        text-align: left;
        position: sticky;
        left: 2em;
        display: inline-block;
    }
}

span.match {
    float: left;
    border: 1px solid #f00;
}

td.name {
    text-align: left;
}

td.descr {
    text-align: left;
}

.pn {
    font-weight: bold;
}

.itemcode a {
    font-weight: normal !important;
}

.imgfiller {
    width: 5em;
    height: 4em;
}


tr.outer:not(.loaded) {
    opacity: 0;
}

tr.outer.loaded:not(.odd):not(.mb) {
    animation: showing 2s;
}

:not(.mb) tr.outer.loaded.odd:not(.mb) {
    animation: showingodd 2.5s;
}

:not(.mb) tr.outer.loaded:not(.mb) * {
    animation: showingin 2s;
}

@keyframes showingin {
    from {
        opacity: 0;
    }
    80% {;
        opacity: 0.5;
    }

    to {;
        opacity: 1;
    }
}

@keyframes showing {
    from {
        transform: translateX(100%);
    }
    to {;
        transform: translateX(0%);
    }
}

@keyframes showingodd {
    from {
        transform: translateX(100%);
    }
    to {;
        transform: translateX(0%);
    }
}

td {
    vertical-align: top;
}

.dark .itemcode input {
    color: #fff;
}

.itemcode input {
    border: none;
    background: rgba(128, 128, 128, 0.1);
}

.dev .outer td, .dev .outer th {
    border: 1px solid red;
}

.itemcode input {
    border: none;
}

td.amount {
    white-space: nowrap;
}

td.amount input {
    vertical-align: top;
    display: inline-block;
    font-size: 1.5em;
    padding: 0;
    margin: 0;
}

p.withdrawn, p.specialorder, p.deprecated, p.till_in_stock {
    color: #f00;
}

.dark input {
    background: #222;
    color: #ddd;
    border: 1px solid #666;
}

.dark button, .dark button * {
    background: #222;
    color: #ddd;

}

.manufacturer {
    white-space: nowrap;
}

.error {
    border: 1px solid red !important;
}

.itemname {
    white-space: nowrap;
}

button svg {
    width: 48px;
    height: 48px;
}

.addtobasket svg {
    height: 2.5em;
    width: 2.5em;
}

.pdf svg {
    height: 1.5em;
    width: 1.5em;
}

table.availability .quantity {
    text-align: right;
}

table.availability {
    border-collapse: collapse;
    zmargin: 0 0.3em;
}

td.amount input {
    text-align: right;
}

table.availability {
    width: 100%;
}

input.amount {
    width: 5em;
}

td.amount {
    text-align: right;
}

.availquery button {
    width: 100%;
}
</style>
