<template>


    <div id="logindialog" ref="logindialog">
        <!-- <GoogleLogin :callback="callback"/> -->
        <img alt="Logo ROPLA" class="logo" src="/img/ropla.svg"/><br/>
        <p v-show="error" class="error">{{ error }}</p>
        <p v-show="error" class="reset"><a :href="'/user/reset?mail='+$btoa(email)">{{ $t('user.resetpassword') }}</a>
        </p>
        <div>
            <table>
                <tr>
                    <td>
                        <input v-model="email" :placeholder="$t('user.email')"/></td>
                </tr>
                <tr>
                    <td><input v-model="password" :placeholder="$t('user.password')" type="password"></td>
                </tr>
                <tr>
                    <td>
                        <button @click="doLogin()">
                            <PhUserDuotone/>
                            {{ $t('user.login') }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td class="newaccount">
                        {{ $t('user.orcreate') }}
                        <a href="/user/create" @click="goCreate">{{ $t('user.create') }}</a>
                    </td>
                </tr>
            </table>
            <div class="google">
                <GoogleSignInButton
                        @error="handleLoginError"
                        @success="handleLoginSuccess"
                ></GoogleSignInButton>
            </div>

            <!--
            <div v-if="userDetails">
                <h2>User Details</h2>
                <p>Name: {{ userDetails.name }}</p>
                <p>Email: {{ userDetails.email }}</p>
                <p>Profile Picture: <img :src="userDetails.picture" alt="Profile Picture"></p>
            </div>-->
        </div>
    </div>
</template>
<script>
import {toRaw, nextTick, inject} from "vue";
import {closeModal, Modal} from "jenesius-vue-modal";
import {useStore} from "@/stores/page";
import {GoogleSignInButton} from "vue3-google-signin";
import PhUserDuotone from "@/components/Icons/PhUserDuotone.vue";
// eslint-disable-next-line
export default {
    name: "LoginWin",
    components: {
        GoogleSignInButton, PhUserDuotone
    },


    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        return {mitt, store};
    },
    watch: {},
    mounted() {
        if (this.mail)
            this.email = this.mail;
        console.log(this.mail);
    },
    props: {
        mail: String
    },
    data: function () {
        return {
            error: '',
            email: '',
            password: "",
        };
    },
    props: {
        mail: String
    },
    methods: {
        doLogin() {
            console.log('dologin')
            this.$axios.post('/_user/login', {
                    mail: this.email,
                    password: this.password
                }, {headers: {"Content-Type": "multipart/form-data"}},
            ).then((resp) => {
                if (resp.data.mail) {
                    this.store.username = resp.data.mail;
                    this.mitt.emit('closemodal');
                } else {
                    this.error = this.$t('user.loginfailed');
                }
            });
        },
        goCreate() {
            this.mitt.emit('closemodal');
        },
        handleLoginSuccess(response) {
            const {credential} = response;
            this.$axios.post('/_google/login', {idtoken: credential}).then((response) => {
                console.log(response);
                if (response.data.user) {
                    if (response.data.userpic)
                        this.store.userpic = response.data.userpic;
                    this.store.username = response.data.user;
                    this.mitt.emit("login");
                    this.$emit(Modal.EVENT_PROMPT, response.data);
                } else {
                    this.error = this.$t('user.loginfailed');
                }

            })
//            console.log("Access Token", credential);
        },
        handleLoginError() {
            console.error("Login failed");
        },
        callback(response) {
            // This callback will be triggered when the user selects or login to
            // his Google account from the popup
            console.log("Handle the response", response);
            const userData = decodeCredential(response.credential)
            console.log("Handle the userData", userData)
            console.log("Handle the Credential", response.credential)
            //this.$axios.post('/_google/', {clientId: response.clientId, credential: response.credential})
        },
        login() {
            googleSdkLoaded(google => {
                google.accounts.oauth2
                    .initCodeClient({
                        client_id:
                            "58730156701-di0.apps.googleusercontent.com",
                        scope: "email profile openid",
                        redirect_uri: "http://localhost:4000/auth/callback",
                        callback: response => {
                            if (response.code) {
                                this.sendCodeToBackend(response.code);
                            }
                        }
                    })
                    .requestCode();
            });
        },
        async sendCodeToBackend(code) {
            try {
                const headers = {
                    Authorization: code
                };
                const response = await axios.post("http://localhost:4000/auth", null, {headers});
                const userDetails = response.data;
                console.log("User Details:", userDetails);
                this.userDetails = userDetails;

                // Redirect to the homepage ("/")
                //this.$router.push("/rex");
            } catch (error) {
                console.error("Failed to send authorization code:", error);
            }
        }
    },
    computed: {},
}
</script>

<style scoped>
#logindialog {
    height: 13em;
    width: 20em;
    background: #fff;
    position: relative;
}

.dark #logindialog {
    background: #212121;
}

table td {

}

table button {
    float: right;
}

table {
    position: absolute;
    right: 0;
}

div.google {
    position: absolute;
    right: 0;
    top: 10em;
}

.logo {
    float: left;
}

.newaccount {
    position: absolute;
    right: 0;
    font-size: 0.5em;
}

.newaccount a {
    color: blue;
}

p.error {
    padding: 0;
    margin: 0;
    color: red;
    font-size: 0.8em;
}

p.reset {
    font-size: 0.6em;
}
</style>
