<template>
    <h2>{{ $t('basket.contents') }}
        <mdiBasketOutline/>
    </h2>
    <div v-show="alertcopy" class="copy">
        <CilCopy/>
    </div>
    <table v-if="Object.keys(store.basket).length">
        <tr>
            <td>
                <button :title="$t('basket.checkrevert')" @click="checkRevert" v-on:dblclick="checkAll"
                        @contextmenu.prevent="checkAll">
                    <ElCheck/>
                </button>
            </td>
            <td colspan="3"></td>
        </tr>


        <template v-for="(count,item) in store.basket">
            <tr>
                <td class="check"><input v-model="tocompare" :value="item" type="checkbox"></td>

                <td>{{ item }}</td>
                <td>
                    <stock-image
                            v-if="imgs[item]"
                            :blend="true"
                            :src="'/_pimg/' + imgs[item]"
                            alt="Ilustracja produktu"
                    />
                </td>
                <th><span v-if="Object.keys(itemnames).length && itemnames[item] && itemnames[item][store.lang]">{{
                    itemnames[item][store.lang]
                    }}</span></th>
                <td><input v-model="amount[item]" class=amount name=amount size=6 @keypress="isNumber($event)"></td>
                <td>
                    <button :title="$t('basket.accept')" @click="itemAccept(item)">
                        <ElOk/>
                    </button>
                    <button :title="$t('basket.remove')" @click="itemRemove(item)">
                        <ElRemove/>
                    </button>
                </td>
            </tr>
        </template>

        <tr>
            <th colspan="2">
                <button :disabled="tocompare.length <= 1" :title="$t('basket.compare')" @click="compare()">
                    <FluentMdl2Compare/>
                </button>
                <button :disabled="!Object.keys(store.basket).length" :title="$t('basket.generateurl')"
                        @click="basketSave()">
                    <FileIconsCurl/>
                </button>

            </th>
            <td colspan="2"></td>
        </tr>
        <tr v-show="basketid">
            <th colspan="2">{{ $t('basket.url') }}:</th>
            <td class="basketurl" colspan="2"><a ref="basketa">{{ basketurl + basketid }}</a></td>
            <td>
                <button v-show="basketid" :title="$t('basket.copy')" @click="basketCopy()">
                    <CilCopy/>
                </button>
            </td>
        </tr>
    </table>
    <h1 v-else>{{ $t('basket.empty') }}</h1>

</template>

<script>
import {select} from "select";
import {inject, ref, toRaw} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/stores/page.js";
import mdiBasketOutline from "@/components/Icons/MdiBasketOutline.vue";
import ElCheck from "@/components/Icons/ElCheck.vue";
import ElOk from "@/components/Icons/ElOk.vue";
import ElRemove from "@/components/Icons/ElRemove.vue";
import FluentMdl2Compare from "@/components/Icons/FluentMdl2Compare.vue";
import FileIconsCurl from "@/components/Icons/FileIconsCurl.vue";
import StockImage from "@/components/Stock/StockImage.vue";
import CilCopy from "@/components/Icons/CilCopy.vue";

export default {
    name: "BasketMain",
    components: {mdiBasketOutline, ElCheck, ElOk, ElRemove, FluentMdl2Compare, FileIconsCurl, CilCopy, StockImage},
    setup() {
        const route = useRoute();
        var getid = route.query.get ? route.query.get : '';
        const mitt = inject("mitt");
        const store = useStore();
        const basketa = ref();
        return {store, mitt, getid, basketa};
    },
    data() {
        return {
            itemnames: {},
            imgs: {},
            tocompare: [],
            amount: {},
            basketid: '',
            basketurl: (this.store.dev ? 'http://' : 'https://') + window.location.host + '/basket?get=',
            alertcopy: false,
        };
    },
    mounted() {

        if (this.getid) {
            this.basketid = this.getid;
            this.basketGet(this.basketid);
        }
        this.loadItems();
    },
    methods: {
        compare() {

            if (Object.keys(this.store.basket).length > 1) {

                var newquery = this.$route.query;
                var tocompare = []
                Object.keys(this.store.basket).forEach((item) => {
                    tocompare.push(item + ':' + (this.tocompare.includes(item) ? this.store.basket[item] : -this.store.basket[item]));
                })
                newquery.items = tocompare.join(',');
                this.$router.push({path: '/basket/compare', query: newquery});
                console.log(tocompare.join(','));
            }
        },
        async basketCopy() {

            var node = this.$refs.basketa.firstChild;
            var range = document.createRange();

            range.setStart(node, 0);
            range.setEnd(node, this.$refs.basketa.textContent.length); // here 0 and 4 is my location and length for the selection
            // if my string is "This is test string" in my case its must select "This"
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            if (navigator.clipboard)
                try {
                    await navigator.clipboard.writeText(this.basketurl + this.basketid);
                    this.alertcopy = true;
                    var that = this;
                    setTimeout(function () {
                        that.alertcopy = false
                    }, 500);
                } catch (err) {
                    console.error('Failed to copy: ', err);
                }

        },
        loadItems() {
            if (Object.keys(this.store.basket).length) {
                this.$axios.get('/_items', {params: {items: Object.keys(this.store.basket).join(',')}}).then((resp) => {
                    this.itemnames = resp.data.names
                    this.imgs = resp.data.imgs;
                })
                for (var item in this.store.basket) {

                    this.amount[item] = this.store.basket[item];
                    console.log('item', item, this.store.basket[item]);
                }
                console.log(this.amount);
            }
        },
        basketGet(id) {
            this.$axios.get('/_basket/get', {params: {basket: id}}).then((resp) => {
                console.log(resp);
                this.store.basket = resp.data;
                this.$router.replace('/basket');
                this.loadItems();
                this.mitt.emit('basketbounce');
            });
        },
        basketSave() {

            this.$axios.get('/_basket/save', {params: {basket: Object.keys(this.store.basket).map(key => key + "=" + this.store.basket[key]).join(",")}}).then((resp) => {
                console.log(resp);
                this.basketid = resp.data.basket;

            });

        },

        itemAccept(item) {
            var data = {itemcode: item, amount: Number(this.amount[item])};
            this.mitt.emit('basketset', data);
        },
        itemRemove(item) {
            var data = {itemcode: item};
            this.mitt.emit('basketdel', data);

        },
        checkRevert() {
            this.tocompare = Object.keys(this.store.basket).filter(item => !this.tocompare.includes(item))
        },
        checkAll() {
            if (this.tocompare.length)
                this.tocompare = {};
            else
                this.tocompare = this.store.basket;
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        },
        basketadd() {
            this.mitt.emit('basketadd', {itemcode: this.item.itemcode, amount: this.amount ? Number(this.amount) : 1});
            console.log(this.item.itemcode, this.amount ? this.amout : 1);
        },
    }
}
</script>

<style scoped>
.amount {
    text-align: right;
}

.check {
    text-align: center;
    border: 1px solid #000;
}

.basketurl a {
    user-select: all;
}

h2 svg {
    width: 48px;
    height: 48px;
}

.copy svg {
    width: 56px;
    height: 56px;
    z-index: 9;
    position: absolute;
    left: 50vw;
    top: 50vh;
}

</style>