<template>
    <pager-line :key="totalitems" :totalitems="totalitems" me="'top'"/>
    <div v-if="!$isMobile()" id="topscroller" ref="topscroller" @scroll="scroll">
        <div ref="topscrollerfillter" :style="'width:' + bottomwidth + 'px'">
            &nbsp;
        </div>
    </div>
    <div id="scroller" ref="scroller">

        <table ref="ptable" class="products" @scroll="scroll">
            <thead>
            <tr :class="{ tall: isHeaderVisible }">
                <th class="itemno sticky">
                    {{ $t("product.no") }}
                </th>
                <th
                        :class="
              sort == 'itemcode' ? (sortasc ? 'sort-asc' : 'sort-desc') : ''
            "
                        class="descr sticky sort"
                        @click="setsortspecial('itemcode')"
                >
                    {{ $t("product.mfrpartno") }}<br/>{{ $t("product.roplaindex") }}
                </th>
                <th class="name sticky">{{ $t("product.quantities") }}</th>
                <th class="manufacturer">
                    {{ $t("product.manufacturer") }}
                </th>
                <th class="filler"></th>
            </tr>
            </thead>
            <tbody>
            <stock-item
                    v-for="(item, index) in products"
                    :key="index"
                    :item="products[index]"
            />
            <tr class="last">
                <!--  v-observe-visibility="lastVisible" -->
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
    <pager-line :key="totalitems" :totalitems="totalitems" me="'bottom'"/>
</template>

<script>
import {useStore} from "@/stores/page";
import {nextTick, ref, inject} from "vue";
import {useRoute} from "vue-router";
//import StockManSearchItem from "@/components/Stock/StockManSearchItem.vue";
import StockItem from "@/components/Stock/StockItem.vue";
import PagerLine from '@/components/PagerLine.vue';

export default {
    name: "StockManSearch",
    components: {
        StockItem, PagerLine
    },
    data: function () {
        return {
            products: [],
            totalitems: 0,
            pp: 25,
            page: 1,
            altshow: [], /* fixme */
        }
    },
    props: {
        mfrcode: Number
    },
    setup() {
        const route = useRoute();
        var page = ref(1);
        if (route.query.p) page = ref(parseInt(route.query.p));
        const store = useStore();
        const devlog = inject('devlog');
        return {store, page, devlog}
    },
    methods: {
        search() {
            this.$axios.get("/_mfr/", {
                params: {
                    m: this.mfrcode,
                    l: this.store.lang,
                    pp: this.pp,
                    p: this.page
                }
            }).then((resp) => {
                this.products = resp.data.products;
                this.totalitems = resp.data.total;
                delete this.$root.coverstages["searchproducts"];
            });
        }
    },
    mounted() {
        this.$root.coverstages["searchproducts"] = "loading.searchproducts";
        this.search();
    },
    watch: {
        $route(to) {
            if (to.query.pp) {
                this.pp = ref(parseInt(to.query.pp))
            } else {
                this.pp = 25
            }
            ;
            if (to.query.p) {
                this.page = parseInt(to.query.p)
            } else {
                this.page = 1
            }
            ;
            this.search();
        },
        page() {
            this.search();
        },
        pp() {
            this.search();
        },
    }
}
</script>

<style scoped>

</style>
