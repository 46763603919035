<template>

    <nav
            v-show="distIsOpen && left"
            id="distributions"
            ref="distributions"
            v-click-away="closeMenu"

            class="distributions"
            @mouseleave="distOpenSend({OnOff:false})"
            @mouseover="distOpenSend({OnOff:true})"
    >

        <ul v-show="left>0" ref="menu" :style="'margin-left: ' + left+'px; top: ' + topbarHeight"
            class="menu">
            <li v-for="(data,companyname) in dist" :class="{selected: company==companyname}">
                <a v-if="data[store.lang]"
                   :href="'/dist/'+companyname.replace('distributions.','')+(l ? '?l='+l : '')">{{
                    data[store.lang].title
                    }}</a>
            </li>
        </ul>
    </nav>
</template>vccvnb kkkkk1`q

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import {useRoute} from "vue-router";

export default {
    name: "DistributionsMenu",
    setup() {
        const store = useStore();
        const route = useRoute();
        const mitt = inject("mitt");
        var company;
        if (route.path.startsWith("/dist"))
            company = route.params.company;
        else
            company = '';
        var l = route.query.l ? route.query.l : '';
        return {store, mitt, company, l};
    },
    data: function () {
        return {
            dist: [],
            distOpen: null,
            distIsOpen: false,
            left: 0,
            top: 0,
            topbarHeight: '61px'
        }
    },
    mounted() {
        this.getDist();
        try {
            //this.topbarHeight = this.$parent.$refs.topbar.clientHeight + "px";
        } catch (e) {
            console.log(e);
        }
        this.mitt.on("chlang", (lang) => {
        })
        this.mitt.on("menuClose", () => {
            this.distIsOpen = false;
        });
        this.mitt.on("distOpen", (state) => {
            var that = this;
            var OnOff = state.OnOff;
            if (state.left)
                this.left = state.left;
            this.top = state.top;
            if (state.OnOff || (typeof state == 'boolean' && state)) {

                that.distIsOpen = true;

                if (that.distOpen) {
                    clearTimeout(that.distOpen);
                    that.distOpen = null;
                }
            } else {
                if (that.distOpen) {
                    clearTimeout(that.distOpen);
                    that.distOpen = null;
                }
                that.distOpen = setTimeout(() => {
                    that.distIsOpen = false;
                }, 500);
            }
        });
    },
    methods: {
        getDist() {
            this.$axios
                .get("/_pg/distributions/?c=0", {})
                .then((response) => {
                    this.dist = response.data;
                });
        },
        closeMenu() {
            this.distOpen = null;
            this.distIsOpen = false;
        },
        distOpenSend: function (isOver) {
            this.mitt.emit("distOpen", isOver);
        },
    }
}
</script>

<style scoped>
.distributions .menu {
    background: #e6e9eb;
    color: #fff;

    padding: 0;
    top: 0px;
    position: fixed;
    z-index: 9;
}

.distributions ul {
    list-style-type: none;

}

@media (min-width: 1600px) {
    .distributions .menu {

    }
}

.dark .distributions .menu {
    background: #353535;
    color: #fff;
    margin-left: -5vw;
}

ul {
    background: #e6e9eb;
    color: #fff;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    z-index: 4;
}

.dark ul {
    background: #353535;
    color: #fff;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    display: inline-block;
    width: 100%;
}

li:hover {
    background: #aaa;
}

.dark li:hover {
    background: #555;
}

li {
    cursor: pointer;
}

#demo {
    display: none;
    position: absolute;
    top: 15vh;
    z-index: 99;
    background: #fff;
    color: #000;
    height: 20vh;
    overflow: auto;
}

.selected {
    background: #aaa;
}

.dark .selected {
    background: #555;
}

xmp {
    overflow-y: scroll;
    min-height: 20vh;
    z-index: 99;
    position: fixed;
    right: 0px;
    bottom: 0px;
    background: #fff;
    color: #000;
}
</style>
