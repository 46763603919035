<script>
export default {
	name: 'CilCopy',
};
</script>
<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
		<path fill="currentColor" d="M408 432h-32v32H112V136h32v-32H80v392h328z" />
		<path fill="currentColor" d="M176 16v384h320V153.373L358.627 16Zm288 352H208V48h104v152h152Zm0-200H344V48h1.372L464 166.627Z" />
	</svg>
</template>
