<template>
    <div class="pages">
        <span
                v-for="(page, index) in pages"
                :key="index"
                :class="page.class"
                :href="page.uri"
                :label="$t(page.name)"
                command="categories"
        >
            <a v-if="page.name == 'pages.products'" :href="page.uri"
               @mouseleave="menu('prod', false)" @mouseover="menu('prod', true)">{{
                $t(page.name)
                }}</a>
            <a v-else-if="page.name == 'pages.distributions'" ref="distmenu" :href="page.uri"
               @mouseleave="menu('dist',false)" @mouseover="menu('dist',true)">{{
                $t(page.name)
                }}</a>
            <a v-else-if="page.name == 'pages.company'" ref="companymenu" :href="page.uri"
               @mouseleave="menu('company',false)" @mouseover="menu('company',true)"
            >{{
                $t(page.name)
                }}</a>

            <a v-else :href="page.uri">
                {{ $t(page.name) }}
                <div
                        style="
            text-align: center;
            padding: 0;
            margin-top: -0.3em;
            font-size: 2em;
          "
                ></div>
            </a>
        </span>
    </div>
</template>

<script>
import {inject} from "vue";
import {useStore} from "@/stores/page";

export default {
    name: "pagesBar",
    props: {
        category: String,
    },
    components: {},
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },
    data() {
        return {
            pages: [
                {uri: "/s/", name: "pages.products", class: "products"},
                {uri: "/company/about", name: "pages.company", class: "us"},
                {uri: "/dist/", name: "pages.distributions", class: "dists"},
                {uri: "/contact/", name: "pages.contact"},
            ],
            short: false,
            menuOver: false,
        };
    },
    computed: {
        langpages: function () {
            var langpages = [];
            for (var i = 0; i < this.pages.length; i++) {
                if (this.pages[i].lang == this.$parent.lang)
                    langpages.push(this.pages[i]);
            }
            return langpages;
        },
    },
    mounted() {
        this.mitt.on('i18n', () => {
            console.log('i18n changed');
        });
    },
    methods: {
        menu: function (menu, OnOff) {
            var rect;
            if (menu == 'company') {
                rect = this.$refs.companymenu[0].getBoundingClientRect();
                if (OnOff) {
                    this.mitt.emit("menuClose");
                }
                this.mitt.emit("compOpen", {
                    OnOff: OnOff,
                    left: Math.round(rect.x),
                    top: Math.round(rect.y + rect.height),
                });

            } else if (menu == 'dist') {
                if (OnOff) {
                    this.mitt.emit("menuClose");
                }
                rect = this.$refs.distmenu[0].getBoundingClientRect();
                this.mitt.emit("distOpen", {
                    OnOff: OnOff,
                    left: Math.round(rect.x),
                    top: Math.round(rect.y + rect.height),
                });

            } else {
                if (OnOff) {
                    this.mitt.emit("menuClose");
                }
                this.mitt.emit("catOpen", OnOff);
            }
        },
    },
};
</script>

<style scoped>
.pages {
    display: block;
    grid-auto-flow: column;
    vertical-align: top;
    align-items: top;
    width: 50vh;
    margin: 0;
    white-space: nowrap;
    flex: 2;
}

/*
.pages .products:not(:hover) .item {
  display: none;
}*/
div.pages {
    padding: 0;
    line-height: 2em;
    padding-bottom: 0.5em;
}

.pages .products::hover {
    display: block;
    z-index: 5;
    position: absolute;
    left: 1em;
}

a {
    color: #fff;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    display: inline;
    cursor: pointer;
    margin-left: 1em;
}

.mobile li:first-child a {
    margin-left: 0;
}

a:hover {
    text-decoration: underline;
}

li {
    list-style-type: none;
    display: inline-block;
}

@media screen and (max-width: 1279px) {
    .pages {
        flex-basis: 100%;
    }
}

</style>
