<template>

    <div v-for="(data,company) in dist" v-if="store.lang">
        <h3 v-if="data[store.lang]">{{ data.title }}</h3>
        <div v-if="data[store.lang]" v-html="data.html"></div>
        <hr v-if="data[store.lang]"/>
    </div>
</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import CategoryTreeItem from "@/components/CategoryTree/CategoryTreeItem.vue";
import reditor from "@/components/REditor.vue";

export default {
    name: "DistributionsPages",
    components: {reditor},
    props: {},
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },
    data: function () {
        return {
            dist: {},
            comment: ''
        }
    },
    mounted() {
        this.getDist();
    },
    methods: {
        getDist() {
            this.$axios
                .get("/_pg/distributions?c=1", {})
                .then((response) => {

                    this.dist = response.data;
                });
        },

    }
}
</script>

<style scoped>
</style>
