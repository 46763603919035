<template>
    <div v-if="$route.query.q">
        {{ $t('search.term') }}: {{ $route.query.q }}
    </div>
    <span id="buttons">
    <button
            v-if="!$isMobile()"
            :title="$t('product.mngcolumnsandfilters')"
            class="mng-columns"
            @click="mitt.emit('mngcolumns')"
    >
      <FaSolidCogs/>
    </button>
    <button
            :title="$t('filter.remove')"
            class="remove-filters"
            @click="mitt.emit('removefilters')"
    >

      <ClarityFilterLine/>
    </button>
    <button
            :title="shownfilters ? $t('filter.hide') : $t('filter.show')"
            class="toggle-filters"
            @click="mitt.emit('togglefilters')"
    >
      <ClarityEyeHideLine v-if="shownfilters"/>
      <ClarityEyeShowSolid v-else/>
    </button>
  </span>
    <category-tree-bread-crumb :path="path"/>
    <stock-filters @filterschanged="filterschanged"/>
    <category-tree-leafs/>
  <!-- <div class="ppgroup">
    <div class="pp">
      <label for="pptop">{{ $t("product.perpage") }}</label
      ><select v-model="pp" id="pptop">
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option></select
      ><label for="pp"></label>
    </div>
    <span class="ppitems">{{
      $t("product.positions", {
        from: this.page * this.pp - (this.pp - 1),
        to:
          this.totalitems > this.page * this.pp
            ? this.page * this.pp
            : this.totalitems,
        total: this.totalitems,
      })
    }}</span>
    <v-pagination
      v-model="page"
      :pages="Math.ceil(totalitems / pp)"
      :range-size="1"
      active-color="#4383af"
    />
  </div>-->

    <pager-line :key="totalitems" :totalitems="totalitems" me="'top'"/>
    <div id="observer" v-track-scroll="onTrackUpdate"></div>
    <stock-image-zoom/>
    <div v-if="!$isMobile()" id="topscroller" ref="topscroller" @scroll="scroll">
        <div ref="topscrollerfillter" :style="'width:' + bottomwidth + 'px'">
            &nbsp;
        </div>
    </div>
    <div id="scroller" ref="scroller">
        <!-- @update:modelValue="updateHandler" -->
        <table ref="ptable" class="products" @scroll="scroll">
            <thead>
            <tr :class="{ tall: isHeaderVisible }">
                <th
                        :class="
              sort == 'itemcode' ? (sortasc ? 'sort-asc' : 'sort-desc') : ''
            "
                        class="descr sticky sort"
                        @click="setsortspecial('itemcode')"
                >
                    {{ $t("product.roplaindex") }}
                </th>
                <th class="name sticky">{{ $t("product.descr") }}</th>
                <th class="manufacturer">
                    {{ $t("product.manufacturer") }}
                </th>
                <th class="availability">{{ $t('product.availability') }}</th>
                <th class="price">{{ $t('product.netprice') }}</th>
                <th class="amount">{{ $t('product.amount') }}</th>

                <th v-for="pid in showcolumnsorder" :key="pid" class="headers">
                    <div
                            :class="sort == pid ? (sortasc ? 'sort-asc' : 'sort-desc') : ''"
                            :content="headers[pid].desc"
                            class="sort"
                            @click="setsort(pid)"
                    >
                        <span v-html="headers[pid].name"></span>
                        <div
                                v-if="headers[pid].symbol"
                                class="symbol"
                                v-html="' [' + headers[pid].symbol + ']'"
                        ></div>
                    </div>
                    <!-- <div class="headmove"><button @click="move('oneleft', header)" :title="$t('product.oneleft')">🡐</button><button @click="move('oneright', header)" :title="$t('product.oneright')">🡒</button></div>
                  <div class="headmove"><button @click="move('toleft', header)" :title="$t('product.toleft')">⭰</button><button @click="move('toright', header)" :title="$t('product.toright')">⭲</button></div> -->
                </th>
                <th class="filler"></th>
            </tr>
            </thead>
            <tbody>
            <stock-item
                    v-for="(item, index) in stock"
                    :key="index"
                    :avail=avail[item.itemcode]
                    :customerindex=customerindex[item.itemcode]
                    :hdrorder="hdrorder"
                    :headers="headers"
                    :item="stock[index]"
                    :no="index"
                    :showcolumnsorder="showcolumnsorder"
            />
            <tr class="last">
                <!--  v-observe-visibility="lastVisible" -->
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
    <pager-line :key="totalitems" :totalitems="totalitems" me="'bottom'"/>
  <!-- <div class="ppgroup">
    <label for="pp">{{ $t("product.perpage") }}</label
    ><select v-model="pp">
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <v-pagination
      v-model="page"
      :pages="Math.ceil(totalitems / pp)"
      :range-size="1"
      active-color="#4383af"
    />
  </div> -->
</template>

<script>
// eslint-disable-next-line
import {inject, nextTick, ref, toRaw} from "vue";
import PagerLine from '@/components/PagerLine.vue';
import {useStore} from "@/stores/page";
import {useFiltersStore} from "@/stores/filters";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {VueTrackDirective} from "vue3-track";
import CategoryTreeBreadCrumb from "@/components/CategoryTree/CategoryTreeBreadCrumb.vue";
import CategoryTreeLeafs from "@/components/CategoryTree/CategoryTreeLeafs.vue";
import stockItem from "@/components/Stock/StockItem.vue";
import stockFilters from "@/components/Stock/StockFilters.vue";
import {useRoute, useRouter} from "vue-router";
import StockImageZoom from "@/components/Stock/StockImageZoom.vue";
import StockMngColAndFi from "@/components/Stock/StockMngColAndFi.vue";
import {openModal} from "jenesius-vue-modal";
import {config} from "jenesius-vue-modal";
import faSolidCogs from "@/components/Icons/FaSolidCogs.vue";
import ClarityEyeHideLine from "@/components/Icons/ClarityEyeHideLine.vue";
import ClarityEyeShowSolid from "@/components/Icons/ClarityEyeShowSolid.vue";
import ClarityFilterLine from "@/components/Icons/ClarityFilterLine.vue";
import ClarityEyeHideSolid from "@/components/Icons/ClarityEyeHideSolid.vue";
//import type { UseScrollReturn } from "@vueuse/core";
config({
    animation: "fade", // Any name
});
export default {
    name: "StockIndex",
    components: {
        StockImageZoom,
        CategoryTreeBreadCrumb,
        CategoryTreeLeafs,
        stockItem,
        stockFilters,
        VPagination,
        PagerLine,
        faSolidCogs, ClarityEyeHideLine, ClarityEyeShowSolid, ClarityEyeHideSolid, ClarityFilterLine
    },
    directives: {
        trackScroll: VueTrackDirective,
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        const route = useRoute();
        const router = useRouter();
        const ptable = ref(null);
        const topscroller = ref(null)
        const storefilters = useFiltersStore();
        const $devlog = inject('$devlog');

        var sort = "";
        var sortasc = true;
        if (route.query.s && route.query.s.length && route.query.s[0] == "-") {
            sortasc = false;
            sort = route.query.s.substring(1);
        } else sort = route.query.s;
        var page = ref(1);
        if (route.query.v && route.query.v.length == 0) {
            delete route.query.v;
        }
        var query = '';
        if (route.query.q) query = route.query.q;
        if (route.query.p) page = ref(parseInt(route.query.p));
        if (route.path.indexOf("%2F") != -1)
            router.replace({
                path: route.path.replace("%2F", "/"),
                query: route.query,
            });
        return {mitt, store, storefilters, sort, sortasc, page, ptable, $devlog, query, topscroller};
    },
    data: function () {
        return {
            renderComponent: true,
            path: "",
            pathName: "",
            pageitems: [],
            totalitems: 0,
            headers: {},
            hdrorder: [],
            hdrorderorg: [],
            idx2head: {},
            idx2desc: {},
            idx2symbol: {},
            stock: [],
            fixedthead: false,
            short: false,
            matching: 0,
            altshow: [],
            imagetimer: null,
            filters: [],
            ranges: [],
            getstocktimer: null,
            vids: [],
            isHeaderVisible: true,
            pp: 25,
            imagetimeout: null, // used in stockimage
            restore: {},
            showcolumns: [],
            bottomwidth: 0,
            shownfilters: true,
            avail: {},
            customerindex: {}
        };
    },
    props: ["searchData", "testy"],
    mounted() {
        // remove saved if on mobile
        if (this.$isMobile() && this.pidsettings && this.pidsettings[this.path])
            this.removepidsettings();
        var vids = [];
        var that = this;

        this.path = this.$route.path
            .replace("%2F", "/")
            .replace(/\/+/, "/")
            .replace(/^\/s\/*/, "")
            .replace(/\/+$/, "");
        this.restore = toRaw(this.store.pidsettings[this.path]);
        if (this.$route.query.pp) this.pp = parseInt(this.$route.query.pp);
        this.mitt.on("gotfilters", (o) => {
            that.filters = o;
            console.log('gotfilters', o);
        });
        /*
                this.mitt.on("mngchange", () => {
                    //console.log("mngchange");
                    //this.getProducts(this.vids);
                    this.mitt.emit("getfilters", {});
                });*/
        this.mitt.on("getproducts", (o) => {
            var vids;
            var ranges;
            if (o.path) this.path = o.path;
            if (o.sort) this.sort = o.sort;
            if (o.ranges) ranges = o.ranges;
            if (o.vids) vids = o.vids;
            else if (this.$route.query.v && this.$route.query.v.length)
                vids = this.$route.query.v.split(",");
            else vids = [];
            var newquery;
            if (
                (!this.$route.query.v && vids.length) ||
                (!this.$route.query.r && Object.keys(ranges).length)
            ) {
                newquery = {...this.$route.query};
                newquery.v = vids.join(",");
                newquery.s = this.sort;
                newquery.r = JSON.stringify(ranges);
                this.$router.push({query: newquery});
            }
            this.debounce(() => {
                this.getproducts(o);
            });
        });
        this.mitt.on("mngcolumns", () => {
            this.openMngModal();
        });
        this.mitt.emit("getfilters", vids);
        this.mitt.on("togglefilters", () => {
            this.shownfilters = !this.shownfilters;
        });
    },
    watch: {
        page(to) {
            var newquery = {...this.$route.query};
            nextTick(() => {
                if (to) {
                    if (to > 1 && parseInt(to) != parseInt(newquery.p)) newquery.p = to;
                    else delete newquery.p;
                    this.$devlog("route", "watch page");
                    this.$router.push({query: newquery});
                }
            });
        },
        pp(to) {
            var newquery = {...this.$route.query};
            if (parseInt(to) != parseInt(newquery.pp) || to == 25) {
                if (to == 25) delete newquery.pp;
                else newquery.pp = to;
                this.$devlog("route", "watch pp");
                this.$router.push({query: newquery});
            }
        },
        $route(to, from) {
            this.store.path = this.path = to.path
                .replace(/\/+/, "/")
                .replace(/^\/s\/*/, "")
                .replace(/\/+$/, "");

            this.stock = [];
            this.getProducts([]);
            delete from.p;
            delete to.p;
            delete from.pp;
            delete to.pp;
            if (!this.$objectEquality(from, to)) {
                this.mitt.emit("getfilters", []);
            }
        },
    },
    computed: {
        showcolumnsorder() {
            var columns = [];
            for (var i = 0; i < this.hdrorder.length; i++) {
                var pid = this.hdrorder[i];
                if (
                    this.headers[pid] &&
                    (this.showcolumns.includes(pid) || this.showcolumns.length == 0)
                )
                    columns.push(pid);
            }
            return columns;
        },
    },
    methods: {
        removepidsettings() {
            delete this.store.pidsettings[this.path];
            this.$router.replace();
        },
        scroll(ev) {
            if (ev.target == this.$refs.ptable && this.$refs.topscroller) {
                this.$refs.topscroller.scrollLeft = this.$refs.ptable.scrollLeft;
            } else {
                if (this.$refs.ptable && this.$refs.topscroller)
                    this.$refs.ptable.scrollLeft = this.$refs.topscroller.scrollLeft;
            }
        },
        gotfilters(filters) {
            this.filters = filters;
        },
        openMngModal() {
            openModal(StockMngColAndFi, {
                headers: this.headers,
                hdrorder: this.hdrorder,
                hdrorderorg: this.hdrorderorg,
                filters: this.filters,
            });
        },
        setsortspecial(what) {
            if (this.sort == what) this.sortasc = !this.sortasc;
            else this.sortasc = true;
            this.sort = what;
            var newquery = {...this.$route.query};
            if (what == "itemcode") newquery.s = this.sortasc ? "" : "-";
            this.$devlog("route", "setsortspecial");
            this.$router.push({query: newquery});
        },
        setsort(pid) {
            if (this.sort == pid) this.sortasc = !this.sortasc;
            else this.sortasc = true;
            this.sort = pid;
            var newquery = {...this.$route.query};
            newquery.s = this.sortasc ? pid : -pid;
            this.$devlog("route", "setsort");
            this.$router.push({query: newquery});
        },
        filterschanged(params) {
            this.vids = params.vids;
            this.ranges = params.ranges;
            if (this.getstocktimer) {
                clearTimeout(this.getstocktimer);
                this.getstocktimer = null;
            }
            this.getstocktimer = setTimeout(() => {
                this.getProducts();
            }, 1000);
        },
        getProductsData() {
            this.$root.coverstages["itemdata"] = "loading.itemdata";
            this.$axios
                .get("/_pd/", {
                    params: {ics: this.stock.map(i => i.itemcode).join(',')}
                }).then((resp) => {
                delete this.$root.coverstages["itemdata"];
                this.avail = resp.data.avail;
                if (resp.data.customerindex)
                    this.customerindex = resp.data.customerindex;

                console.log('avail', resp.data.avail);
            });
        },
        getProducts: function () {
            this.$root.coverstages["products"] = "loading.products";
            var that = this;

            this.$axios
                .get("/_p/" + this.path, {
                    params: {
                        q: this.$route.query.q,
                        v: this.$route.query.v,
                        r: this.$route.query.r,
                        s: this.$route.query.s,
                        p: this.$route.query.p,
                        l: this.store.lang,
                        pp: this.pp
                    },
                })
                .then((resp) => {
                    delete this.$root.coverstages["products"];
                    if (!resp.data.products) {
                        console.log("/_p returned no data");
                        return;
                    }
                    this.totalitems = resp.data.total;
                    if (parseInt(this.page) > Math.ceil(this.totalitems / this.pp) + 1) {
                        this.page = Math.ceil(this.totalitems / this.pp);
                    }
                    var stock = resp.data.products;
                    var head = resp.data.headers;


                    if (
                        this.store.pidsettings[this.path] &&
                        this.store.pidsettings[this.path].order
                    ) {
                        this.hdrorderorg = resp.data.order;
                        // https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
                        this.hdrorder = [
                            ...new Set(
                                this.store.pidsettings[this.path].order.concat(resp.data.order)
                            ),
                        ];
                        //console.log('hdrorderorg', toRaw(this.hdrorderorg));
                        //console.log('hdrorder', toRaw(this.hdrorder));
                        this.hdrorder = this.hdrorder.filter((item) =>
                            this.hdrorderorg.includes(item)
                        );
                        //console.log("hdrorder", toRaw(this.hdrorder));
                        if (this.store.pidsettings[this.path].display)
                            this.showcolumns = this.store.pidsettings[this.path].display;
                    } else this.hdrorder = this.hdrorderorg = resp.data.order;

                    this.stock = stock;
                    this.getProductsData();
                    if (stock.length) this.matching = stock[0].total;
                    else this.matching = 0;
                    this.headers = head;
                    nextTick(() => {
                        //that.bottomwidth = that.$refs.scroller.scrollWidth;
                        if (that.$refs.ptable)
                            that.bottomwidth = that.$refs.ptable.scrollWidth;

                        //this.openMngModal();
                    });
                });
        },
        visibilityChanged() {
            console.log("widac mnie?");
            //console.log("widac mnie?", isVisible, entry);
        },
        onTrackUpdate(position, visibility) {
            //console.log("pos", position, "vis", visibility);
            if (visibility.vertical && !this.isHeaderVisible) {
                this.isHeaderVisible = true;
            } else {
                this.isHeaderVisible = false;
            }
        },
    },
};
</script>

<style>
button svg {
    width: 16px;
    height: 16px;
}

table.products {
    margin: 0;
    min-width: 80%;
    border-collapse: collapse;
    width: 100%;

    position: relative;
    display: block;
    padding: 0;
}

table.products thead tr {
    background: #EEEEEE;
}

table.products tbody {
    margin: 0;
    padding: 0;
}

table.products > thead > tr > th, table.products > tbody > tr > td {
    padding: 0 0.5em;
}

table.products > thead > tr > th:first-child, table.products > tbody > tr > td:first-child {
    padding-left: 0;
}

table.products thead th {
    border-bottom: 1px solid #000;
}

table.products thead tr {
}

.dark table.products thead tr {
    background: #383838;
}

table.products thead th.sticky {
    z-index: 4;
}

@media only screen and (min-width: 1600px) {
    table.products {
        max-height: calc(100vh - 4.5em);
        overflow-y: scroll;
    }

    table.products thead th {
        position: sticky;
        border: 20x solid green;
    }

    table.products tbody {
        overflow: scroll;
        overflow-y: scroll;
        bottom: 40px;
    }

    table.products .sticky {
        position: sticky;
    }
}

table.products {
    padding: 0;
    margin: 0;
}

table.products .sticky {
    margin: 0;
    padding: 0;
    margin-top: 2em;
    background: #eee;
}

/* tło nagłówka */
table.products thead th,
table.products th.sticky {
    vertical-align: top;
    text-align: left;
    background: #eee;
    top: 0;
    z-index: 1;
}

.dark table.products thead th,
.dark table.products th.sticky {
    background: #383838;
}

/* tło  kolumn parzystych */
table.products > tbody > tr:not(.odd) > td,
table.products > tbody > tr > td.sticky {
    background: #eee;

}

.dark table.products > tbody > tr:not(.odd) > td,
.dark table.products > tbody > tr > td.sticky {
    background: #383838;
}


.dark table.products {
    border: 1px solid #666;
}

/* tło pierwszej kolumny nieparzystych */
table.products .odd .sticky {
    background: #fff;
}

.dark table.products .odd .sticky {
    background: #212121;
}

table.products .sticky:nth-child(1) {
    left: 0;
    width: 2em;
    min-width: 2em;
    max-width: 2em;
}

table.products .sticky:nth-child(2) {
    width: 14em;
    min-width: 14em;
    max-width: 14em;
    left: 2em;
}

table.products .sticky:nth-child(3) {
    width: 6em;
    min-width: 7em;
    max-width: 7em;
    left: 16em;
}

table.products a {
    color: #000;
    font-weight: bold;
}

.dark table.products a {
    color: #fff;
    font-weight: bold;
}

#scroller {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: initial;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

body {
    scrollbar-width: initial;
}

.sort {
    cursor: pointer;
}

.sort-desc::before {
    content: "▼";
    color: #e30016;
}

.sort-asc::before {
    content: "▲";
    color: #e30016;
}

.headers {
    margin-left: 0.5em;
}

.Pagination {
    margin-top: 1em;
    margin-bottom: 1em;
}

.observe {
    position: sticky;
    top: 0;
}

.ppgroup {
    display: flex;
    padding-top: 0.5em;
}

.ppgroup select,
.ppgroup label {
    float: left;
    display: inline-block;
}

.ppgroup select {
    margin-left: 0.5em;
    margin-right: 1em;
}

div.pp {
    display: inline-block;
    margin-top: 0.25em;
}

th.headers div.symbol {
    text-align: center;
}

button.mng-columns {
    display: inline-block;
}

#topscroller {
    overflow-x: scroll;
    scrollbar-width: initial;
}

.itemno {
    text-align: right;
}

.filler {
    width: 100%;
}

.Pagination {
    margin: 0;
}

#buttons {
    display: inline-block;
    position: relative;
    width: 200px;
}

#buttons button {
    display: inline-block;
    vertical-align: top;
}

/*
#buttons button {

  position: absolute;
  top: 0;
  left: 0px;
}
#buttons button:nth-child(2) {
  left: 48px;
}
#buttons button:nth-child(3) {
  left: 96px;
}

 */

</style>
