<template>
    <div id="mngdialog" ref="mngdialog">
        {{ order }}
        <table>
            <thead>
            <tr>
                <td>{{ $t("order.name") }}</td>
                <td v-if="Object.keys(filters).length">{{ $t("order.filter") }}</td>
                <td>{{ $t("order.display") }}</td>
            </tr>
            </thead>
            <draggable v-model="order" tag="tbody">

                <tr v-for="pid in order" :key="pid">
                    <td>
                        <label :for="'display' + pid">{{ headers[pid].name }}</label>{{ pid }}
                    </td>
                    <td v-if="filters && Object.keys(filters).length">
                        <label>
                            <input
                                    v-if="filter"
                                    :checked="filter.includes(pid)"
                                    :disabled="!display.includes(pid)"
                                    :value="pid"
                                    type="checkbox"
                                    @change="changevalue(pid)"
                            />
                            <div v-else>No filter</div>
                        </label>
                    </td>
                    <td>
                        <label :for="'display' + pid">
                            <input
                                    :id="'display' + pid"
                                    v-model="display"
                                    :value="pid"
                                    type="checkbox"
                            />
                        </label>
                    </td>
                </tr>
            </draggable>
        </table>
        <div class="footer">
      <span>
        <button :disabled="!order.length" @click="save()">
          {{ $t("order.save") }}
        </button>
      </span>
            <span>
        <button @click="reset">{{ $t("order.reset") }}</button>
      </span>
            <span>
        <button @click="closeModal">{{ $t("order.cancel") }}</button>
      </span>
        </div>
    </div>
</template>
<script>
import {toRaw, nextTick, inject} from "vue";
import {closeModal} from "jenesius-vue-modal";
import {useStore} from "@/stores/page";
import {useRoute} from "vue-router";
// eslint-disable-next-line
import {VueDraggableNext} from "vue-draggable-next";
import "array.prototype.move";

export default {
    name: "StockMngColAndFi",
    // eslint-disable-next-line
    components: {draggable: VueDraggableNext},
    setup() {
        const route = useRoute();
        const mitt = inject("mitt");
        const path = route.path
            .replace("%2F", "/")
            .replace(/^\/s/, "")
            .replace(/^\//, "")
            .replace(/\/$/, "");
        const store = useStore();
        return {store, path, mitt};
    },
    watch: {
        hdrorder() {
            console.log("hdrorder", this.hdrorder);
        },

        headers() {
            console.log("headers");
        },
        /* dialog opened */
        filters() {
            console.log("filters");
            console.log("mng opened filters set");
        },
        /*    filter() {
          if (!this.filter) return;
          this.filter = this.filter.filter(
            (value, index, array) => array.indexOf(value) === index
          );
        },*/
        display(to, from) {
            if (!from.length) return;
            var _to = toRaw(to);
            var _from = toRaw(from);

            let diff = toRaw(_to).filter((x) => !toRaw(_from).includes(x));

            if (diff.length) this.filter = this.filter.concat(diff);

            this.filter = this.filter.filter((pid) => this.display.includes(pid));
        },
    },
    mounted() {
        this.init();

        console.log('headers', toRaw(this.headers));
        if (this.store.pidsettings[this.path])
            console.log('_display', toRaw(this.store.pidsettings[this.path].display));
        console.log('hdrorder', toRaw(this.hdrorder));
        console.log('hdrorderorg', toRaw(this.hdrorderorg));
        if (this.store.pidsettings[this.path])
            console.log('_order', toRaw(this.store.pidsettings[this.path].order));
        console.log('filters', Object.keys(toRaw(this.filters)));
        if (this.store.pidsettings[this.path])
            console.log('_filter', toRaw(this.store.pidsettings[this.path].filter));
    },
    props: {
        headers: Object,
        hdrorder: Array,
        hdrorderorg: Array,
        filters: Object,
    },

    data: function () {
        return {
            dragging: false,
            order: [],
            display: [],
            filter: [],
            pidsettings: {},
            width: 500,
            height: 500,
        };
    },
    methods: {
        init() {
            /* eslint-disable */
            //return;
            nextTick(() => {
                this.pidsettings = this.store.pidsettings;

                if (!this.pidsettings[this.path]) {
                    this.pidsettings[this.path] = {
                        order: toRaw(this.hdrorder).filter(
                            (value, index, array) => array.indexOf(value) === index
                        ),
                        filter: Object.keys(toRaw(this.filters)).filter(
                            (value, index, array) => array.indexOf(value) === index
                        ),
                        display: Object.keys(toRaw(this.headers)).filter(
                            (value, index, array) => array.indexOf(value) === index
                        ),
                    };
                }

                var i;
                this.order = this.pidsettings[this.path].order;
                for (i = 0; i < this.order.length; i++)
                    this.order[i] = parseInt(this.order[i]);
                this.order = toRaw(this.hdrorder).filter(
                    (value, index, array) => array.indexOf(value) === index
                );


                if (this.pidsettings[this.path] && this.pidsettings[this.path].filter) {
                    this.filter = this.pidsettings[this.path].filter;
                    for (i = 0; i < this.filter.length; i++)
                        this.filter[i] = parseInt(this.filter[i]);
                    /*  WTF
                    this.filter = toRaw(this.filter).filter(
                      (value, index, array) => array.indexOf(value) === index
                    ); */
                } else {
                    console.log("setting filter to", toRaw(this.hdrorder));
                    this.filter = toRaw(this.hdrorder);
                }

                this.display = this.pidsettings[this.path].display;
                if (this.display)
                    for (i = 0; i < this.display.length; i++)
                        this.display[i] = parseInt(this.display[i]);
            });
        },
        changevalue(pid) {
            var index = this.filter.indexOf(pid);
            if (index !== -1) {
                this.filter.splice(index, 1);
            } else this.filter.push(pid);
            /* uniq */
            this.filter = [...new Set(this.filter)];

            /*
            this.filter = this.filter.filter(
              (value, index, array) => array.indexOf(value) === index
            ); */
            console.log(pid);
        },
        move(idx, by) {
            this.order.move(idx, idx + by);
            console.log(idx, idx + by);
        },
        save(close = true) {
            console.log("presave filter", toRaw(this.filter));
            if (this.order)
                this.order = this.order.filter(
                    (value, index, array) => array.indexOf(value) === index
                );
            if (this.display)
                this.display = this.display.filter(
                    (value, index, array) => array.indexOf(value) === index
                );
            var o = {
                order: toRaw(this.order),
                filter: toRaw(this.filter),
                display: toRaw(this.display),
            };
            this.store.pidsettings[this.path] = o;

            console.log("saving", this.path, JSON.stringify(o));
            this.mitt.emit("mngchange");
            if (close) this.closeModal();
        },
        reset() {
            this.pidsettings[this.path] = {
                order: toRaw(this.hdrorderorg),
                filter: Object.keys(toRaw(this.filters)),
                display: Object.keys(toRaw(this.headers)),
            };
            var i;
            this.order = this.pidsettings[this.path].order;
            for (i = 0; i < this.order.length; i++)
                this.order[i] = parseInt(this.order[i]);

            this.filter = this.pidsettings[this.path].filter;
            for (i = 0; i < this.filter.length; i++)
                this.filter[i] = parseInt(this.filter[i]);

            this.display = this.pidsettings[this.path].display;
            for (i = 0; i < this.display.length; i++)
                this.display[i] = parseInt(this.display[i]);
        },
        closeModal() {
            this, closeModal();
        },
    },

    computed: {},
};
</script>

<style scoped>
.dark {
    color: #fff;
}

table {
    border-collapse: collapse;
}

td {
    cursor: grabbing;
    border: 1px solid #000;
    vertical-align: bottom;
    padding: 0em;
}

.dark #mngdialog {
    box-shadow: 10px 10px 5px #111;
    background: #222;
}

thead {
    overflow-y: scroll;
    display: block;
    scrollbar-color: #00000000 #00000000;
}

thead td {
    background: #ddd;
    vertical-align: bottom;
    font-weight: bold;
}

.dark thead td {
    background: #333;
}

span {
    display: inline-block;
}

@media only screen and (max-width: 1599px) {
    #mngdialog {
        position: relative;
        max-height: 80vh;
        height: 80vh;

        box-shadow: 10px 10px 5px #bbb;
        background: #eee;
    }
}

@media only screen and (min-width: 1600px) {
    td:nth-child(1) {
        width: 70%;
    }

    td:nth-child(2) {
        width: 15%;
        text-align: center;
    }

    td:nth-child(3) {
        width: 15%;
        text-align: center;
    }

    #mngdialog {
        position: relative;
        height: 80vh;
        max-height: 80vh;

        width: 40vw;
        max-width: 40vw;
        min-width: 38em;

        box-shadow: 10px 10px 5px #bbb;
        background: #eee;
    }
}

.footer {
    display: grid;
    background: #ddd;
}

.dark .footer {
    background: #3e3e3e;
}

.footer span {
    display: inline;
    width: 100%;
}

.footer button {
    height: 4em;
    width: 100%;
    height: 3em;
    margin: 0;
    background-color: #999;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    transition-duration: 0.4s;
    font-weight: bold;
}

.footer span:not(:last-child) button {
    border-right: 1px solid #fff;
}

.dark .footer span:not(:last-child) button {
    border-right: 1px solid #aaa;
}

.dark button {
    background-color: #666;
}

button:hover {
    background-color: #666;
    color: white;
}

.dark button:hover {
    background-color: #999;
    color: white;
}

label {
    width: 100%;

    display: inline-block;
}

table {
    height: calc(100% - 2em);
}

tbody {
    overflow-y: scroll;
    max-height: calc(80vh - 6em);
    display: block;
    width: 100%;
}

tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

#mngdialog > div > div:nth-child(2) {
    margin-top: 1em;
}

.footer {
    height: 2em;
    position: sticky;
    bottom: 0;
    vertical-align: bottom;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0%;
}

button {
    height: 4em;
}

button:disabled {
    color: #aaa;
    pointer-events: none;
}

table {
    width: 100%;
}

.arrows {
}

.arrows button {
    font-size: 1.2em;
    padding: 0em 1em;
    line-height: 1.5em;
}

label {
    padding: 0;
}

tbody tr:nth-child(even):not(.footer) {
    background: #fff;
}

.dark tbody tr:nth-child(even):not(.footer) {
    background: #333;
}

.dark tbody tr:nth-child(even):not(.footer) {
    background: #333;
}

#mngdialog {
    border: 2px solid #4383af;
}

.dark td {
    color: #aaa;
}

.dark button {
    color: #eee;
}
</style>

<script setup></script>
