<template>
    <div ref="newsitem" :class="{shown: more}" class="newsitem">

        <span class="title">{{ item.title }}</span>

        <a v-if="more" class="less" @click="more=false">⊟</a>
        <a v-if="!more" class="more" @click="more=true">⊞</a>
        <span class="date">{{ new Date(item.date * 1000).toISOString().split('T')[0] }}</span>
        <div class="content" v-html="item.html"></div>
    </div>
</template>

<script>
export default {
    name: 'NewsItem',
    props: {
        item: Object,
        lang: String
    },
    data: function () {
        return {
            x: 0,
            y: 0,
            more: false,
        }
    },
    methods: {
        toggle: function () {
            this.more = !this.more;
        },
    },
    computed: {},
    mounted() {
        var rect = this.$refs.newsitem.getBoundingClientRect();
        this.x = rect.x + rect.width;
        this.y = rect.y + rect.height;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.newsitem {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 2em;
    max-height: 100vh;
    transition: max-height 1s ease-in-out;
}

@media (min-width: 1280px) {
    .newsitem {
        position: relative;
        flex-basis: 30%;
        flex: 0 0 30%;
        min-width: 200px;
        max-width: 30vw;
        width: min-content;
    }
}

.more, .less {
    float: right;
    padding-left: 0.5em;
    color: #fff;
    mask: none;
    cursor: pointer;
}

.newsitem:not(.shown) {
    text-overflow: ellipsis;
    max-height: 8em;
    mask: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.8) 50%,
            rgba(0, 0, 0, 0) 100%
    );
}


.date {
    padding-left: 1em;
    float: right;
}


</style>
