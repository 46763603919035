import {createApp} from "vue";
import App from "./ROPLA.vue";
import router from "./router";
import i18n from "./lang";
import {createPinia} from "pinia";
import piniaStorage from "@ctechhindi/pinia-storage";
import axios from "axios";

import mitt from "mitt";
import VueResizeObserver from "vue-resize-observer";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import axiosRetry from "axios-retry";


import VueClickAway from "vue3-click-away";
//import VueObserveVisibility from "vue-observe-visibility";
import {VueTrackPlugin} from "vue3-track";
import {devlog} from "@/devlog";
import {btoa, atob} from "@/btoa";
import {minimatch} from 'minimatch'
import log from "tui-vue-log";
import "@/ropla.css";
import {vue3Debounce} from 'vue-debounce'
import {pageTitle} from 'vue-page-title';
import {objectEquality} from '@santi100/equal-lib';
import GoogleSignInPlugin from "vue3-google-signin";
import VueCookies from 'vue-cookies';
import {isMobile} from "detect-if-mobile";


const app = createApp(App);
app.directive('debounce', vue3Debounce({lock: true}))
app.use(VueCookies, {expires: '28d'});

app.config.globalProperties.$isMobile = () => {
    return isMobile();
};
//app.use(VueMobileDetection);
app.config.globalProperties.$btoa = btoa;
app.config.globalProperties.$atob = atob;
app.config.globalProperties.$devlog = devlog;
app.config.globalProperties.$objectEquality = objectEquality;
app.config.globalProperties.$logbuffer = [];
app.config.globalProperties.$logbuffertype = [];
app.use(VueClickAway); // Makes 'v-click-away' directive usable in every component
//const pinia = createPinia();
app.use(VueResizeObserver);
//app.directive("track-scroll", VueTrackDirective);
//app.use(VueObserveVisibility);
app.use(VueTrackPlugin);
app.use(
    VueTippy,
    // optional
    {
        directive: "tippy", // => v-tippy
        component: "tippy", // => <tippy/>
        componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
        defaultProps: {
            placement: "auto-end",
            allowHTML: true,
        }, // => Global default options * see all props
    }
);
app.use(i18n);
app.config.globalProperties.$langs = {
    pl: "Wersja polska",
    en: "English version",
};
app.config.globalProperties.$langPrimary = "pl";
app.use(router);
const pinia = createPinia();
pinia.use(piniaStorage);
app.use(pinia);

// eslint-disable-next-line
function logbuf(type, method, url, params, code, ms = 999) {
    var text;
    if (params)
        params = Object.fromEntries(
            // eslint-disable-next-line
            Object.entries(params).filter(([_, v]) => v != null)
        );
    else params = {};

    if (method == "GET")
        var queryString = Object.keys(params)
            .map((key) => key + "=" + encodeURIComponent(params[key]))
            .join("&");
    var d = new Date();
    var n = d.toLocaleTimeString("pl-PL");
    text =
        n +
        " " +
        ("     " + ms).slice(-5) +
        "ms" +
        " " +
        code +
        " " +
        method +
        " " +
        url +
        "?" +
        (method == "GET" ? queryString : "");
    // eslint-disable-next-line

    app.config.globalProperties.$logbuffer.unshift([
        type,
        text,
        url + "?" + (method == "GET" ? queryString : ""),
    ]);
    if (app.config.globalProperties.$logbuffer.length > 10) {
        app.config.globalProperties.$logbuffer =
            app.config.globalProperties.$logbuffer.slice(0, 9);
        app.config.globalProperties.$logbuffertype =
            app.config.globalProperties.$logbuffertype.slice(0, 9);
    }
}

const axiosinstance = axios.create();
axiosinstance.interceptors.request.use((config) => {
    config.headers["request-startTime"] = new Date().getTime();
//    config.headers['Access-Control-Allow-Origin'] = '*';
//    config.headers['Access-Control-Allow-Headers'] = '*';
    return config;
});
axiosinstance.interceptors.response.use(
    function (response) {
        const ms =
            new Date().getTime() - response.config.headers["request-startTime"];
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        logbuf(
            "ok",
            response.config.method.toUpperCase(),
            response.config.url,
            response.config.params,
            response.status,
            ms
        );
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        /*
        logbuf(
          "error",
          error.config.method.toUpperCase(),
          error.config.url,
          error.config.params,
          error.status
        );*/
        return Promise.reject(error);
    }
);

axiosRetry(axiosinstance, {
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
    retries: 10,
});
app.config.globalProperties.$axios = axiosinstance;
app.config.devtools = true

app.provide('minimatch', minimatch)
app.provide("mitt", mitt());
app.provide("devlog", devlog);
router.isReady().then(() => {
    app.mount("body");
});
app.use(log, {
    style: "padding: 8px; color: #fff",
    background: ["#333", "red"],
    border: "6px",
});
app.use(
    pageTitle({
        suffix: '- Ropla Elektronik Sp. z o.o.',
        mixin: true,
        router: router
    })
);
app.use(GoogleSignInPlugin, {
    clientId: '376655116601-s26b9blr7p5kjrckq4hichnjlevb02ji.apps.googleusercontent.com',
});