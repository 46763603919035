<template>
    <button :title="$t('product.basket')" @click="goBasket">
        <div ref="counter" class="counter">{{ sum }} ({{ count }})</div>
        <MdiBasketOutline/>
    </button>
</template>

<script>
import MdiBasketOutline from "@/components/Icons/MdiBasketOutline.vue";
import {inject, toRaw} from "vue";
import {useStore} from "@/stores/page";


export default {
    name: "BasketButton",
    components: {MdiBasketOutline},

    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        return {store, mitt};
    },

    data() {
        return {};
    },
    watch: {},
    methods: {
        goBasket() {
            this.$router.push({path: '/basket'});
        },
        basketbounce() {
            this.$refs.counter.classList.add("bounce");
            setTimeout(() => {
                this.$refs.counter.classList.remove("bounce");
            }, 500)
        },
        basketadd(obj) {

            if (this.store.basket[obj.itemcode])
                this.store.basket[obj.itemcode] += obj.amount;
            else this.store.basket[obj.itemcode] = obj.amount;
            //console.log('basketadd', obj, toRaw(this.store.basket));
//            this.$axios.post('/_basketset', {username: this.store.username, basket: this.store.basket}).then((resp) => {
//            })
            this.$refs.counter.classList.add("bounce");
            setTimeout(() => {
                this.$refs.counter.classList.remove("bounce");
            }, 500)
        },
        basketset(obj) {

            this.store.basket[obj.itemcode] = obj.amount;
            //console.log('basketset', obj, toRaw(this.store.basket));
//            this.$axios.post('/_basketset', {username: this.store.username, basket: this.store.basket}).then((resp) => {
//            })
            this.$refs.counter.classList.add("bounce");
            setTimeout(() => {
                this.$refs.counter.classList.remove("bounce");
            }, 500)
        },
        basketdel(obj) {

            delete this.store.basket[obj.itemcode];
            //console.log('basketdel', obj, toRaw(this.store.basket));
//            this.$axios.post('/_basketset', {username: this.store.username, basket: this.store.basket}).then((resp) => {
//            })
            this.$refs.counter.classList.add("bounce");
            setTimeout(() => {
                this.$refs.counter.classList.remove("bounce");
            }, 500)
        }
    },
    mounted() {
        this.mitt.on('basketadd', this.basketadd);
        this.mitt.on('basketset', this.basketset);
        this.mitt.on('basketdel', this.basketdel);
        this.mitt.on('basketbounce', this.basketbounce());
    },
    computed: {
        count() {
            return Object.keys(this.store.basket).length
        },
        sum() {
            return Object.values(this.store.basket).reduce((a, b) => a + b, 0);
        }
    },
    watch: {
        sum() {
            this.basketbounce();
        },
        count() {
            this.basketbounce();
        }
    }
};
</script>

<style scoped>
button:active label {
    transform: translateY(2px);
}

svg {
    color: #ddd;
}

button {
    background: transparent;
    border: none;
    position: relative;
    padding: 0;
    width: 32px;
    height: 32px;
}

button * {
    color: #000;
}

button svg {
    width: 22px;
    height: 22px;
}

.dark button svg {
    color: #aaa;
}

.dark button * {
    color: #ddd;
}

.counter {
    position: absolute;
    color: #fff;
    z-index: 99;
    right: 6px;
    bottom: 1px;
    white-space: nowrap;
}

.dark .counter {
    color: #fff;
}

.bounce {
    animation: bounce-in .5s;
}

@keyframes bounce-in {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(4);
    }
    100% {
        transform: scale(1);
    }
}
</style>
