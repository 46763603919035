<template>
  <h1>404
    <GrommetIconsDocumentMissing/>
  </h1>
  <h2>{{ $t("pages.not-found") }}</h2>
  <hr/>
</template>

<script>
import GrommetIconsDocumentMissing from "@/components/Icons/GrommetIconsDocumentMissing.vue";

export default {
  name: "NotFound",
  components: {GrommetIconsDocumentMissing}
};
</script>

<style scoped>
h1 {
  color: red;
  font-size: 3em;
}

h2 {
  font-weight: bold;
}

h1 svg {
  width: 36px;
  height: 36px;
}
</style>
