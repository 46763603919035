<template>
    <div v-if="cats">
        <input id="catlist" v-model="grouplist" type="radio" value="list"/>
        <label for="catlist">{{ $t("categories.list") }}</label>

        <input id="catimgs" v-model="grouplist" type="radio" value="img"/>
        <label for="catimgs">{{ $t("categories.images") }}</label>
        <ul :class="{list: grouplist == 'list', fr5: Object.keys(cats).length<=20, fr3: Object.keys(cats).length<=12}"
            class="cats">
            <li
                    v-for="(item, idx) in cats"
                    :key="idx"
                    :class="{ img: grouplist == 'img' }"
            >
                <a :class="{list: grouplist == 'list'}"
                   :href="'/s/' + idx.replaceAll('.', '/')+($route.query.q ? '?q='+$route.query.q : '')"
                   :title="$t('product.groupno') + ': ' + item.group_code"
                >
                    <img
                            v-if="grouplist == 'img' && item.imgcode"
                            :src="'/_gimg/' + item.imgcode"
                    />
                    <img v-else-if="grouplist == 'img'" src="/img/grupa.png"/>{{
                    item.name
                    }} <span class="matches">({{ item.matches }})</span></a
                >
            </li>
            <li class="filler">&nbsp;</li> <!-- fixme -->
        </ul>
    </div>
</template>
<script>
import {inject, nextTick, toRaw} from "vue";
import {useStore} from "@/stores/page";

export default {
    name: "CategoryTreeSearch",
    emits: ["opencat"],
    setup() {
        const mitt = inject("mitt");
        const store = useStore();

        return {mitt, store};
    },
    props: {
        cats: Object
    },

    data: function () {
        return {
            isOpen: false,
            selected: false,
            grouplist: this.store.grouplist,
            // localitem: {}
        };
    },
    methods: {},
    mounted() {
    },
    watch: {
        grouplist(to) {
            //console.log("grouplist changed", to);
            this.store.grouplist = to;
        },
    },
};
</script>
<style scoped>
ul {
    margin: 0;
    padding: 0;
}

img {
    border: 1px solid #000;
    width: 134px;
    height: 128px;
}

li.img {
    display: inline-block;
}

li:not(.img) {
    display: inline;
}

li {
    vertical-align: top;
}

li.img {
    width: 128px;
    margin-right: 1em;
}

a {
    display: inline;
    text-decoration: none;
    color: #0066cc;
}

a:hover {
    text-decoration: underline;
}

.cats {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.cats li {
    white-space: pre-wrap;
}

.cats:not(.list) {

}

.cats.list {
}


.grouplist a {
    display: inline-block
}

ul:not(.list) {
    height: calc(128px + 5em);
    overflow-x: auto;
}

ul:not(.list) li {
    margin-bottom: 3em;
}

ul.list {
    list-style-type: none;
    display: flex;
    flex-flow: column wrap;
    justify-content: start;
    height: 12em;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

ul.list.fr5 {
    height: 8em;
}

ul.list.fr3 {
    height: 5em;
}

ul.list li {
    display: inline-block;
    flex-wrap: wrap;
    line-height: 1.5em;
    max-width: 22em;
}

ul.list li.filler {
    flex-grow: 1;
    width: 100%;
    display: block;
}

ul.list li:not(.filler)::before {
    vertical-align: top;
    content: " ●  ";
    color: #0066cc;
}

.dark ul.list li:not(.filler)::before {
    color: #fff;
}

.matches {
    color: #000;
}

.dark .matches {
    color: #fff;
}
</style>
