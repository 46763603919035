<template>
    <h1>{{ $t('user.activation') }}</h1>
    <h2 v-show="mail">
        {{ $t('user.activated', {mail: this.mail}) }}
        <a href="/" @click.prevent="mitt.emit('showlogin',{mail: this.mail})">{{ $t('site.login') }}</a>
    </h2>
</template>

<script>
import {useRoute} from "vue-router";
import {inject} from "vue";
import {useStore} from "@/stores/page.js";

export default {
    name: "LoginActivate",
    setup() {
        const route = useRoute();
        const mitt = inject("mitt");
        const code = route.params.pathMatch ? route.params.pathMatch[0] : null;
        const store = useStore();
        return {mitt, store, code}
    },
    data() {
        return {mail: ''}
    },
    mounted() {
        if (this.code) {
            this.$axios.get('/_user/activate', {params: {code: this.code}}).then((resp) => {
                this.mail = resp.data.mail;
            });
        }
    }
}
</script>

<style scoped>

</style>