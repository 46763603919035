import { defineStore } from "pinia";
import { ref, toRaw } from "vue";
export const useFiltersStore = defineStore("filters", () => {
  const vals = ref({});
  const ranges = ref({});
  function getVals() {
    var obj = toRaw(this.vals);
    var keys = Object.keys(obj);
    var vals = [];

    for (var i = 0; i < keys.length; i++) {
      vals = vals.concat(obj[keys[i]]);
    }
    return toRaw(vals);
  }
  function getRanges() {
    return toRaw(this.ranges);
  }

  function getParams() {
    var vals = toRaw(this.getVals());
    var ranges = toRaw(this.getRanges());
    return { vals, ranges };
  }
  return { vals, ranges, getVals, getRanges, getParams };
});
