<template>
    <div class="outfilters">
        <!--  :class="{float: short && showFilters}" -->
        <div v-if="hdrorder.length" class="filters">
            <stock-filter
                    v-for="(param_id, idx) in nehfirst"
                    v-show="delayshow[param_id]"
                    :key="param_id"
                    :filter="filters[param_id]"
                    :idx="idx"
                    :initialmatches="matches[param_id]"
                    :initialstate="vals"
                    :itemcount="items[param_id] ? items[param_id].length : undefined"
                    :items="items[param_id]"
                    :mul="mul"
                    :shown="shownfilters"
                    :zwave="store.lastfiltersloaded==path"
                    @itemschecked="itemschecked"
                    @setfilters="setfilters"
            />
        </div>
    </div>
</template>

<script>
import {inject, toRaw, ref, nextTick} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "@/stores/page";
import {useFiltersStore} from "@/stores/filters";
import stockFilter from "@/components/Stock/StockFilter.vue";

//import { forEach } from "core-js/stable/dom-collections";

export default {
    name: "StockFilters",
    components: {stockFilter},
    props: {
        //path: String,
        headers: Object,
    },
    emits: ["filterschanged"],
    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        const storefilters = useFiltersStore();
        const route = useRoute();
        const router = useRouter();
        const $devlog = inject('$devlog');
        const vals = ref(
            route.query && route.query.v ? String(route.query.v).split(",") : []
        );
        var query = '';
        if (route.query.q) query = route.query.q;
        const path = route.path
            .replace("%2F", "/")
            .replace(/\/+/, "/")
            .replace(/^\/s\/*/, "")
            .replace(/\/+$/, "");

        function createDebounce() {
            let timeout = null;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, this.$isMobile() ? 0 : (delayMs || 500));
            };
        }

        return {
            mitt,
            store,
            storefilters,
            vals,
            path,
            route,
            router,
            debounce: createDebounce(),
            $devlog,
            query
        };
    },
    data() {
        return {
            filters: {},
            ranges: {},
            mul: "" /*FIXME, where from? */,
            hdrorder: [],
            showfilters: [],
            shownfilters: true,
            items: {},
            matches: {},
            hwith: [],
            hwithfiltered: 0,
            valschecked: [],
            delayshow: {},
        };
    },
    watch: {
        shownfilters() {
            this.$forceUpdate();
        },
        $route(to) {
            //console.log("route changed",this.me,to.query.p);
        },
        ranges() {
            //this.paramsChanged();
            //console.log("range change", toRaw(this.ranges));
        },
        vals() {
            //this.paramsChanged();
        },
        hwithfiltered() {
            if (this.hwith.length == this.hwithfiltered) {
                if (this.valschecked.join(",") != this.$route.query.v) {
                    var newquery = {...this.$route.query};
                    newquery.v = this.valschecked.join(",");
                    this.$router.replace({query: newquery});
                }
            }
        },
    },

    mounted() {
        /*    var vals = [];
        if (this.$route.query && this.$route.query.v)
          vals = this.$route.query.v.split(",");*/
        if (this.$route.query.v)
            this.storefilters.vals = this.vals = this.$route.query.v.split(",");
        if (this.$route.query.r) {
            this.ranges = {};
            var r = this.$route.query.r.split("~");
            for (var i = 0; i < r.length; i++) {
                var kv = r[i].split("=");
                var k = kv[0];
                var range = kv[1].split("_");
                this.storefilters.ranges[k] = this.ranges[k] = toRaw(range);
                //this.$forceUpdate();
            }
        }
        this.mitt.on("removefilters", () => {
            this.removeFilters();
        });
        this.mitt.on("togglefilters", () => {
            this.shownfilters = !this.shownfilters;
        });
        this.mitt.on("chlang", () => {
            this.getfilters(this.vals);
        });
        this.mitt.on("mngchange", () => {
            this.getfilters(this.vals);
        });

        this.mitt.on("getfilters", () => {
            var params = this.storefilters.getParams();
            this.$devlog("event", "on mitt getfilters", this.vals, params.vals);

            this.debounce(() => {
                //this.getfilters(vals);
                this.getfilters();
            });
        });
        this.mitt.emit("getfilters");
        this.$devlog("vals", "mounted end vals and ranges", this.vals, this.ranges);
    },
    methods: {
        itemschecked(o) {
            this.hwithfiltered++;
            this.valschecked = this.valschecked.concat(o.vals);
        },
        getnehfirst() {
            var hwith = [];
            var hwithout = [];

            for (var i = 0; i < this.hdrorder.length; i++) {
                var pid = this.hdrorder[i];
                if (this.showfilters && this.showfilters.includes(pid)) {
                    if (this.filters[pid] && this.filters[pid].matches) {
                        hwith.push(pid);
                    } else hwithout.push(pid);
                }
            }
            this.hwith = hwith;
            let nehfirst = hwith.concat(hwithout)
            console.log('neh', nehfirst);
            let that = this;
            //XXX

            for (let i = 0; i < nehfirst.length; i++) {
                let n = nehfirst[i];
                if (this.store.lastfiltersloaded != this.path || this.$isMobile()) {
                    that.delayshow[n] = ref(false);
                    setTimeout(() => {
                        that.delayshow[n] = ref(true);
                    }, this.$isMobile() ? 0 : 50 * i)
                } else
                    that.delayshow[n] = ref(true);
            }
            // lock wave after the first
            this.store.lastfiltersloaded = this.path;
            return nehfirst;
        },
        paramsChanged() {
            var vals = this.vals.join(",");
            var range = this.getparamsrange();
            if (
                (this.$route.query.v != vals && this.$route.query.v) ||
                (this.$route.query.r != range && this.$route.query.r)
            ) {
                var newquery = {...this.$route.query};
                if (vals.length) newquery.v = vals;
                else delete newquery.v;
                if (Object.keys(this.getparamsrange()).length)
                    newquery.r = this.getparamsrange();
                else delete newquery.r;
                this.$devlog(
                    "route",
                    "vals different in url",
                    this.$route.query.v,
                    vals,
                    this.$route.query.r,
                    this.getparamsrange()
                );
                this.$devlog("route", newquery);
                this.$router.push({query: newquery});
            }
        },
        removeFilters() {
            const newquery = Object.assign({}, this.$route.query);
            delete newquery.v;
            delete newquery.r;

            this.vals = [];
            this.storefilters.vals = {};
            this.storefilters.ranges = {};
            this.$router.push({newquery});
            this.$devlog("route", "removeFilters", this.$route.path, newquery);
        },
        setfilters(o) {

            /*
            var pvals = Object.keys(this.items[o.param_id]);
            this.vals = this.vals.filter(function (x) {
              return pvals.indexOf(x) < 0;
            });*/

            this.ranges[o.param_id] = o.range;

            var reroute = false;
            var vals = o.vals.join(",");
            if (vals != this.$route.query.v) {
                reroute = true;

                this.vals = o.vals;
                this.storefilters.vals = o.vals;
            }
            var ranges = this.getparamsrange();


            if (ranges != this.$route.query.r) {
                this.storefilters.ranges = this.ranges;

                reroute = true;
            }
            if (reroute) {
                var newquery = {...this.$route.query};
                if (vals.length) newquery.v = vals;
                else delete newquery.v;
                if (ranges) newquery.r = ranges;
                else delete newquery.r;

                this.$router.push({query: newquery});
            }

            this.getfilters();
        },
        getfilteritems: function (pids = []) {
            var path = this.$route.path.replace("%2F", "/").replace(/^\/s/, "");
            var that = this;
            //var that=this;
            pids.sort(function (a, b) {
                return a - b;
            });
            this.$axios
                .get(
                    "/_fi/" + path,
                    {
                        params: {
                            q: this.query,
                            p: pids.join(","),
                            v: Array(this.vals).join(","),
                            r: this.getparamsrange(),
                            ver: this.store.version,
                            rand: Math.random(),
                        },
                    },
                    {
                        headers: {
                            "Content-Type": null,
                        },
                    }
                )
                .then((response) => {
                    that.items = response.data;
                    console.log('items', toRaw(that.items));
                });
        },
        getparamsrange() {
            var ranges = this.storefilters.getRanges();
            var pranges = {};
            var strranges = {};

            var r;
            for (r in ranges) {
                if (ranges[r].length) pranges[r] = ranges[r];
            }
            for (r in pranges) {
                strranges[r] = pranges[r];
            }
            var urlparams = new URLSearchParams(strranges);
            return (
                urlparams
                    .toString()
                    .replaceAll(/%2C/g, "_") //,
                    //        .replaceAll(/=/g, ".")
                    .replaceAll(/&/g, "~")
            );
        },
        getfilters: function () {
            var path = this.$route.path.replace("%2F", "/").replace(/^\/s/, "");
            var that = this;
            if (this.getfilterstimer) {
                clearTimeout(this.getfilterstimer);
                this.getfilterstimer = null;
            }
            var params = this.storefilters.getParams();
            var vals = params.vals;
            vals = vals.concat(this.vals);
            // uniq
            vals = [...new Set(vals)];
            this.vals = vals;
            this.ranges = params.ranges;
            this.$emit("filterschanged", params);
            var v;
            v = Array(this.vals).join(",");
            var ranges = {};
            for (var r in params.ranges) {
                ranges[r] = Array(params.ranges[r]).join(",");
            }
            this.$root.coverstages["filters"] = "loading.filters";
            this.$axios
                .get(
                    "/_f/" + path,
                    {
                        params: {
                            q: this.query,
                            v: v,
                            r: this.getparamsrange(),
                            l: that.store.lang,
                            ver: this.store.version,
                            rand: Math.random(),
                        },
                    },
                    {
                        headers: {
                            "Content-Type": null,
                        },
                    }
                )
                .then((response) => {
                    var data = response.data;
                    delete this.$root.coverstages["filters"];
                    if (data.filters) {
                        that.filters = data.filters;

                        this.mitt.emit("gotfilters", data.filters);
                        that.getfilteritems(Object.keys(data.filters));
                        var pids = Object.keys(that.filters);
                        for (var i = 0; i < pids.length; i++) {
                            var pid = pids[i];
                            that.matches[pid] = that.filters[pid].matches;
                        }
                    } else {
                        this.mitt.emit("gotfilters", {});
                        return;
                    }

                    if (
                        this.store.pidsettings[this.path] &&
                        this.store.pidsettings[this.path].order
                    ) {
                        this.hdrorder = this.store.pidsettings[this.path].order;
                        if (this.store.pidsettings[this.path].filter)
                            this.showfilters = this.store.pidsettings[this.path].filter;

                    } else if (data.order) {
                        that.hdrorder = data.order;
                        that.showfilters = data.order;
                    } else return;
                    delete this.$root.coverstages["filters"];
                });
        },
    },
    computed: {

        /* first filters with matches, then without */
        nehfirst() {
            return this.getnehfirst();
        },

        filterstatene() {
            var ne = {};

            for (const [key, value] of Object.entries(this.vals)) {
                if (value.length) ne[key] = value;
            }

            return ne;
        },
    },
};
</script>

<style scoped>
.outfilters {
    overflow: auto;
    scrollbar-width: auto;
}

.filters {
    white-space: nowrap;
}

div {
}

button.remove-filters {
    display: inline-block;
}

</style>
