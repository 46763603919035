import {createWebHistory, createRouter} from "vue-router";
import NotFound from "@/components/NotFound.vue";
import DebugInfo from "@/components/DebugInfo.vue";
import NewsPage from "@/components/News/NewsPage.vue";
import NewsPageEdit from "@/components/News/NewsPageEdit.vue";
import StockIndex from "@/components/Stock/StockIndex.vue";
import StockSearch from "@/components/Stock/StockSearch.vue";
import StockManSearch from "@/components/Stock/StockManSearch.vue";
import ProductItem from "@/components/Product/ProductItem.vue";
import LoginHistory from "@/components/Login/LoginHistory.vue";
import BasketMain from "@/components/Basket/BasketMain.vue";
import BasketCompare from "@/components/Basket/BasketCompare.vue";
import LoginActivate from "@/components/Login/LoginActivate.vue";
import CompanyPage from "@/components/Company/CompanyPage.vue";
import ContactPage from "@/components/Contact/ContactPage.vue";
import ContactPageEdit from "@/components/Contact/ContactPageEdit.vue";
import DistributionsPages from "@/components/Distributions/DistributionsPages.vue";
import DistributionsPage from "@/components/Distributions/DistributionsPage.vue";
import DistributionsPagesEdit from "@/components/Distributions/DistributionsPagesEdit.vue";
/*
, component: News},
 */
const routes = [
    {path: "/", name: "news", component: NewsPage},
    {path: "/debug", name: "debug", meta: {title: 'Odrobaczanie'}, component: DebugInfo},
    {path: "/news", meta: {title: 'Kontakt'}, component: NewsPage},
    {
        path: "/news/edit",
        name: "Newsy - edycja",
        meta: {title: 'Edytor strony news'},
        component: () => import('@/components/News/NewsPageEdit.vue')
    },
    {
        path: "/int/pidcase",
        name: "PIDCase",
        meta: {title: 'Raport o podejrzanych wartościach parametrów'},
        component: () => import('@/components/Int/PIDCase.vue')
    },
    {
        path: "/user/create",
        meta: {title: 'Utwórz konto'},
        component: () => import("@/components/Login/LoginCreate.vue")
    },
    {path: "/dist", meta: {title: 'Dystrybucje'}, component: DistributionsPages},
    {path: "/dist/edit", meta: {title: 'Edycja dystrybucji'}, component: DistributionsPagesEdit},
    {path: "/dist/:company", meta: {title: 'Dystrybucja'}, component: DistributionsPage, props: true},
    {path: "/company", meta: {title: 'Firma'}, component: CompanyPage},
    {path: "/company/:page", meta: {title: 'Firma'}, component: CompanyPage, props: true},
    {path: "/contact", meta: {title: 'Kontakt'}, component: ContactPage},
    {
        path: "/contact/edit",
        name: "Kontakt - edycja",
        meta: {title: 'Edytor strony kontakt'},
        component: () => import('@/components/Contact/ContactPageEdit.vue')
    },
    {path: "/mfr/:mfrcode", meta: {title: 'Producent'}, component: StockManSearch, props: true},
    {path: "/p/:itemcode", meta: {title: 'Produkt'}, name: "item", component: ProductItem},
    {path: "/s:pathMatch(.*)*", meta: {title: 'Produkty'}, name: "stock", component: StockIndex},
    {path: "/q:pathMatch(.*)*", meta: {title: 'Wyszukiwanie'}, component: StockSearch, props: true},
    {path: '/user/lastlog', meta: {title: 'Logowania'}, component: LoginHistory},
    {path: '/user/activate/:pathMatch(.*)*', meta: {title: 'Aktywacja konta'}, component: LoginActivate},
    {path: '/basket', meta: {title: 'Koszyk'}, component: BasketMain},
    {path: '/basket/compare', meta: {title: 'Porównaj produkty'}, component: BasketCompare},

    {path: "/:pathMatch(.*)*", meta: {title: 'Nie zsnaleziono'}, component: NotFound},
];
const router = createRouter({
    history: createWebHistory(),
    routes, //same --- > routes:routes
});
export default router;
