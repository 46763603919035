<template>
    <div id="news" :class="{ short: short }">
        <NewsItem
                v-for="(item, num) in paged"
                :key="num"
                :item="item"
                :lang="lang"
        ></NewsItem>
    </div>
</template>

<script>
//import axios from 'axios'
import NewsItem from "./NewsItem.vue";
import {useStore} from "@/stores/page";
import {inject} from "vue";

export default {
    name: "NewsPage",
    components: {
        NewsItem,
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },
    data: function () {
        return {
            news: [],
            short: false,
            perPage: 10,
            curPage: 1,
            more: {},
            lang: "pl" /* FIXME */,
        };
    },
    methods: {
        toggle: function (id) {
            this.more[id] = !this.more[id];
        },
        isShort: function (item) {
            if (item.lang[this.lang].short.length && !this.more[item.id]) {
                return true;
            }
            return false;
        },
    },
    computed: {
        paged() {
            const items = this.news;
            // Return just page of items needed
            return items.slice(
                (this.curPage - 1) * this.perPage,
                this.curPage * this.perPage
            );
        },
        total() {
            return this.news.length;
        },
    },
    watch: {},
    mounted() {
        /*   this.$root.$on('pgshort', function() {
            that.short=true;
           });
           this.$root.$on('pglong', function() {
            that.short=false;
           }); */
        this.mitt.on("chlang", (e) => (this.lang = e));
        var that = this;
        this.$axios.get("/_pg/news?l=" + this.store.lang + '&c=1').then((response) => {
            that.news = response.data;
        });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#news:not(.short) {
    margin-top: 1em;
}

#news.short {
    margin-top: 100px;
}

#news .image {
    max-width: 50%;
    margin: 0;
    margin: 0 1em;
}

#news .image-style-align-right {
    float: right;
}

#news .image-style-align-left {
    float: left;
}

#news li {
    margin-left: 8em;
}

#news img {
    max-width: 100%;
}

#news figcaption {
    font-size: 50%;
}

#news {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 1em;
    padding-left: 2%;
    color: #444;
}

.dark #news {
    color: #eee;
}

#news .newsitem {
    border: 1px solid #f4f4f4;
}

.dark #news .newsitem {
    border: 1px solid #282828;
}

#news {
    text-align: left;
}

#news .title {
    font-weight: bold;
}

#news .content {
    padding: 0.3em;
    text-align: justify;
    text-justify: inter-word;
}
</style>
