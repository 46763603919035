<template>
    <div id="langbar">
        <label
                v-for="(desc, language) in langs"
                :key="language"
                :for="'lang' + language"
                class="pushradio"
        >
            <input
                    :id="'lang' + language"
                    v-model="lang"
                    :value="language"
                    name="language"
                    type="radio"
            />
            <img
                    :alt="desc"
                    :class="{ pushed: lang == language }"
                    :src="'/img/' + language + '.png'"
                    :title="desc"
            />
        </label>
    </div>
</template>

<script>
import {useStore} from "@/stores/page";
import {inject} from "vue";
import {getCurrentInstance} from "vue";

export default {
    name: "langBar",
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        //console.log('i18n@langbar',i18n)
        const langs =
            getCurrentInstance().appContext.config.globalProperties.$langs;
        return {store, langs, mitt};
    },
    watch: {
        lang(lang) {


            try {

                this.$i18n.locale = lang;
                this.store.lang = lang;
                this.mitt.emit("chlang", lang);
            } catch (e) {
                console.log(e);
            }

            /*                      var l = this.$route.query.l;
            if (l != lang) {
                            var newquery = {...this.$route.query};
                            if (lang == "pl") {
                                delete newquery.l;
                            } else {
                                newquery.l = lang
                            }
                            ;
                            this.$router.push({query: newquery});
                        }*/
        },
    },
    data() {
        return {
            lang: this.store.lang ? this.store.lang : "pl",
        };
    },
    methods: {},
};
</script>

<style scoped>
#langbar {
    position: absolute;
    top: 0.2em;
    display: inline-block;
    right: 0;
    z-index: 9;
}

.mobile #langbar {
    right: 0;
}

#langbar img {
    width: 42px;
    margin: 0;
    padding: 0;
}
</style>
