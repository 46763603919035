<template>
    <Tabs :tabs="{ wysiwyg: 'WYSIWYG', html: 'HTML' }" default="wysiwyg">
        <TabContent name="wysiwyg">
            <editor v-model="_body" :init="initMCE" tinymce-script-src="/tinymce/tinymce.min.js"/>
        </TabContent>

        <TabContent name="html">
            <button :title="$t('editor.tidy')" @click="tidy">{{ $t('editor.tidy') }}</button>
            <textarea v-model="_body" class="html"></textarea>
        </TabContent>
    </Tabs>

    <label class="path">
        <span>{{ $t('editor.path') }}</span>
        <input v-model="_path" :disabled="disablepath" :placeholder="$t('editor.path')" :title="$t('editor.path')"
               class="path">
    </label>
  <!-- v-if="_date != null" -->

    <label class="date">
        <span>{{ $t('editor.date') }}</span>
        <VueDatePicker v-model="_date" format="yyyy-MM-dd" position="left"></VueDatePicker>
    </label>
    <label class="title">
        <span>{{ $t('editor.title') }}</span>
        <input v-model="_title" :placeholder="$t('editor.title')" :title="$t('editor.title')" class="title">
    </label>


    <div class="position">
        <button :disabled="unchanged" :title="$t('editor.save')" @click="save">💾</button>
        <span v-if="_sort != null" class="sort">{{ _sort }}</span>
        <button v-if="_sort != null" :disabled="sort==1" :title="$t('editor.up')" @click="up">▲</button>
        <button v-if="_sort != null" :disabled="last" :title="$t('editor.down')" @click="down">▼</button>
    </div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import {toRaw} from "vue";
import {inject, ref} from "vue";
import {useStore} from "@/stores/page.js";
import {Tabs, TabButton, TabContent} from '@exapps/vue-tabs';
import '@ckeditor/ckeditor5-build-classic/build/translations/pl.js'
import {PasteFromOffice} from '@ckeditor/ckeditor5-paste-from-office';
import {PasteFromOfficeEnhanced} from '@ckeditor/ckeditor5-paste-from-office-enhanced';
import {GeneralHtmlSupport} from '@ckeditor/ckeditor5-html-support';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: "ERditor",
    props: {
        path: String,
        title: String,
        body: String,
        position: Number,
        sort: Number,
        id: Number,
        last: Boolean,
        pager: Boolean,
        cat: String,
        date: Number
    },
    components: {
        Editor,
        Tabs, TabButton, TabContent, VueDatePicker
    },
    setup(props) {

        if (props.date)
            var _date = ref(new Date(props.date * 1000));
        else
            var _date = null;
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt, _date};
    },
    data() {
        var self = this;
        return {
            _path: this.path,
            _body: this.body,
            _title: this.title,
            _sort: this.sort,
            disablepath: true,
            initMCE: {
                selector: "#newsedittest",
                branding: false,
                promotion: false,
                toolbar_sticky: true,
                save_onsavecallback: () => {
                    self.save();
                },
                language: "pl",
                plugins: 'lists link image table code help wordcount save PasteFromOffice PasteFromOfficeEnhanced GeneralHtmlSupport',
                toolbar: 'save cancel | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link img | tidy',
                menuBar: false,
                setup: function (editor) {
                    /*
                    editor.ui.registry.addButton('tidy', {
                        text: 'Tidy',
                        onAction: function () {
                            self.tidy();
                        }
                    });*/
                    editor.ui.registry.addButton('img', {
                        text: 'IMG',
                        onAction: function () {
                            this.editor.model.change((writer) => {
                                const insertPosition = this.editor.model.document.selection.getFirstPosition();
                                writer.insertText("CKEditor 5 rocks!", insertPosition);
                            });
                        }
                    });
                }
            },
        }
    },
    mounted() {

    },
    methods: {
        save() {
            console.log('savink');
            this.$emit('pgsave', {
                path: this.path,
                id: this.id,
                title: this.title,
                body: this._body,
                sort: this.sort,
                lang: this.store.lang,
                date: (this._date != null ? this._date.getTime() / 1000 : null),
            });
        },
        tidy() {
            this.$axios.post(this.store.dev ? 'https://test.ropla.eu/_tidy' : '/_tidy', {
                html: this._body,
            }).then((resp) => {
                this._body = resp.data.html;
            });
        },
        up() {
            this.$emit('pgmove', {
                cat: this.cat,
                id: this.id,
                dir: -1
            });
        },
        down() {
            this.$emit('pgmove', {
                cat: this.cat,
                id: this.id,
                dir: 1
            });
        }
    },
    computed: {
        unchanged: function () {
            return !(this.body != this._body || this.title != this._title || this.sort != this._sort);
        },
        hdate: function () {
            return new Date(item.date * 1000).toISOString().split('T')[0];
        }
    },
    watch: {
        $route(to, from) {
            this.$router.go(0);
        },
    }
}
</script>

<style scoped>
.title {
    width: 100%;
}

.html {
    width: 100%;
    min-height: 50vh;
    padding: 0;
    margin: 0;
}

label span {
    padding: 0 1em 0 0.5em;
    min-width: 8em;
    text-align: right;
}

label {
    background: #fff;
    color: #000;
}

label input {
}

label {
    display: flex;
}

input {
    flex: 1;
}

span {
    background: #fff;
    color: #000;
    padding: 1px 0.5em 2px 0.5em;
}
</style>