<template>
    <div v-for="page in pagedata">
        <h2 v-if="page[store.lang]">{{ page[store.lang].title }}</h2>
        <div v-if="page[store.lang]" v-html="page[store.lang].html"></div>
        <hr>
    </div>
</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import CategoryTreeItem from "@/components/CategoryTree/CategoryTreeItem.vue";

export default {
    name: "ContactPage",
    props: {
        page: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            pagedata: {}
        }
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },

    mounted() {
        this.getContact()
    },
    methods: {
        getContact(page) {
            this.$axios
                .get("/_pg/contact?c=1", {})
                .then((response) => {
                    this.pagedata = response.data;
                });
        },
    }
}
</script>

<style scoped>
</style>
