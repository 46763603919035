<script>
export default {
	name: 'ClarityEyeShowSolid',
};
</script>
<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 36 36">
		<path fill="currentColor" d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43" class="clr-i-solid clr-i-solid-path-1" />
		<circle cx="18.09" cy="18.03" r="6.86" fill="currentColor" class="clr-i-solid clr-i-solid-path-2" />
		<path fill="none" d="M0 0h36v36H0z" />
	</svg>
</template>
