<template>
    <ul>
        <li>
            <a href="/s/">{{ $t("product.root") }}</a>
        </li>
        <li
                v-for="crumb in crumbs"
                :key="crumb.path"
                v-tippy
                :content="$t('product.groupno') + ': ' + crumb.group_code"
        >
            <a :href="'/s/' + crumb.path + '?l=' + $i18n.locale">{{ crumb.name }}</a>
        </li>
    </ul>
</template>

<script>
import {inject, nextTick, toRaw} from "vue";
import {useStore} from "@/stores/page";
import i18n from "@/lang/index.js";

export default {
    name: "CategoryTreeBreadCrumb",

    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        return {mitt, store};
    },
    data() {
        return {
            crumbs: [],
            readytogo: false,
        };
    },
    mounted() {
        this.mitt.on("categories-propagated", () => {
            nextTick(() => {
                this.crumbs = [];
                this.traverse(
                    this.$route.path.replace(/^\/s\//, "").split("/"),
                    this.store.tree
                );
                if (this.crumbs.length)
                    this.$setPageTitle(this.crumbs[this.crumbs.length - 1].name);
            });

        });
    },
    methods: {
        traverse(parts, branch) {
            if (branch.children && branch.children.length > 0) {
                for (var i = 0; i < branch.children.length; i++) {
                    var subpath = branch.children[i].path.split("/");
                    if (parts[0] == subpath[subpath.length - 1]) {
                        this.crumbs.push({
                            path: toRaw(branch.children[i].path),
                            name: toRaw(branch.children[i].name),
                            group_code: toRaw(branch.children[i].group_code),
                        });

                        if (branch.children[i].children) {
                            this.traverse(parts.splice(1), branch.children[i]);
                        }
                        break;
                    }
                }
            }

            //      var part = parts[0];
        },
    },
    computed: {},
    watch: {
        $route(to, from) {
            nextTick(() => {
                this.crumbs = [];
                this.traverse(
                    this.$route.path.replace(/^\/s\//, "").split("/"),
                    this.store.tree
                );
                if (this.crumbs[this.crumbs.length - 1])
                    this.$setPageTitle(this.crumbs[this.crumbs.length - 1].name);
            });
        },
    },
};
</script>

<style scoped>
ul {
    text-align: left;
    margin: 0 0 0.5em 0;

    padding: 0;
    position: relative;
    display: inline-block;
}

ul li {
    list-style-type: none;
    display: inline-block;
    font-weight: bold;
}

.dark ul li a,
ul li a {
    color: #4383af;
}

ul li:not(:first-child):before {
    content: "\00a0\00a0〉";
    color: #666;
}

.dark ul li:not(:first-child):before {
    color: #999;
}

ul li:last-child a {
    text-decoration: underline;
}

a {
    color: #000;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.dark a {
    color: #666;
}
</style>
