<template>
    <img v-if="store.userpic" :alt="store.username" :src="store.userpic"/>
    <h1>{{ store.username }}</h1>
    <h2>{{ $t('user.lastlog') }}</h2>
    <div class="scroller">
        <table class="history">
            <thead>
            <tr>
                <th>{{ $t('user.logintime') }}</th>
                <th>{{ $t('user.logouttime') }}</th>
                <th>{{ $t('user.ip') }}</th>
                <th>{{ $t('user.dns') }}</th>
                <th>{{ $t('user.location') }}</th>
                <th>{{ $t('user.useragent') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="h in history">
                <td>{{ h.login }}</td>
                <td>{{ h.logout }}</td>
                <td>{{ h.ip }}</td>
                <td>{{ h.revdns }}</td>
                <td>{{ h.location }}</td>
                <td>{{ h.useragent }}</td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

import {inject, toRaw} from "vue";
import {useStore} from "@/stores/page.js";

export default {
    name: "LoginHistory",
    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        return {mitt, store};
    },
    data: function () {
        return {
            history: [],
        }
    },
    mounted() {
        this.getHistory();
    },
    methods: {
        getHistory() {
            var that = this;
            this.$root.coverstages["lastlog"] = "loading.lastlog";
            this.$axios
                .get(
                    "/_user/lastlog", {
                        params: {
                            ver: this.store.version,
                            rand: Math.random(),
                        },
                    },
                )
                .then((response) => {
                    that.history = response.data;
                    delete this.$root.coverstages["lastlog"];
                });
        }
    }
}
</script>

<style scoped>
div.scroller {
    overflow-x: auto;
}

table {
    border-collapse: collapse;
    cursor: default;
    min-width: 100%;
}

td {
    border: 1px solid #000;
    user-select: all;
}

tbody tr:hover {
    background: #eee;
}

.dark tbody tr:hover {
    background: #333;
}

thead th {
    padding: 0 0.5em 0 0.5em;
}
</style>