<template>
    <div
            v-show="shown && filter && filter.isfilter && matches > 0 && hasitems > 0"
            :class="{loaded: loaded, finished: finished}" class="filter"

    >
        <div v-if="filter" v-tippy class="title">
            <div v-html="filter.name"></div>
            {{
            store.dev ?
                filter.param_id : ''
            }}
            <div class="symbol" v-html="filter.symbol"></div>
        </div>


        <TransitionGroup
                v-if="filter"
                ref="ul"
                :class="{ real: typeof filter == 'object' && filter.isreal }"
                :title="filter.param_id"
                :xcss="false"
                name="list"
                tag="ul"
                @enter="onEnter"
                @leave="onLeave"
                @before-enter="onBeforeEnter"

        >
            <li
                    v-for="(item, valid, no) in items"
                    :key="valid"
                    :class="{
            inrange:
              item.val_real >= parseFloat(min) &&
              item.val_real <= parseFloat(max),
          }"
                    :data-index="no"
            >
                <div>
                    <label
                            v-tippy
                            :class="{ remarks: item.val_remarks }"
                            :content="item.remarks"
                            :for="'vid'+valid"
                            class="ckbox"
                    >
                        <input
                                :id="'vid'+valid"
                                v-model="checked"
                                :value="valid"
                                type="checkbox"
                                @change="min = max = 'none'"
                        />{{
                        item.val_string
                        }}<span :title="$t('filter.matches')" class="count">
                ({{ item.matches }})</span
                    >
                        <span class="checkmark"></span>
                    </label>
                </div>
            </li
            >
        </TransitionGroup>

        <div class="footer">
            <div v-if="typeof filter == 'object' && filter.isreal" class="minmax">
                <select v-model="min" @change="checked = []">
                    <option hidden value="none">{{ $t("filter.min") }}</option>
                    <option
                            v-for="option in items"
                            :key="option.val_id"
                            :hidden="parseFloat(max) < option.val_real"
                            :value="option.val_real"
                    >
                        {{ option.val_string }}
                    </option>
                </select>
                <select v-model="max" @change="checked = []">
                    <option hidden value="none">{{ $t("filter.max") }}</option>
                    <option
                            v-for="option in items"
                            :key="option.val_id"
                            :hidden="parseFloat(min) > option.val_real"
                            :value="option.val_real"
                    >
                        {{ option.val_string }}
                    </option>
                </select>
            </div>
            <span>
        <input
                :checked="items && checked.length == Object.keys(items).length"
                type="checkbox"
                @change="setall"
        />

        <button :disabled="disablesubmit" class="setfilter" @click="setfilters">
          {{ $t("filter.set") }}
        </button>
      </span>
        </div>
    </div>
</template>

<script>
import {useStore} from "@/stores/page";
import {useFiltersStore} from "@/stores/filters";
import {inject, ref, toRaw, nextTick} from "vue";
//import { useRoute } from "vue-router";


export default {
    name: "StockFilter",
    setup(props) {
        //setup() {
        const store = useStore();
        const storefilters = useFiltersStore();
        const mitt = inject("mitt");
        const $devlog = inject('$devlog');
        var filterstate = ref([]);
        if (props.initialstate) {
            filterstate = ref(toRaw(props.initialstate));
        }
        //const route = useRoute();
        /*    if (route.query && route.query.v)
            filter? String(route.query.v).split(",") : [];*/
        //    console.log("tutu", toRaw(props.initialstate));
        return {store, storefilters, mitt, filterstate, $devlog};
    },
    props: {
        filter: {type: Object, required: true},
        mul: String,
        initialstate: Array,
        allchecked: Boolean,
        items: Object,
        initialmatches: Number,
        shown: Boolean,
        idx: Number,

    },
    data() {
        return {
            search: "" /*FIXME where from?*/,
            checked: [],
            min: "none",
            max: "none",
            otherchecked: [],
            matches: 0,
            loaded: false,
            finished: false
        };
    },

    watch: {
        items() {
            if (!this.filter) return;
            if (Array.isArray(this.filterstate)) {
                if (this.items)
                    this.checked = Object.keys(this.items).filter((value) =>
                        this.filterstate.includes(value)
                    );
                else this.checked = [];
                var keys = [];
                if (this.items) keys = Object.keys(this.items);
                this.otherchecked = this.filterstate.filter(
                    (value) => !keys.includes(value)
                );
                var matches = 0;
                for (var it in this.items) {
                    matches += this.items[it].matches;
                }
                this.matches = matches;
                this.$emit("itemschecked", {
                    param_id: this.filter.param_id,
                    vals: this.checked,
                });
            }
            this.storefilters.vals[this.filter.param_id] = this.checked;
            if (this.$isMobile())
                this.loaded = true;
            else
                setTimeout(() => {
                    this.loaded = true;
                }, this.idx * 100);
            setTimeout(() => {
                this.finished = true
            }, 2000 + this.idx * 100);


        },
        initialstate() {
            this.filterstate = this.initialstate;
            //this.$devlog("vals", "got initialstate", toRaw(this.initialstate));
        },
    },
    methods: {
        // Transition hooks
        onBeforeEnter(el) {
        },
        onEnter(el, done) {
            if (this.$isMobile())
                el.className = 'loaded';
            else
                setTimeout(() => {
                    el.className = 'loaded';
                    //console.log(el.getAttribute('data-index'));
                }, (el.getAttribute('data-index') < 10 ? el.getAttribute('data-index') * 200 + 900 : 2000));

        },
        onLeave(el, done) {

        },
        update() {
            this.$forceUpdate();
        },
        setall() {
            if (this.checked.length) this.checked = [];
            else this.checked = Object.keys(this.items);
        },
        setfilters() {
            var o = {param_id: this.filter.param_id, vals: [], range: []};
            if (
                Number.isNaN(parseFloat(this.min)) ||
                Number.isNaN(parseFloat(this.max))
            ) {
                o.vals = toRaw(this.checked.concat(this.otherchecked));
                console.log("nans", o.vals);
            } else {
                o.range = [this.min, this.max];
                console.log("!nans", o.range);
            }
            // XXX      var vals=this.$route.query.v.split
            try {
                this.storefilters.ranges[o.param_id] = o.range;
                this.storefilters.vals[o.param_id] = this.checked;
            } catch (e) {
                this.$devlog("vals", e);
            }
            console.log("emit setfilters", o);
            this.$emit("setfilters", o);
        },
    },
    computed: {
        hasitems() {
            return typeof this.items === 'object' ? Object.keys(this.items).length > 0 : false
        },
        disablesubmit() {
            var dis = true;
            dis =
                (Number.isNaN(parseFloat(this.min)) ||
                    Number.isNaN(parseFloat(this.max))) &&
                this.initialstate.length == 0 &&
                this.checked.length == 0;
            return dis;
        }
        ,
    }
    ,
    mounted() {
        //this.matches=this.filter.matches;
        this.matches = this.initialmatches;
        if (
            this.filter &&
            this.storefilters.ranges &&
            this.storefilters.ranges[this.filter.param_id]
        ) {
            this.min = this.storefilters.ranges[this.filter.param_id][0];
            this.max = this.storefilters.ranges[this.filter.param_id][1];
        }
    }
    ,
}
;
</script>

<style scoped>
.title > div {
    margin: 0 0.5em;
    padding: 0;
}

.symbol {
    content: "tu symbol chyba";
}

.symbol:empty {
    min-height: 1.5em;
}

.filter {
    position: relative;
    display: inline-block;
    border: 1px solid #000;
    vertical-align: top;
}

.dark .filter {
    border: 1px solid #666;
}

.filter .title {
    border-bottom: 1px solid #000;
}

.dark .filter .title {
    border-bottom: 1px solid #666;
}

.filter ul {
    list-style-type: none;

    margin: 0;
    vertical-align: top;
    max-height: 10em;
    min-height: 10em;
    overflow-y: auto;
    overflow-x: hidden;

}

.filter ul.real {
    max-height: calc(10em - 2em);
    min-height: calc(10em - 2em);
}

.filter .minmax {
    height: 2em;
    min-height: 2em;
    max-height: 2em;
}

.filter .minmax select {
    width: 50%;
}

.filter ul div {
    padding-right: 1em;
    text-align: left;
}

.count {
    position: absolute;
    right: -1em;
    display: inline-block;
    width: 7em;
    padding-left: 2em;
    text-align: right;
    vertical-align: bottom;
}

label {
    user-select: none;
    display: inline-block;
    width: 100%;
}

li > div > label {
    padding-right: 3em;
}

.symbol {
    font-weight: bold;
}

.filter .title,
.filter .footer {
    background: #eee;
    margin: 0;
    padding: 0;
}

.filter .footer select {
    background: #fff;
    margin: 0;
    padding: 0;
    height: 2em;
    display: inline-block;
    vertical-align: bottom;
}

.dark .filter .footer select {
    background: #383838;
    color: #fff;
}

.dark .filter .title,
.dark .filter .footer {
    background: #383838;
}

.filter .footer {
    border-top: 1px solid #000;
    text-align: right;
}

.dark .filter .footer {
    border-top: 1px solid #666;
}

li {
    position: relative;
    width: 100%;
}

label {
    border-bottom: 1px dashed #00000000;
}

.dark label:hover {
    border-bottom: 1px dashed #999;
}

label:hover {
    border-bottom: 1px dashed #000;
}

.footer input {
    vertical-align: bottom;
}

.inrange {
    background: #eee;
}

.dark .inrange {
    background: #383838;
    color: #fff;
}

.ckbox {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.ckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0.2em;
    left: 0.2em;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.ckbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ckbox input:checked ~ .checkmark {
    background-color: #2196f3;
}

.inrange .ckbox .checkmark,
.inrange .ckbox:hover .checkmark {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.ckbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.ckbox .checkmark:after {
    left: 5px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


ul {
    padding: 0;
    width: 100%;
}

.filter:not(.loaded) {
    display: none;
}

:not(.mobile) .filter.loaded {
    animation: showingul 1s;
}

.mobile .filter.loaded {
    animation: showingli 0s;
}


:not(.mobile) li.loaded {
    animation: showingli 1s;
}

.mobile li.loaded {
    animation: showingli 0s;
}

.finished li.loaded {
    opacity: 1;
    transform: translateX(0%);
}

li:not(.loaded) {
    display: none;
}


@keyframes showingul {
    from {
        transform: translateY(-100%);
    }
    80% {
        transform: translateY(10%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes showingli {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    80% {
        opacity: 0.4;
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}
</style>
