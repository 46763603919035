<template>
    <div>
        <label :class="{ pushed: darkMode }" class="pushckbox">
            <input v-model="darkMode" type="checkbox"/>
            <AkarIconsSun v-if="darkMode"/>
            <AkarIconsMoon v-else/>
        </label></div>
</template>

<script>
import {useStore} from "@/stores/page";

//import { ref } from "vue";
import AkarIconsMoon from "@/components/Icons/AkarIconsMoon.vue";
import AkarIconsSun from "@/components/Icons/AkarIconsSun.vue";

export default {
    name: "darkModeButton",
    emits: ["set"],
    components: {AkarIconsMoon, AkarIconsSun},
    setup() {
        const store = useStore();
        return {store};
    },
    data() {
        return {
            darkMode: this.store.dark,
        };
    },
    watch: {
        darkMode(newDarkMode) {
            this.store.dark = newDarkMode;
            console.log("dark set", this.store.dark);
            this.$emit("set", newDarkMode);
        },
    },
    methods: {},
    mounted() {
        /*
        console.log("typeof this.store.dark", typeof this.store.dark);
        if (typeof this.store.dark == "boolean") this.darkMode = this.store.dark;
        else
          this.darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;*/
    },
};
</script>

<style scoped>
div {
    display: inline-block;
}

button:active label {
    transform: translateY(2px);
}

.dark label {
    color: #ddd;
}

label.pushckbox {
    box-shadow: none;
}

label svg {
    width: 22px;
    height: 22px;
}
</style>
