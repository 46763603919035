<script>
export default {
	name: 'FileIconsCurl',
};
</script>
<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
		<path fill="currentColor" d="M328.712 115.557c15.126-3.33 27.726-16.16 28.662-33.992c1.51-28.784-28.482-49.182-53.858-36.628c-17.732 8.772-23.983 29.074-18.008 46.049L107.585 395.995c-15.841 3.29-29.218 16.599-30.208 34.93c-1.564 28.966 28.955 48.91 54.802 35.811c18.014-9.128 24.412-29.571 18.426-46.499zm2.321-54.69c13.488 8.16 13.543 27.972.098 35.576c-13.445 7.605-30.397-2.65-30.44-18.414c-.043-15.764 16.854-25.321 30.342-17.162M104.76 450.14c-13.728-7.914-13.728-27.806 0-35.72c13.727-7.914 30.98 2.032 30.98 17.86s-17.253 25.774-30.98 17.86M18.848 216.802c25.131 14.488 56.716-3.72 56.716-32.697s-31.585-47.185-56.716-32.697c-25.13 14.488-25.13 50.906 0 65.394m8.592-50.557c13.727-7.914 30.98 2.032 30.98 17.86s-17.253 25.774-30.98 17.86c-13.728-7.914-13.728-27.806 0-35.72m133.594-25.63c25.131 14.489 56.715-3.72 56.715-32.697s-31.584-47.185-56.715-32.697c-25.13 14.489-25.13 50.906 0 65.394m8.591-50.557c13.728-7.914 30.98 2.032 30.98 17.86s-17.252 25.775-30.98 17.86c-13.727-7.913-13.727-27.806 0-35.72M18.85 283.424c-25.13 14.489-25.13 50.906 0 65.394c25.131 14.489 56.716-3.72 56.716-32.697s-31.585-47.185-56.716-32.697m8.592 50.558c-13.728-7.914-13.728-27.807 0-35.72s30.98 2.031 30.98 17.86s-17.253 25.774-30.98 17.86M511.43 73.299c-4.906-28.403-38.674-41.646-60.637-23.78c-13.096 10.652-16.185 27.643-10.853 41.861L262.69 395.135c-16.372 3.173-30.185 17.054-30.76 35.934c-.884 28.991 30.098 48.2 55.633 34.491c17.32-9.297 23.387-29.046 17.817-45.563l177.85-304.565c18.085-3.916 31.775-21.437 28.2-42.133m-31.151-13.588c15.092 4.827 19.345 24.282 7.638 34.934c-11.708 10.652-30.675 4.584-34.06-10.895c-3.384-15.48 11.33-28.867 26.422-24.04M279.455 450.455c-13.945 7.487-30.866-3.004-30.383-18.837c.482-15.833 18.009-25.242 31.472-16.895c13.463 8.347 12.857 28.246-1.089 35.732" />
	</svg>
</template>
