<template>
    <div class="ppgroup">
        <div class="pp">
            <label for="pptop">{{ $t("product.perpage") }}</label
            ><select v-model="pp">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select
        ><label for="pp"></label>
        </div>
        <span class="ppitems">{{
            $t("product.positions", {
              from: this.page * this.pp - (this.pp - 1),
              to:
                  this.total > this.page * this.pp
                      ? this.page * this.pp
                      : this.total,
              total: this.total,
            })
            }}</span>
        <v-pagination
                v-model="page"
                :pages="Math.ceil(total / pp)"
                :range-size="1"
                active-color="#4383af"
        />
    </div>
</template>

<script>
import {useRoute} from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import {nextTick, ref, inject} from "vue";
import {useStore} from "@/stores/page.js";
import StockImageZoom from "@/components/Stock/StockImageZoom.vue";
import CategoryTreeBreadCrumb from "@/components/CategoryTree/CategoryTreeBreadCrumb.vue";
import CategoryTreeLeafs from "@/components/CategoryTree/CategoryTreeLeafs.vue";
import stockItem from "@/components/Stock/StockItem.vue";
import stockFilters from "@/components/Stock/StockFilters.vue";

export default {
    name: "PagerLine",
    data: function () {
        return {
            lockroute: false,
        }
    },
    props: {
        totalitems: Number,
        me: String
    },
    setup(props) {
        const route = useRoute();
        const mitt = inject("mitt");

        var page = ref(1);
        var pp = ref(25);
        if (route.query.p) page = ref(parseInt(route.query.p));
        if (route.query.pp) pp = ref(parseInt(route.query.pp));
        var total = ref(props.totalitems);
        const $devlog = inject('$devlog');
        return {page, pp, total, mitt, $devlog}
    },
    mounted() {
//        if (!this.$devlog) this.$devlog=inject('$devlog');
    },
    components: {
        VPagination,
    },
    methods: {
        setpage(val) {
            this.lockcounter = true;
            this.page = val;
            nextTick(() => {
                this.lockcounter = false;
            });
        }
    },
    watch: {
        $route(to) {
            this.lockroute = true;
            if (to.query.p) {
                this.page = parseInt(to.query.p)
            } else {
                this.page = 1
            }
            ;
            if (to.query.pp) {
                this.pp = ref(parseInt(to.query.pp))
            } else {
                this.pp = 25
            }
            ;
            nextTick(() => {
                this.lockroute = false
            })
//      this.$forceUpdate();
        },
        page() {
            var newquery = {...this.$route.query};
            if (this.page > 1 && parseInt(this.page) != parseInt(newquery.p)) newquery.p = this.page;
            else delete newquery.p;

            if (this.lockroute) {
                this.$devlog('route', 'PagerLine locked')
                return;
            }
            this.$router.push({path: this.$route.path, query: newquery});
        },
        pp(to) {
            var newquery = {...this.$route.query};
            if (parseInt(to) != parseInt(newquery.pp) || to == 25) {
                if (to == 25) delete newquery.pp;
                else newquery.pp = to;
                this.$devlog("route", "watch pp");
                this.$router.push({query: newquery});
            }
        },
    },
}
</script>

<style scoped>

</style>
