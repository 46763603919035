<template>
  <a href="/img/grupa.png" ref="ahover" target="_blank">
    <img
      src="/img/grupa.png"
      ref="hover"
      class="hover"
      id="plisthover"
      :alt="alt"
      :title="alt"
      :class="{ hidden: hidden, hiding: hiding }"
      @click.prevent="open"
      @mouseleave="hide"
  /></a>
</template>

<script>
import { inject, nextTick, ref } from "vue";

export default {
  name: "StockImageZoom",
  props: {
    alt: String,
  },
  setup() {
    const mitt = inject("mitt");
    var hover = ref(null);
    return { mitt, hover };
  },
  data: function () {
    return {
      hidden: true,
      hiding: false,

      height: 480,
      width: 640,
      href: "",
    };
  },
  mounted() {
    var that = this;
    this.mitt.on("hideprodimg", () => {
      this.hide();
    });
    this.mitt.on("showprodimg", (o) => {
      that.hiding = that.hidden = false;
      that.showing = true;
      //o.x = o.x - that.width / 2;
      //      console.log(o.x, o.x - that.width / 2);
      try {
        that.$refs.hover.style.display = "block";
      } catch (e) {
        // eslint-disable-next-line
      }
      nextTick(() => {
        if (!that.$refs.hover) return;
        try {
          that.$refs.hover.title = o.title;
          // something is no yes
          //that.$refs.hover.style.left = o.x + that.width / 4 + "px";
          that.$refs.hover.style.left = that.width / 2 + "px";
          that.$refs.hover.style.top = o.y - that.height / 4 + "px";
          that.$refs.hover.src = o.src;
          that.$refs.ahover.href = o.src;
          that.href = o.src;
          setTimeout(function () {
            that.hidden = that.showing = that.hiding = false;
          }, 300);
        } catch (e) {
          // eslint-disable-next-line
        }
      });
    });
  },
  methods: {
    open: function () {
      if (!this.$refs.hover) return;
      if (this.href) {
        this.hide();
        window.open(this.href);
      }
    },
    hide: function () {
      var that = this;
      if (!that.$refs.hover) return;
      this.hiding = true;
      this.hidden = false;
      this.hiding = true;

      setTimeout(function () {
        that.hidden = true;
        that.hiding = false;

        that.$refs.hover.style.left = "";
        //that.$refs.hover.style.display = "none";
      }, 300);
    },
  },
};
</script>

<style scoped>
.hiding {
  animation: bounce-out 0.3s;
  display: block;
}
.hidden {
  display: none;
  position: absolute;
  left: -1920px;
  top: -1080px;
  width: 1px;
  height: 1px;
}
img {
  position: fixed;
  z-index: 150 !important;
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.75);
  animation: bounce-in 0.5s;
  border-radius: 5px;
  background: #fff;
  width: 640px;
  height: 480px;
  position: fixed;
  display: none;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
