<template>
  <!--  -->
    <ul v-show="showcategories" v-click-away="closeMenu" :style="'top:'+menutop+'px; left: '+menuleft+'px'"
        class="categories"
        @mouseenter="mitt.emit('catOpen', true)" @mouseleave="mitt.emit('catOpen', false)"
    >
        <li v-for="item in store.treep"
            v-show="item.depth &&  isparentprequal(selected.path, item.path)"
            :key="item.path"
            :class="{depth2: item.depth==2, depth3: item.depth==3, selected: selected.path==item.path, fin: item.fin}">
            <a :href="'/s/'+item.path" @click.prevent="select(item)">
                {{ item.name }}
                <span class="count">({{ item.count }})</span>
            </a>
        </li>
    </ul>
</template>

<script>
import CategoryTreeItem from "./CategoryTreeItem.vue";
import {inject, nextTick, toRaw} from "vue";
import {useStore} from "@/stores/page";
import {useRoute} from "vue-router";

export default {
    name: "CategoryTreeMew",
    components: {CategoryTreeItem},
    setup() {
        const store = useStore();
        const mitt = inject("mitt");

        return {store, mitt};
    },
    data() {
        return {
            showcategories: 0,
            showcategoriestimer: null,
            selected: {name: 'Main', path: '/'},
        };
    },
    props: {
        menutop: Number,
        menuleft: Number
    },
    watch: {},
    computed: {},
    mounted() {
        this.selected.path = this.$route.path
            .replace("%2F", "/")
            .replace(/^\/s/, "")
            .replace(/^\//, "")
            .replace(/\/$/, "");
        this.fetchCategories(this.$i18n.locale);
        var that = this;
        //console.log(this.$i18n.locale)
        this.mitt.on('prodmenu', () => {
            var path = this.$route.path
                .replace("%2F", "/")
                .replace(/^\//, "")
                .replace(/\/$/, "");
            that.showcategories++;
            if (path != 's')
                this.$router.push('/s');
        });
        this.mitt.on("menuClose", () => {
            that.showcategories = 0;
        });

        this.mitt.on('catOpen', (onoff) => {
            if (onoff)
                that.showcategories++;
            else
                that.showcategoriestimer = setTimeout(() => {
                    if (that.showcategories)
                        that.showcategories--;
                }, 500);
        });
        this.mitt.on("chlang", (lang) => {
            var path = this.$route.path
                .replace("%2F", "/")
                .replace(/^\/s/, "")
                .replace(/^\//, "")
                .replace(/\/$/, "");
            console.log('lang changed', path, lang, toRaw(this.store.treep[path]));
            if (
                this.store.treep &&
                this.store.treep[path] &&
                this.store.treep[path].lang &&
                this.store.treep[path].lang[lang]
            ) {
                console.log('lang changed route', lang);
                var newquery = {...this.$route.query};
                if (lang == "pl") delete newquery.l;
                else newquery.l = lang;
                //console.log('recv chlang', this.item.lang[lang])
                this.$router.push({
                    path: "/s/" + this.store.treep[path].lang[lang],
                    query: newquery,
                });
            }
            this.fetchCategories(lang, true);
        });
    },
    methods: {
        select(item) {
            console.log(item.path, this.$route.path.replace('/s/', ''));
            if (item.path == this.$route.path.replace('/s/', '')) {
                clearTimeout(this.showcategoriestimer);
                this.showcategories = 0;
                return;
            }
            if (item.fin) {
                clearTimeout(this.showcategoriestimer);
                this.showcategories = 0;
            }
            this.selected = item;
            this.$router.push('/s/' + item.path);
        },
        isparentprequal(parent, child) {
            var childparts = child.split('/');
            var parentparts = parent.split('/');
            var match = true;
            for (var i = 0; i < childparts.length - 1; i++)
                if (parentparts[i] != childparts[i]) {
                    match = false;
                    break;
                }
            return match;
        },
        closeMenu() {
            clearTimeout(this.showcategoriestimer);
            this.showcategories = 0
        },
        fetchCategories(lang) {
            //console.log('fetchCategories',lang,chlang,this.store.path)
            var flang = lang === undefined ? this.$i18n.fallbackLocale : lang;
            this.$axios
                .get("/_t/" + flang, {
                    params: {
                        ver: this.store.version,
                        rand: Math.random(),
                    },
                })
                .then((response) => {
                    this.tree = response.data;
                    this.store.tree = response.data;
                    this.store.treep = {};
                    this.traversep(this.tree);
                    nextTick(() => {
                        //console.log('categories propagated')
                        this.mitt.emit("categories-propagated");
                    });
                    //console.log('ct end')
                });
        },
        traversep(branch, depth = 0) {
            this.store.treep[branch.path] = {
                path: branch.path,
                lang: branch.lang,
                name: branch.name,
                gc: branch.group_code,
                count: branch.count,
                depth: depth
            };
            if (branch.children && branch.children.length > 0) {
                for (var i = 0; i < branch.children.length; i++) {
                    this.traversep(branch.children[i], depth + 1);
                }
            } else this.store.treep[branch.path]['fin'] = true;
        },
    },
};
</script>

<style scoped>
.categories {
    position: fixed;
    z-index: 8;
    width: 24em;
    max-width: 100vw;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: #e6e9eb;
    border: 1px solid #000;
    height: auto;

}

@media (min-width: 1600px) {
    .categories {
        margin-left: 97px;
    }
}

li {
    float: none;
    clear: both;
    background: #e6e9eb;
    cursor: pointer;
}

.dark li {
    background: #353535;
}

li:hover {
    background: #aaa;
}

.dark li:hover {
    background: #555;
}

a {
    user-select: none;
    text-decoration: none;
    display: block;
    width: 100%;
    max-width: 60vw;
    text-overflow: ellipsis;
    padding-left: 0.5em;
    position: relative;
}


.count {
    position: absolute;
    right: 0;
}

.mobile .count {
    display: none;
}

.selected {
    background: #aaa;
}

.dark .selected {
    background: #555;
}

.depth2 {
    padding-left: 1em;
}

.depth3 {
    padding-left: 2em;
}
</style>
