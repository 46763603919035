<template>
    <nav v-show="compIsOpen && left"

         id="company"
         ref="company"
         v-click-away="closeMenu"

         class="company"
         @mouseleave="compOpenSend({OnOff:false})"
         @mouseover="compOpenSend({OnOff:true})"
    >
        <ul v-show="left>0" ref="menu" :style="'margin-left: ' + left+'px; top: ' + topbarHeight"
            class="menu">
            <li v-for="(data,companyname) in comp" :class="{selected: company==companyname}"><a
                    :href="'/company/'+companyname.replace('.', '/')+(l ? '?l='+l : '')">{{
                data[store.lang].title
                }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import {useRoute} from "vue-router";

export default {
    name: "CompributionsMenu",
    setup() {
        const store = useStore();
        const route = useRoute();
        const mitt = inject("mitt");
        var company;
        if (route.path.startsWith("/comp"))
            company = route.params.company;
        else
            company = '';
        var l = route.query.l ? route.query.l : '';
        return {store, mitt, company, l};
    },
    data: function () {
        return {
            comp: [],
            compOpen: null,
            compIsOpen: false,
            left: 0,
            top: 0,
            topbarHeight: '61px'
        }
    },
    mounted() {
        this.getComp();
        try {
            //this.topbarHeight = this.$parent.$refs.topbar.clientHeight + "px";
        } catch (e) {
            console.log(e);
        }
        this.mitt.on("chlang", (lang) => {
        })
        this.mitt.on("menuClose", () => {
            this.compIsOpen = false;
        });
        this.mitt.on("compOpen", (state) => {

            var that = this;
            var OnOff = state.OnOff;
            if (state.left)
                this.left = state.left;
            this.top = state.top;
            if (state.OnOff || (typeof state == 'boolean' && state)) {

                that.compIsOpen = true;

                if (that.compOpen) {
                    clearTimeout(that.compOpen);
                    that.compOpen = null;
                }
            } else {
                if (that.compOpen) {
                    clearTimeout(that.compOpen);
                    that.compOpen = null;
                }
                that.compOpen = setTimeout(() => {
                    that.compIsOpen = false;
                }, 500);
            }
        });
    },
    methods: {
        getComp() {
            this.$axios
                .get("/_pg/company/", {})
                .then((response) => {
                    this.comp = response.data;
                });
        },
        closeMenu() {
            this.compOpen = null;
            this.compIsOpen = false;
        },
        compOpenSend: function (isOver) {
            this.mitt.emit("compOpen", isOver);
        },
    }
}
</script>

<style scoped>
.company .menu {
    background: #e6e9eb;
    color: #fff;

    padding: 0;
    top: 0px;
    position: fixed;
    z-index: 9;
}

.company ul {
    list-style-type: none;

}

@media (min-width: 1600px) {
    .company .menu {

    }
}

.dark .company .menu {
    background: #353535;
    color: #fff;
    margin-left: -5vw;
}

ul {
    background: #e6e9eb;
    color: #fff;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    z-index: 4;
}

.dark ul {
    background: #353535;
    color: #fff;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    display: inline-block;
    width: 100%;
}

li:hover {
    background: #aaa;
}

.dark li:hover {
    background: #555;
}

li {
    cursor: pointer;
}

#demo {
    display: none;
    position: absolute;
    top: 15vh;
    z-index: 99;
    background: #fff;
    color: #000;
    height: 20vh;
    overflow: auto;
}

.selected {
    background: #aaa;
}

.dark .selected {
    background: #555;
}

xmp {
    overflow-y: scroll;
    min-height: 20vh;
    z-index: 99;
    position: fixed;
    right: 0px;
    bottom: 0px;
    background: #fff;
    color: #000;
}
</style>
