<script>
export default {
	name: 'ClarityFilterLine',
};
</script>
<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 36 36">
		<path fill="currentColor" d="M33 4H3a1 1 0 0 0-1 1v1.67a1.79 1.79 0 0 0 .53 1.27L14 19.58v10.2l2 .76V19a1 1 0 0 0-.29-.71L4 6.59V6h28v.61L20.33 18.29A1 1 0 0 0 20 19v13.21l2 .79V19.5L33.47 8A1.81 1.81 0 0 0 34 6.7V5a1 1 0 0 0-1-1" class="clr-i-outline clr-i-outline-path-1" />
		<path fill="none" d="M0 0h36v36H0z" />
	</svg>
</template>
