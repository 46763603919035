<template>
    <fluentMdl2Compare/>

    <div id="content" ref="content">
        <table>
            <thead>
            <tr>
                <th class="roplaindex">{{ $t('product.roplaindex') }}</th>
                <th v-for="item in nothidden">
                    <a :href="'/p/'+item" target="_blank">{{ item }}</a>
                </th>
            </tr>
            <tr>
                <th>{{ $t('product.mfrpartno') }}</th>
                <th v-for="item in nothidden">
                    {{ (itemdata[item] != undefined ? itemdata[item].product.partno : '') }}
                </th>
            </tr>
            <tr>
                <th>{{ $t('product.name') }}</th>
                <th v-for="item in nothidden">
                    {{ (itemdata[item] != undefined ? itemdata[item].product.lang[store.lang].itemname : '') }}
                </th>
            </tr>
            <tr class="amount">
                <th>{{ $t('product.amount') }}</th>
                <td v-for="item in nothidden" class="amount">
                    <input v-model="items[item]"/>
                </td>
            </tr>
            </thead>
            <tbody v-for="leveldec in nothidden.length">
            <tr v-for="pid in getlevelpids(nothidden.length + 1 - leveldec)"
                :class="{differ: !issame(pid)}">
                <th>{{ pidtitles[pid] }}</th>
                <td v-for="item in nothidden">{{ pidval(item, pid) }}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <button :title="$t('compare.genxlsx')" @click="generatexlsx">
        <BiFiletypeXlsx/>
    </button>
    <button :title="$t('compare.genpdf')" @click="generatepdf">
        <BiFiletypePdf/>
    </button>
    <button :title="$t('compare.compare2basket')" @click="tobasket">
        <FontistoShoppingBasket/>
    </button>
</template>

<script>
import fluentMdl2Compare from "@/components/Icons/FluentMdl2Compare.vue";
import {useRoute} from "vue-router";
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import {intersection} from "lodash";
import Vue3Html2pdf from "@erst-vg/vue3-html2pdf";

import FontistoShoppingBasket from "@/components/Icons/FontistoShoppingBasket.vue";
import BiFiletypeXlsx from "@/components/Icons/BiFiletypeXlsx.vue";
import BiFiletypePdf from "@/components/Icons/BiFiletypePdf.vue";

export default {
    name: "BasketCompare",
    components: {fluentMdl2Compare, BiFiletypePdf, FontistoShoppingBasket, BiFiletypeXlsx},
    setup() {
        const route = useRoute();
        const mitt = inject("mitt");
        var hidden = [];
        var items = {};
        if (route.query.items)
            items = Object.fromEntries(route.query.items.split(',').map(s => s.split(':')));
        Object.keys(items).forEach((item) => {
            console.log(item);
            if (!items[item])
                items[item] = 1
            else {
                if (Number(items[item]) < 0) {
                    hidden.push(item);
                }
                items[item] = Math.abs(Number(items[item]));
            }
        });
        const store = useStore();
        return {mitt, items, store, hidden}
    },
    data() {
        return {
            itemdata: {},
            paramsdata: {},
            itemhead: {},
            pidtitles: {},
            pidlevels: {},
            itempids: {},
            pidssorted: [],
            xlsxdata: [],
        };
    },
    mounted() {
        this.$axios.get('/_is/', {
            params: {
                items: this.nothidden.join(',')
            }
        }).then((resp) => {
            var items = Object.keys(resp.data);
            this.resp = resp.data;
            var pids = [];
            for (var i = 0; i < items.length; i++) {
                this.itemdata[items[i]] = resp.data[items[i]];
                Object.keys(resp.data[items[i]].head[this.store.lang]).forEach((pid) => {
                    this.pidtitles[pid] = resp.data[items[i]].head[this.store.lang][pid].name;
                    pids = pids.concat(Object.values(resp.data[items[i]].head[this.store.lang]));

                    if (!this.pidlevels[pid]) {
                        this.pidlevels[pid] = []
                    }
                    this.pidlevels[pid].push([items[i]]);
                    if (!this.itempids[items[i]])
                        this.itempids[items[i]] = [];
                    this.itempids[items[i]].push(pid);
                });
            }
            pids = pids.sort((a, b) => {
                return a.sort - b.sort
            })
            this.pidssorted = [...new Set(pids.map(item => item.param_id))];
        });
    },
    methods: {
        tobasket() {
            if (confirm(this.$t('compare.compare2basketask'))) {
                this.store.basket = this.items;
                this.$router.push('/basket');
            }
        },
        generatexlsx() {
            var data = this.generatexlsxdata();
            this.$axios.post('/_compare', JSON.stringify(data)).then((resp) => {
                window.location = '/_compare/xlsx/' + resp.data.id;
            });

        },
        generatepdf() {
            var data = this.generatexlsxdata();
            this.$axios.post('/_compare', JSON.stringify(data)).then((resp) => {
                window.location = '/_compare/pdf/' + resp.data.id;
            });

        },
        generatexlsxdata() {
            var xlsx = [];
            var line = [];
            var items = this.nothidden;
            var issame = [];
            line.push(this.$t('product.roplaindex'));
            items.forEach(item => line.push(item));
            xlsx.push(line);
            line = [];
            line.push(this.$t('product.mfrpartno'));
            items.forEach(item => {
                line.push(this.itemdata[item] != undefined ? this.itemdata[item].product.partno : '');
            });
            xlsx.push(line);
            line = [];
            line.push(this.$t('product.name'));
            items.forEach(item => {
                line.push(this.itemdata[item] != undefined ? this.itemdata[item].product.lang[this.store.lang].itemname : '');
            });
            xlsx.push(line);
            line = [];
            issame.push(true);
            issame.push(true);
            issame.push(true);

            for (var level = this.nothidden.length - 1; level > 0; level--) {
                var levelpids = this.getlevelpids(level + 1);
                levelpids.forEach(pid => {
                    line = [];
                    line.push(this.pidtitles[pid]);
                    issame.push(this.issame(pid));
                    items.forEach(item => {
                        line.push(this.pidval(item, pid));
                    });
                    xlsx.push(line);
                })
            }

            return {
                grid: xlsx,
                same: issame,
                title: this.$t('product.comparison'),
                created: this.$t('product.comparisoncreated'),
                createtime: new Date().toLocaleTimeString(this.store.lang, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                }),
            };
        },
        pidval(item, pid) {
            if (this.itemdata[item]) {
                var found = this.itemdata[item].product.params[this.store.lang].find(item => item.param_id == pid);
                if (found && found.val)
                    return found.val;
            }
        },
        issame(pid) {
            var items = Object.keys(this.itemdata);
            var piddata = [];
            for (var i = 0; i < items.length; i++) {
                var found = this.itemdata[items[i]].product.params[this.store.lang].find(item => item.param_id == pid);
                if (found)
                    piddata.push(found.val);
                else return false;
            }
            return new Set(piddata).size == 1;
        },
        allparams() {
            var items = Object.keys(this.itemdata);
            var itemparams = [];
            for (var i = 0; i < items.length; i++) {
                var newarray = this.itemdata[items[i]].product.params[this.store.lang].map(function (value) {
                    return value.param_id
                });
                itemparams.push(newarray);
            }
            var allparams = Array.from(new Set(...itemparams));

            var cntparams = {};
            for (var i = 0; i < allparams.length; i++) {
                for (var j = 0; j < items.length; j++) {
                    if (cntparams[allparams[i]] == undefined)
                        cntparams[allparams[i]] = [];
                    if (this.itemdata[items[j]].product.params[this.store.lang].find(item => item.param_id == allparams[i]) != undefined)
                        cntparams[allparams[i]].push(items[j]);
                }
                // if (cntparams[allparams[i]].length == items.length) {
                //     console.log('delete ', allparams[i], cntparams[allparams[i]].length, items.length);
                //     delete cntparams[allparams[i]];
                // }
            }
            return cntparams;
        },
        getlevelpids(level) {
            var levelpids = [];

            this.pidssorted.forEach((pid) => {
                if (this.pidlevels[pid] && this.pidlevels[pid].length == level)
                    levelpids.push(pid);
            })
            return levelpids;
        },
        gettitle(pid) {
            var items = Object.keys(this.itemdata);
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (this.itemdata[item].head[this.store.lang][pid] != undefined) {
                    return this.itemdata[item].head[this.store.lang][pid].name;
                }
                console.log('gt', item);
            }
            ;
            return items;
        }
    },
    computed: {
        itemcount() {
            return this.nothidden.length;
        },
        nothidden() {
            return Object.keys(this.items).filter((el) => !this.hidden.includes(el));
        }
    }
}
</script>

<style scoped>
svg {
    width: 64px;
    height: 64px;
}

td:not(:last-child), th:not(:last-child) {
    border-right: 1px solid #000;
}

.dark td:not(:last-child), .dark th:not(:last-child) {
    border-right: 1px solid #666;
}

#content {
    overflow-x: auto;
    max-width: 100vw;
}


.differ td, .differ th {
    font-style: italic;
    background: #eee;
}

.dark .differ td, .dark .differ th {
    background: #333;
}

th:first-child {
    white-space: nowrap;
}

.amount td, .amount input {
    text-align: right;
}

tbody tr:hover td, tbody tr:hover th {
    border-bottom: 1px solid #000;
}
</style>