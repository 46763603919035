<template>
    <div id="mngdialog" ref="mngdialog">

        <table v-show="product && product.lang && product.lang[store.lang]" id="main">
            <tr>
                <td class="ftop fleft fright title" colspan="4">{{ $t('product.availquery') }}</td>
            </tr>
            <tr class="header">
                <td class="fleft partno mfr">{{ product.partno }}</td>
                <td class="mfr fright" colspan="3">{{ product.man_name }}</td>
            </tr>
            <tr class="header">
                <td class="fleft fbottom">{{ product.itemcode }}</td>
                <td class="fbottom fright" colspan="3">{{ product.lang ? product.lang[store.lang].itemname : '' }}</td>
            </tr>
            <tr>
                <th class="upper fleft  high">{{ $t('product.query') }}</th>
                <td class="fright"></td>
                <th class="upper high">{{ $t('product.avail') }}</th>
                <td class="fright"></td>
            </tr>
            <tr>
                <td class="fleft"><em>{{ $t('product.quantity') }}</em>
                    <input id=quantity v-model="amount"
                           :placeholder="$t('product.moq-short') + ': '"
                           :title="$t('product.moq') + ': ' + product.moq + product.unit+'\n' + $t('product.multi') + ': ' + product.multi"
                           class="moq" @change="checkAmount" @keyup="removeNonDigits"/>{{ product.unit }}
                    <div class="error">{{ error }}&nbsp;</div>
                </td>
                <th class=""></th>
                <td class="fleft fright" colspan="2">
                    <table class="avail">
                        <tr v-for="atdate in avail">
                            <td :class="{ onsite: !atdate.date }" class="quantity">{{
                                atdate.quantity
                                }} {{ product.unit }}
                            </td>
                            <td>
                                <span v-if="atdate.date">{{ $t('product.arrival') }}&nbsp;{{ atdate.date }}</span>
                                <span v-else class="onsite">{{ $t('product.onsite') }}</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr class="querypriceamount">
                <td class="fleft"><label for="querypriceamount">{{
                    $t('product.querypriceamount')
                    }}</label></td>
                <td>
                    <input id="querypriceamount" v-model="querypriceamount" type="checkbox"/>
                </td>
                <td class="fleft"></td>
                <td class="fright"></td>
            </tr>
            <tr class="queryavailamount">
                <td class="fleft nowrap"><label for="queryavailamount">{{
                    $t('product.queryavailamount')
                    }}</label></td>
                <td>
                    <input id="queryavailamount" v-model="queryavailamount" type="checkbox">
                </td>
                <td class="fleft"></td>
                <td class="fright"></td>
            </tr>

            <tr class="ftop">
                <th class="fleft fright high spacingtop" colspan="2">{{ $t('product.querycomment') }}</th>
                <!-- xxx -->
                <td class="fleft" colspan="2" rowspan="2">

                    <table class="userdata">
                        <tr>
                            <td class="requiredfields" colspan="2">{{ $t('product.requiredfields') }}</td>
                        </tr>
                        <tr>
                            <td class="">
                                <label :class="{ errlabel: hasSomeUserData && !firstname }" for="firstname">{{
                                    $t('user.firstname')
                                    }}</label>
                            </td>
                            <td>
                                <input id="firstname" v-model="firstname"/>
                            </td>
                        </tr>
                        <tr>
                            <td class="">
                                <label :class="{ errlabel: hasSomeUserData && !lastname }" for="lastname">{{
                                    $t('user.lastname')
                                    }}</label>
                            </td>
                            <td>
                                <input id="lastname" v-model="lastname"/>
                            </td>
                        </tr>
                        <tr>
                            <td class="">
                                <label :class="{ errlabel: hasSomeUserData && !email }" for="email">{{
                                    $t('user.emailaddr')
                                    }}</label>
                            </td>
                            <td>
                                <input id="email" v-model="email"/>
                            </td>
                        </tr>
                        <tr>
                            <td class="">
                                <label :class="{ errlabel: hasSomeUserData && !phone }" for="phone">{{
                                    $t('user.phone')
                                    }}</label>
                            </td>
                            <td>
                                <input id="phone" v-model="phone"/>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button :disabled="disableSubmit" class="sendquery" @click="sendQuery">
                                    {{ $t('product.sendquery') }}
                                </button>
                            </td>
                        </tr>
                    </table>

                </td>
            </tr>
            <tr>
                <td class="fleft fright" colspan="2">
                    <textarea v-model="comment" rows="5"></textarea>
                </td>

            </tr>
        </table>
    </div>
</template>

<script>
import {useStore} from "@/stores/page";
import {inject, toRaw, nextTick} from "vue";
import {MaskInput} from 'vue-3-mask';
import {closeModal} from "jenesius-vue-modal";

export default {
    name: "ProductQuery",
    components: {MaskInput},
    props: {
        _product: Object,
        _avail: Object,
        itemcode: String
    },
    setup() {
        const store = useStore();

        return {store};
    },
    data: function () {
        return {
            product: {},
            avail: {},
            comment: '',
            amount: null,
            amountValid: true,
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            queryavailamount: false,
            querypriceamount: false,
            error: '',
        };

    },
    mounted() {

        if (this.itemcode) {
            var that = this;
            this.$root.coverstages["product"] = "loading.product";
            this.$axios
                .get("/_i/" + this.itemcode, {
                    params: {
                        ver: this.store.version,
                    },
                })
                .then((response) => {
                    delete this.$root.coverstages["product"];
                    console.log(
                        "product received",
                        response.data.product,
                        that.lang,
                        that.store.lang
                    );
                    that.product = response.data.product;
                    that.getAvailability(that.product.itemcode);
                });
        } else {
            this.product = this._product;
            this.avail = this._avail[this.product.itemcode];
        }
        if (this.store.dev)
            this.testData();
    },
    methods: {
        testData() {
            this.comment = "Komentarz";
            this.email = 'mcl@ropla.eu';
            this.firstname = 'Michał';
            this.lastname = 'Listos';
            this.phone = '507625507';
            this.amount = 25;
            this.queryavailamount = true;
            this.querypriceamount = false;
        },

        sendQuery() {
            var queryData = {
                username: this.store.username,
                itemcode: this.product.itemcode,
                partno: this.product.partno,
                comment: this.comment,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
                phone: this.phone,
                amount: this.amount,
                queryavailamount: this.queryavailamount,
                querypriceamount: this.querypriceamount,
                unit: this.product.unit
            };
            this.$axios.post(this.store.dev ? 'http://localhost/_prodquery' : '/_prodquery', queryData);
            closeModal();
        },
        removeNonDigits() {
            if (isNaN(this.amount))
                this.amount = isNaN(this.amount) ? this.moq : Number(this.amount);
        },
        checkAmount() {
            if (isNaN(this.amount)) {
                //this.amount = this.product.moq;
                this.error = this.$t('err.nan');
                return;
            }
            if (!isNaN(this.product.moq))
                if (this.amount < this.product.moq) {
                    this.error = this.$t('err.moq', {moq: this.product.moq});
                    //this.amount = this.product.moq;
                    return;
                }
            if (!isNaN(this.product.multi))
                if (Number(this.amount) % Number(this.product.multi)) {
                    this.error = this.$t('err.multi', {multi: this.product.multi});
                    //this.amount = Math.ceil(this.amount / this.product.multi) * this.product.multi;
                    return;
                }
            this.error = '';
        },
        getAvailability(itemcode) {
            var that = this;
            this.$root.coverstages["avail"] = "loading.avail";
            this.$axios
                .get("/_pd/", {
                    params: {
                        ics: itemcode,
                        ver: this.store.version,
                    },
                })
                .then((response) => {
                    delete this.$root.coverstages["avail"];
                    console.log(
                        "avail received",
                        response.data.avail[itemcode]
                    );
                    that.avail = response.data.avail[itemcode];
                });
        }
    },
    computed: {
        hasSomeUserData() {
            return this.firstname.length > 0 || this.lastname.length > 0 || this.email.length > 0 || this.phone.length > 0;
        },
        disableSubmit() {
            var disable = !this.amount || this.error || !(this.queryavailamount || this.querypriceamount);
            if (!disable)
                disable = !this.firstname.length || !this.lastname.length || !this.email.length || !this.phone.length;
            if (!disable)
                disable = !this.firstname || !this.lastname || !this.email || !this.phone;
            return disable;
        },
    }
}
</script>

<style scoped>
table {
    width: 100%;
}

.dark #mngdialog {
    box-shadow: 10px 10px 5px #111;
    background: #222;
}

#mngdialog {
    overflow-y: auto;
}

@media only screen and (max-width: 1599px) {
    #mngdialog {
        position: relative;
        max-height: 80vh;

        box-shadow: 10px 10px 5px #bbb;
        background: #eee;
    }
}

@media only screen and (min-width: 1600px) {
    #mngdialog {
        position: relative;
        max-height: 80vh;

        width: 60vw;
        max-width: 60vw;

        box-shadow: 10px 10px 5px #bbb;
        background: #eee;
        padding: 0.5em;
    }
}

h1 {
    text-align: center;
}

.upper {
    text-transform: uppercase;
}

.fbottom {
    border-bottom: 1px solid #000;
}

.ftop {
    border-top: 1px solid #000;
}

.fleft {
    border-left: 1px solid #000;
}

.fright {
    border-right: 1px solid #000;
}

.title {
    font-size: 2em;
    height: 3em;
    text-align: center;
}

.high {
    height: 2em;
}

#main > tr > td,
#main > tr > th {
    min-width: 25%;
}

table {
    border-collapse: collapse;
}

textarea {
    width: 100%;
}

.quantity {
    max-width: fit-content;
}

#quantity {
    max-width: 16em;
    text-align: right;
}

.toright {
    text-align: right;
}

.header {
    background: var(--highlight1-bg-color);
    color: var(--highlight1-fg-color);
}

.mfr {
    font-weight: bold;
    font-size: 1.2em;
}

.dark .header {
    background: var(--dark-highlight1-bg-color);
    color: var(--dark-highlight1-fg-color);
}

.partno {
    background: var(--highlight1-bg-color);
    color: var(--highlight1-fg-color);
}

.dark .partno {
    background: var(--dark-highlight1-bg-color);
    color: var(--dark-highlight1-fg-color);
}

.dark #mngdialog {
    color: #fff;
}

.onsite {
    font-weight: bold;
    font-size: 1.2em;
}

.userdata label {
    text-align: right;
    display: inline-block;
}

.userdata {
    white-space: nowrap;
}

.userdata td:first-child {
    text-align: right;
}

.nowrap {
    white-space: nowrap;
}

.dark button,
button {
    width: 100%;
    background: #e0ffff;
    color: #000;
}

button:disabled {
    background: #aaf;
    color: #666;
}

.spacingtop {
    padding-top: 2em;
}

.requiredfields {
    font-weight: bold;
    text-align: left !important;
}

label {
    display: inline-block;
    width: 100%;
}

.errlabel {
    color: #bb0000;
}


.dark .errlabel {
    color: #ffaaaa;
}

.error {
    color: red;
    font-size: 0.8em;
    font-weight: bold;
}

em {
    font-weight: bold;
}
</style>