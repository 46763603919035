<template>
  <div v-if="subcats.length">
    <input type="radio" id="catlist" value="list" v-model="grouplist" />
    <label for="catlist">{{ $t("categories.list") }}</label>

    <input type="radio" id="catimgs" value="img" v-model="grouplist" />
    <label for="catimgs">{{ $t("categories.images") }}</label>
    <ul>
      <li
        v-for="(item, idx) in subcats"
        :class="{ img: grouplist == 'img' }"
        :key="idx"
      >
        <a
          :href="'/s/' + item.path"
          :title="$t('product.groupno') + ': ' + item.group_code"
        >
          <img
            v-if="grouplist == 'img' && item.imgcode"
            :src="'/_gimg/' + item.imgcode"
          />
          <img v-else-if="grouplist == 'img'" src="/img/grupa.png" />{{
            item.name
          }}</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { inject, nextTick, toRaw } from "vue";
import { useStore } from "@/stores/page";
export default {
  name: "CategoryTreeLeafs",
  emits: ["opencat"],
  setup() {
    const mitt = inject("mitt");
    const store = useStore();
    return { mitt, store };
  },

  data: function () {
    return {
      isOpen: false,
      selected: false,
      subcats: [],
      grouplist: this.store.grouplist,
      // localitem: {}
    };
  },

  methods: {
    traverse(parts, branch) {

      if (branch.children && branch.children.length > 0) {
        for (var i = 0; i < branch.children.length; i++) {
          var subpath = branch.children[i].path.split("/");

          //parts[0] == ""
          if (parts[0] == subpath[subpath.length - 1]) {
            if (branch.children[i].children) {
              this.subcats = branch.children[i].children;
              this.traverse(parts.splice(1), branch.children[i]);
            } else {
              this.subcats = [];
              return;
            }
            break;
          }
        }
      }

      //      var part = parts[0];
    },
  },
  mounted() {
    this.mitt.on("categories-propagated", () => {
      nextTick(() => {
        if (this.$route.path.replace(/\/s\//, "") == "")
          this.subcats = this.store.tree.children;
        else
          this.traverse(
            this.$route.path.replace(/\/s\//, "").split("/"),
            this.store.tree
          );
      });
    });
  },
  watch: {
    grouplist(to) {
      //console.log("grouplist changed", to);
      this.store.grouplist = to;
    },
    path() {
      /*
      nextTick(() => {
        this.traverse(
          this.$route.path.replace(/\/s/, "").split("/"),
          this.store.tree
        );
      });*/
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
}
img {
  border: 1px solid #000;
  width: 134px;
  height: 128px;
}
li.img {
  display: inline-block;
}
li:not(.img)::before {
  vertical-align: top;
  content: " ●  ";
  color: #0066cc;
}
li:not(.img) {
  display: inline;
}
li {
  vertical-align: top;
  list-style-type: none;
  margin-right: 0.5em;
}
li.img {
  width: 128px;
  margin-right: 1em;
}

a {
  display: inline;
  text-decoration: none;
  color: #0066cc;
}
a:hover {
  text-decoration: underline;
}
</style>
