<template>
    <widget-container-modal/>
    <ul id="usermenu" ref="usermenu" v-show="showUserMenu" v-click-away="userMenuClose">
        <li class="user">{{ store.username }}</li>
        <li><a href="/user/lastlog">{{ $t('user.lastlogshort') }}</a></li>
        <li><a @click="logout()">{{ $t('user.logout') }}</a></li>
    </ul>
    <div id="page" :class="{ first: $route.path == '/', mobile: $isMobile() }">

        <div id="header">
            <a id="logo" href="/" v-on:click.ctrl.prevent="$router.push('/debug')">
                <img src="/img/ropla.svg" alt="ROPLA"/>
            </a>
            <div id="logofill"></div>
            <div id="topbar">

                <SearchPanel/>
                <langBar/>
            </div>

            <div id="pagesbar" ref="pagesbar">

                <pagesBar/>
                <div id="buttonbar">

                    <basket-button/>
                    <span id="login" ref="login" @click.stop="userMenu()">
                        <div class="user" :title="store.username" v-if="store.username">
                         <img v-if="store.userpic" class="avatar" :alt="store.username" :src="store.userpic"/>

                          <SolarUserLineDuotone v-else/>
                        </div>
                        <SolarUserBroken v-else/>
                    </span>
                    <dark-mode-button @set="setDarkMode"/>
                </div>
                <div id="verinfo">
                    <span id="ver">{{ store.version }}</span>
                    <span id="dblastupdate">db:{{ dblastupdate }}</span>
                </div>
            </div>
            <div id="banner" v-if="$route.path == '/'">
                <img src="/img/baner_flaga.png" alt="flaga"/>
                <div :class="{ cover: coverstagevals.length }" id="bannercover"></div>
            </div>
        </div>
        <category-tree-new :menutop="menutop" :menuleft="menuleft"/>
        <company-menu/>
        <distributions-menu/>
        <div id="content">
            <router-view/>
        </div>
    </div>
    dev: {{store.dev}}/srr:{{store.srr}}/route:{{$route.name}}
    <div :class="{ cover: coverstagevals.length }" v-if="coverstagevals.length">
        <p class="v-spinner-text">{{ coverstagevals.join(" | ") }}</p>
        <pulse-loader :color="'#E30016'" :size="'32px'"></pulse-loader>
        <div id="logbuffer">
            <p v-for="(log, id) in $logbuffer" :key="id">
            <pre :class="{ error: log[0] == 'error' }"> {{ log[1] }}</pre>
            </p>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {getCurrentInstance, toRef, toRaw, inject} from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import i18n from "@/lang";
import langBar from "@/components/langBar.vue";
import {useStore} from "@/stores/page";
import {useRoute, useRouter} from "vue-router";
import pagesBar from "@/components/pagesBar.vue";
import darkModeButton from "@/components/darkModeButton.vue";
import NewsPage from "@/components/News/NewsPage.vue";
import CategoryTreeNew from "@/components/CategoryTree/CategoryTreeNew.vue";
import {container, openModal, promptModal, closeModal} from "jenesius-vue-modal";
import LoginWin from "@/components/Login/LoginWin.vue";
import SearchPanel from "@/components/SearchPanel.vue";

import {useOneTap} from "vue3-google-signin";
import BasketButton from "@/components/Basket/BasketButton.vue";
import SolarUserBroken from "@/components/Icons/SolarUserBroken.vue";
import SolarUserLineDuotone from "@/components/Icons/SolarUserLineDuotone.vue";
import DistributionsMenu from "@/components/Distributions/DistributionsMenu.vue";
import CompanyMenu from "@/components/Company/CompanyMenu.vue";
export default {
    name: "ROPLA",
    components: {
        CategoryTreeNew,
        langBar,
        pagesBar,
        darkModeButton, BasketButton,
        NewsPage,
        PulseLoader,
        WidgetContainerModal: container, LoginWin,
        SearchPanel, SolarUserLineDuotone, SolarUserBroken, DistributionsMenu, CompanyMenu
    },
    data() {
        return {
            height: 0,
            categoryTreeVisible: false,
            coverstages: {},
            tree: {},
            dblastupdate: '?',
            menutop: 0,
            menuleft: 0,
            showUserMenu: false,
        };
    },
    setup() {

        const route = useRoute();
        const router = useRouter();
        const app = getCurrentInstance();
        const langs = Object.keys(app.appContext.config.globalProperties.$langs);
        const store = useStore();
        if (store.dev) {
            document.body.classList.add('dev')
        }
        const $i18n = app.appContext.config.globalProperties.$i18n;
        const mitt = inject("mitt");
        mitt.on('goto', (where) => {
            this.$devlog('going to', where)
            router.push(where);
        })
        mitt.on('closemodal', ()=>{closeModal()});

        //console.log(i18n.locale,$i18n.locale)
        if ($i18n.locale) {
            //var langs = Object.keys(getCurrentInstance().appContext.config.globalProperties.langs)
            var found = false;
            /* is there a l query param? */
            if (route.query && route.query.l && route.query.l.length) {
                //console.log('queryl pre', route.query.l, langs)
                for (var i = 0; i < langs.length; i++) {
                    //console.log('queryl', route.query.l, langs, i)
                    if (langs[i] == route.query.l) {
                        found = true;
                        $i18n.locale = langs[i];
                        store.lang = langs[i];
                        //console.log('query', i)
                        break;
                    }
                }
            }
            /* scan browser preffered languages */
            var browserlangs = navigator.languages;
            //console.log('before',found,$i18n.locale)
            // check if q param is set and in browser lang list
            if (
                !(
                    route.query &&
                    route.query.l &&
                    browserlangs.find((l) => l == route.query.l)
                )
            )
                for (i = 0; i < browserlangs.length; i++) {
                    //console.log('browserlang',browserlangs[i])
                    //console.log('rvue',langs,browserlangs[i])
                    found = langs.find((lang) => lang == browserlangs[i]);
                    if (found) {
                        //console.log('found', browserlangs[i], browserlangs)
                        i18n.global.locale.value = browserlangs[i];
                        //console.log('found at position',i,browserlangs[i])
                        /* if we prefer to avoid ?l=pl */
                        if (
                            browserlangs[i] !=
                            getCurrentInstance().appContext.config.globalProperties
                                .$langPrimary
                        ) {
                            var newquery = {...route.query};
                            newquery.l = browserlangs[i];

                            try {
                                //console.log('ropla.vue')
                                getCurrentInstance().appContext.config.globalProperties.$devlog(
                                    "route",
                                    "ROPLA.vue lang",
                                    newquery,
                                    getCurrentInstance().appContext.config.globalProperties
                                        .$langPrimary
                                );
                                router.replace({
                                    query: newquery,
                                });
                            } catch {
                                router.push({query: newquery});
                                getCurrentInstance().appContext.config.globalProperties.$devlog(
                                    "route",
                                    "ropla.vue catch"
                                );
                            }
                        }
                        break;
                    }
                    /*
                    if (langs[i] == i18n.global.locale.value) {
                      found = true
                      break
                    }*/
                }
            /* do we have the already set translation? */
            //console.log('search?', app.appContext.config.globalProperties.$langs,found)
            for (
                i = 0;
                i < app.appContext.config.globalProperties.$langs.length;
                i++
            ) {
                if (app.appContext.config.globalProperties.langs[i] == $i18n.locale) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                //console.log('not found',app.appContext.config.globalProperties.$langPrimary)
                i18n.global.locale.value =
                    app.appContext.config.globalProperties.$langPrimary;
            }
        }
        const state = useStore();
        // eslint-disable-next-line
        //const { b_lang, lang, b_page, page } = storeToRefs(state);
        if (store.dark) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }

        //watch(_darkpreferred, function (darkpreferred) {console.log('statewatch', darkpreferred)})
        return {state, dark: toRef(store.dark), store, mitt};
    },
    async mounted() {
        var sessid=this.$cookies.get('ropla');
        var pat=String(this.$route.path);
        const re=/^\/*(user|int)(\/.*|$)/;

        if (sessid)
         this.$cookies.set('ropla', sessid);
        else if (!this.store.dev) {
            this.store.userpic=null;
            this.store.username=null;
            if (this.$route.path.match(re)) {
                var ret=await this.openLoginModal();
                console.log('login ret', ret);
                if (ret == null)
                 this.$router.push('/');
            }
            console.log('basket cleared', this.store.dev, import.meta.env !== undefined, import.meta.env);

        }
/*
        if (this.store.dev)
            this.openLoginModal();

 */
        if (this.$isMobile())
            document.body.classList.add('mobile');
        var box = [];
        try {
            box = this.$refs.pagesbar.getBoundingClientRect();
            this.menutop = box.top + box.height;
            this.menuleft = box.left;
        } catch (e) {
            console.log(e)
        }
        this.mitt.on('login', () => {
            this.$forceUpdate();
        });
        this.$axios
            .get(
                "/_dblastupdate")
            .then((response) => {
                this.dblastupdate = response.data.time;
            });
        this.$axios
            .get(
                "/_whoami",)
            .then((response) => {
                if (response.data.user) {
                    if (response.data.userpic)
                        this.store.userpic = response.data.userpic;
                    this.store.username = response.data.user;
                    this.store.sessionid = response.data.sessionid;
                    this.mitt.emit("login");
                } else {
                    this.store.user = '';
                    this.store.userpic = '';
                }
            });
        this.mitt.on("chlang", (lang) => {
            if (this.$route.name != 'stock') { //in stock done in categorytreenew.vue
                var newquery = {...this.$route.query};
                if (lang == "pl") delete newquery.l;
                else newquery.l = lang;
                //console.log('recv chlang', this.item.lang[lang])
                this.$router.push({
                    query: newquery,
                });
            }
            this.$i18n.locale = lang;
        });
        this.mitt.on("showlogin", (props) => {
            console.log('opening', props);
            this.openLoginModal(props);
        })
    },

    computed: {
        logbuf() {
            var myar = [];
            for (var i = 0; i < this.$logbuffer.length; i++)
                myar.push(this.$logbuffer[i]);
            console.log("in ROPLA.vue", toRaw(this.$logbuffer));
            return myar.join("\n");
        },
        coverstagevals() {
            var vals = [];
            //console.log(this.coverstages);
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(this.coverstages)) {
                vals.push(this.$t(value));
            }
            return vals;
        },
        locale() {
            return navigator.language;
        },
        locales() {
            return navigator.languages;
        },
        swidth() {
            return window.screen.width;
        },
        sheight() {
            return window.screen.height;
        },
    },
    methods: {
        logout() {
            this.$root.coverstages["logout"] = "loading.logout";
            this.$axios.get("/_user/logout").then(() => {
                delete this.$root.coverstages["logout"];
                this.store.username = null;
                this.store.userpic = null;
            });
        },
        userMenuClose() {
            if (this.showUserMenu)
                this.showUserMenu = false;
        },
        userMenu() {
            if (!this.store.username) {
                this.openLoginModal();
                return;
            }
            if (!this.showUserMenuw)
                this.showUserMenu = true;
            var box = this.$refs.login.getBoundingClientRect();
            this.$refs.usermenu.style.left = box.x + 'px';
            this.$refs.usermenu.style.top = (box.top + box.height) + 'px'


        },
        menu() {
            return menu
        },
        setDarkMode(state) {
            if (state) {
                document.body.classList.add("dark");
            } else {
                document.body.classList.remove("dark");
            }
            //console.log("set dark mode", state, document.body.classList);
        },
        async openLoginModal(props) {
            const code = await promptModal(LoginWin, props);
            console.log('login return', code, code==null);
            return code;
        },
    },
    watch: {
        coverstages() {
            console.log("cover changed");
        },
        $route(to, from) {
            console.log('Route changed from ' + from.path + ' to ' + to.path);
            if (to.query && to.query.l && to.query.l.length)
                this.$i18n.locale = to.query.l;
            else {
                this.$i18n.locale = navigator.language.split("-")[0];
            }
        },
        dark(to) {
            if (to) {
                document.body.classList.add("dark");
            } else {
                document.body.classList.remove("dark");
            }
            //console.log("darkpreferred", to, document.body.classList);
        },
    },
};
</script>
<style>
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    background: #f9f9f9;
    max-width: 100vw;
}

body.dark {
    background: #242424;
}

#page {
    font-family: Avenir, Helvetica, Arial, sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    background: #ffffff;
}

.logofill {
    height: calc(2em - 2px); /* wtf? */
}

#logofill {
    position: absolute;
    top: 0px;
    width: 97px;

    z-index: 4;
    background: #e30016;
}


#logo {
    display: block;
    z-index: 7;
    margin-top: 0;
    top: 0px;
    position: fixed;
    align-self: flex-start;
    border-bottom: 9px solid #e30016;
    background: #e30016;
}

.first #logo {
    top: 0px;
}

#topbar {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    position: fixed;
    top: 0;
}

@media (min-width: 1280px) {
    #topbar {
        width: 90vw;
        padding-left: 97px;
    }
}

/* #page {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}*/

#pagesbar {
    background: #687680;
    position: fixed;
    top: 2em;
    width: 100%;
    /* z-index: 3; */
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    vertical-align: top;
}

.dark #pagesbar {
    background: #434343;
}

.first #banner {
    top: 2em;
    height: 157px;
}

#banner {
    top: 26px; /* wtf? */
    position: relative;

    margin: 0;
}

#header {
    background: #f8f8f8;
    text-align: left;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 6;
    padding: 0;
    min-height: 32px;
    display: flex;
    align-items: stretch;
    width: calc(100% - 10vw);

}

.dark #header {
    background: #4f4f4f;
}

#header img {
    margin: 0;
    padding: 0;
    padding-top: 0px;
}

#content {
    width: 100%;
    overflow: hidden;
    text-align: left;
    margin-top: 4em;
}


#page.first #content {
    margin-top: calc(97px + 2em + 4em);
}

#banner.cover {
    backdrop-filter: blur(5px);
}

.cover {
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

#bannercover.cover {
    backdrop-filter: blur(5px);
    height: 157px;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 3;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

#banner {
    margin: 0;
    padding: 0;
}

#scroller {
    width: 100%;
    position: sticky;
    top: 228px;
}
#scroller thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;
}

@media (max-width: 1279px) {
    #page {
        flex-wrap: wrap;
    }

    body {
        padding-top: 4em;
    }
}

@media (min-width: 1280px) {
    .first #logofill {
        height: calc(157px + 2em - 3px); /* wtf? */
    }

    /* #scroller {
        width: calc(100% - 380px);
     }*/
}

.dark #page {
    background: #212121;
    color: #ddd;
}

@media (min-width: 1600px) {
    body {
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .first #page {
        margin-top: 157px;
    }

    #page {
        width: 100%;
    }

    #header {
        height: 29px; /* wtf 3px? */
    }

    .first #header {
        height: calc(157px + 29px); /* wtf 3px? */
    }

    #pagesbar {
        width: calc(100% - 10vw);
        left: 5vw;

    }
}

@media (max-width: 1600px) {
    #logofill {
        height: fit-content;

    }

    #banner {
        display: none;
    }

    #pagesbar {
        /* wrong on mobile
        width: calc(100% - 97px); */
    }

    #header {
        width: 100%;
    }

    body {
        margin: 0;
    }
}

#pagesbar {
    border-left: 97px solid #e30016 !important;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
     grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)); */
    z-index: 4;
}

#buttonbar {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: right;
    z-index: 99;
}
#buttonbar button {
    width: 5em;
}
#buttonbar {
    white-space: nowrap;
}
html {
    overflow-x: hidden;
}

a {
    color: #000;
}

.dark a {
    color: #fff;
}

.v-spinner {
    position: fixed;
    top: 50vh;
    left: calc(50vw - 10em);
}

.v-spinner-text {
    position: fixed;
    display: inline-block;
    top: calc(50vh - 4em);
    left: calc(50vw - 10em);
    z-index: 99;
}

.modal-container {
    z-index: 100;
    backdrop-filter: blur(5px);
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}


#login {
    background: transparent;
}

#verinfo {
    z-index: 4;
    position: absolute;
    right: 10vw;
}

#ver {
    color: #fff;
}

#dblastupdate {
    padding-left: 1em;
    color: #fff;
}

@media only screen and (max-width: 1279px) {
    #ver, #dblastupdate {
        display: none;
    }
}

#logbuffer {
    max-width: 60vw;
    width: 60vw;
    border: 1px solid rgba(128, 128, 128, 0.5);
    position: fixed;
    top: 60vh;
    left: 20vw;
    height: 20vh;
    margin: 0;
    padding: 0;


    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 100%;
    background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1),
            rgba(255, 255, 255, 0)
    );
}

.dark #logbuffer {
    background-image: linear-gradient(
            180deg,
            rgba(200, 200, 200, 1),
            rgba(0, 0, 0, 0)
    );
}

#logbuffer * {
    margin: 0;
}

#logbuffer .error {
    -webkit-text-fill-color: red;
    -moz-text-fill-color: red;
    text-fill-color: red;
}

.avatar {
    max-width: 24px;
}

#pagesbar {
    max-width: 100vw;

}

@media screen and (max-width: 1279px) {
    #pagesbar {
        flex-wrap: wrap;
    }
}

body {
    max-width: 100vw;
}

@media only screen and (max-width: 1279px) {
    #pagesbar {
        border-left: 21px solid #e30016 !important;
    }

    #logofill {
        width: 21px;
    }

    #topbar {
        padding-left: 21px;
        position: fixed;
        top: 0;
    }

    #logo {

    }

    #logo img {
        position: fixed;
        transform: rotate(270deg);
        top: 32px;


        background: #e30016;
        left: -38px;
        border-bottom: 4px solid #e30016;
    }
}

div.user {
    line-height: 2em;

}

div.user > * {
    vertical-align: middle;
}

#login:hover {

}

#usermenu {
    position: fixed;
    top: 0;
    right: 10vw;
    z-index: 9;
    list-style-type: none;
    background: #fff;
    padding: 0.5em;
    margin: 0;
    border: 1px solid #000;
    cursor: pointer;
}

.dark #usermenu {
    background: #353535;
    border: 1px solid #666;
}

#usermenu li:first-child {
    border-bottom: 1px solid #000;
    font-weight: bold;
    color: #aaa;
}

.dark #usermenu li:first-child {
    border-bottom: 1px solid #666;
}

#usermenu li a {
    text-decoration: none;
}

#usermenu li:not(.user):hover {
    background: #ddd;
}

#login svg {
    width: 22px;
    height: 22px;
}
</style>
