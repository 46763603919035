<template>
    <p>{{ comment }}</p>/{{ store.lang }}/

    <div v-for="(data,company) in dist" v-if="store.lang">
        <reditor v-if="data[store.lang]" :id="data[store.lang].id" :body="data[store.lang].html"
                 :path="company"
                 :title="data[store.lang].title" @pgsave="save"/>
    </div>

</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, ref, toRaw} from "vue";
import CategoryTreeItem from "@/components/CategoryTree/CategoryTreeItem.vue";
import reditor from "@/components/REditor.vue";
import {useRoute} from "vue-router";

export default {
    name: "DistributionsPagesEdit",
    components: {
        reditor
    },
    props: {
        page: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            dist: {}
        }
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        const route = useRoute();
        var page = ref(1);
        var pp = ref(25);
        if (route.query.p) page = ref(parseInt(route.query.p));
        if (route.query.pp) pp = ref(parseInt(route.query.pp));
        return {store, mitt, page, pp, route};
    },

    mounted() {
        this.getDist();
        this.getComment();
    },
    methods: {
        getLocalized() {
            var ret = {};
            var that = this;
            if (this.dist)
                Object.keys(this.dist).forEach(function (company, idx) {
                    console.log(company, that.dist[company]);
                    ret[company] = that.dist[company][that.store.lang];
                });
            return ret;
            return this.dist.map(a => a[this.store.lang]);
        },
        getDist() {
            this.$axios
                .get("/_pg/distributions?c=1", {})
                .then((response) => {
                    console.log(response);
                    this.dist = response.data;
                });
        },
        save(params) {
            this.$axios.post(this.store.dev ? 'https://test.ropla.eu/_pgsave' : '/_pgsave', {
                username: this.store.username,
                id: params.id,
                title: params.title,
                body: params.body,
                sort: params.sort,
                path: params.path,
                lang: params.lang
            }).then((resp) => {
            });
            console.log('saving', params);
        },
        getComment() {
            this.$axios
                .get("/_pgcomment/distributions", {})
                .then((response) => {

                    this.comment = response.data;
                });
        },
    }
}
</script>

<style scoped>
</style>
