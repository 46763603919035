<script>
export default {
	name: 'FontistoShoppingBasketAdd',
};
</script>
<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="31" height="24" viewBox="0 0 31 24">
		<path fill="currentColor" d="M19.478 22.099v-.091H13.75v.091l.001.045a1.9 1.9 0 1 1-3.802 0l.001-.047v-.089h-.653a1.22 1.22 0 0 1-1.192-.96l-.001-.008L4.287 2.967l-3.261-.543A1.221 1.221 0 0 1 1.43.017L1.424.016l4.081.68c.497.085.887.461.991.943l.001.008l.692 3.264l11.905.992a6.51 6.51 0 0 0-.112 1.186v.004c0 .402.035.796.103 1.179l-.006-.04a1.397 1.397 0 0 0-.951 1.601l-.001-.009l.719 5.19c.044.333.196.625.418.845c.202.201.479.327.786.33h.001c.068 0 .135-.007.199-.019l-.007.001a1.389 1.389 0 0 0 1.013-1.605l.001.009l-.392-2.822a6.657 6.657 0 0 0 4.808 2.044c.043 0 .086 0 .128-.006l.093-.005l-.669 3.902a.407.407 0 0 1-.4.339H9.96l.327 1.547h14.157a1.221 1.221 0 0 1 .002 2.44H23.28v.091a1.9 1.9 0 1 1-3.802 0zm1.358 0a.539.539 0 1 0 1.07-.094v.003h-1.06a.514.514 0 0 0-.008.091zm-9.528 0a.539.539 0 1 0 1.07-.094v.003h-1.061a.494.494 0 0 0-.007.089v.002zm3.867-13.875a1.388 1.388 0 0 0-1.013 1.605l-.001-.009l.719 5.19c.044.333.196.625.418.845c.202.201.479.327.786.33h.001c.068 0 .135-.007.199-.019l-.007.001a1.389 1.389 0 0 0 1.013-1.605l.001.009l-.719-5.19a1.452 1.452 0 0 0-.418-.845a1.132 1.132 0 0 0-.786-.331h-.001c-.069 0-.136.007-.201.02l.007-.001zm-3.966 0a1.387 1.387 0 0 0-1.014 1.609l-.001-.009l.719 5.19c.044.333.196.625.418.845c.202.201.479.327.786.33h.001c.068 0 .135-.007.199-.019l-.007.001a1.388 1.388 0 0 0 1.014-1.605l.001.009l-.72-5.193a1.452 1.452 0 0 0-.418-.845a1.128 1.128 0 0 0-.786-.331H11.4c-.068 0-.134.007-.198.019zm8.789-1.128a5.678 5.678 0 1 1 11.36.002a5.678 5.678 0 0 1-11.362-.003zm1.627 0a4.051 4.051 0 1 0 8.106 0a4.051 4.051 0 0 0-8.108-.001zm3.24 1.621v-.808h-.808a.814.814 0 0 1 0-1.628h.808v-.808a.814.814 0 0 1 1.628 0v.808h.808a.814.814 0 0 1 0 1.628h-.808v.807a.814.814 0 0 1-1.628 0z" />
	</svg>
</template>
