<template>
    <div id="search" v-click-away="closeMenu">
        <ul class="categories">
            <li v-if="formatselected()" class="title" @click="showcategories = true">{{ formatselected() }}</li>
            <li v-else class="title" @click="showcategories = true">{{ $t('search.category') }}</li>
            <li v-for="item in store.treep"
                v-show="showcategories && item.depth &&  isparentprequal(selected.path, item.path)"
                :key="item.path"
                :class="{depth1: item.depth==1, depth2: item.depth==2, depth3: item.depth==3, selected: selected.path==item.path, fin: item.fin}"
                @click="select(item)">
                <div class="ami"></div>
                {{ item.name }}
            </li>
        </ul>
        <form @submit.prevent="sendsearch">
            <input v-model="search" v-debounce:500ms="dosearch" :placeholder="$t('search.search')"
                   type="text" @focus="$event.target.select();showcategories=false"/>
            <button type="submit">{{ $isMobile() ? $t("search.searchmobile") : $t("search.search") }}
            </button>
        </form>
        <div v-show="menuopen" id="searchresults">
            <div v-for="item in results" :key="item.path"><a :href="'/s/'+item.path">{{ item.name }}
                <span class="count">({{ item.count }})</span></a></div>
        </div>
    </div>

</template>

<script>
import {inject, toRaw} from "vue";
import {minimatch} from 'minimatch'
import {useStore} from "@/stores/page";
import {useRoute} from "vue-router";
import CategoryTreeSearch from "@/components/CategoryTree/CategoryTreeSearch.vue";

export default {
    name: "SearchPanel",
    setup() {
        const route = useRoute();
        const store = useStore();
        const mitt = inject("mitt");
        var search = '';
        if (route.query.s)
            search = route.query.s;
        return {store, mitt, search};
    },
    data() {
        return {
            results: [],
            menuopen: false,
            selected: {name: 'Szukaj w kategorii', path: '/'},
            showcategories: false
        }
    },
    watch: {
        search() {
            this.menuopen = true;
        }
    },
    mounted() {
        this.mitt.on("categories-propagated", () => {
            //console.log(toRaw(this.store.tree));
        });
    },
    methods: {
        formatselected() {

            var glued = ''
            if (!this.store.treep[this.selected.path])
                return;
            var elements = this.toelements(this.selected.path);
            if (this.$isMobile())
                return elements[elements.length - 1].name;
            for (var i = 0; i < elements.length; i++) {
                var el = {...elements[i]};
                glued = glued + '▹' + el.name;
            }
            return glued.replace('▹', '');
        },
        toelements(path) {
            var elements = [];
            var pathparts = path.split('/');
            var paths = pathparts.slice(0, pathparts.length);
            for (var i = 1; i < paths.length + 1; i++) {
                elements.push(this.store.treep[paths.slice(0, i).join('/')]);
            }
            return elements;
        },
        isparentprequal(parent, child) {
            var childparts = child.split('/');
            var parentparts = parent.split('/');
            var match = true;
            for (var i = 0; i < childparts.length - 1; i++)
                if (parentparts[i] != childparts[i]) {
                    match = false;
                    break;
                }
            return match;
        },
        select(item) {
            if (item.path == this.selected.path)
                this.showcategories = false;

            if (item.fin)
                this.showcategories = false;
            this.selected = item;

        },
        closeMenu() {
            this.showcategories = false;
            this.menuopen = false;
        },
        dosearch() {
            this.results = [];
            //var search = (this.search.indexOf('*') == -1) ? '*' + this.search + '*' : this.search;
            var search = '*' + this.search + '*';

            for (const [path, o] of Object.entries(this.store.treep)) {
                if (minimatch(o.name, search, {
                    nocase: true,
                    noglobstar: true,
                    dot: true,
                    noext: true,
                    nocomment: true,
                    nonegate: true,
                    partial: true
                })) {
                    //console.log(path,name)
                    this.results.push({path: o.path, name: o.name, count: o.count})
                }
            }
            this.menuopen = true;
        },
        sendsearch() {
            this.closeMenu();
            var path = this.selected.path.length && this.selected.path != '/' ? '/s/' + this.selected.path : '/q';
            this.$router.push({path: path, query: {q: this.search}});
            console.log('no to szuka', path, this.selected.path, this.search)
        }
    }
}
</script>

<style scoped>
#search {
    white-space: nowrap;
}

#search * {
    display: inline-block;
    position: relative;
}

#search .categories {
    display: inline-block;
    width: 20vw;
    list-style-type: none;
    padding: 0;
    margin: 0;
    float: left;
}

#search .categories li:first-child {
    height: calc(1.5em + 9px);
    line-height: calc(1.5em + 9px);
    border: 1px solid #000;
    padding: 0 0.5em 0 0.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    direction: rtl;
}

#search .categories li {
    z-index: 5;
    display: block;
    background: #fff;
    position: relative;
}

.dark #search .categories li {
    background: #353535;
}

#search .categories li {
    cursor: pointer;
    user-select: none;
}

#search .acategories li:not(.fin):not(.title):not(.selected):before {
    content: "⊞"
}

#search .acategories li.open:before {
    content: "⊟"
}

#search .acategories li.fin:before {
    content: "⊡"
}

#search .categories li:not(:first-child):hover {
    background: #eee;
}

.dark #search .categories li:not(:first-child):hover {
    background: #555;
}

#searchresults {
    position: absolute;
    left: 0;
    top: 2em;
    display: block;
    margin: 0;
    padding: 0;
    z-index: 5;
}

#searchresults div:hover {
    background: #ccc;
}

.dark #searchresults div:hover {
    background: #555;
}

#searchresults {
    background: #fff;
    border: 1px solid #000
}

#searchresults div {
    display: block;
    width: 20em;
    text-align: left;;
}

#searchresults a {
    text-decoration: none;
    padding-left: 0.5em;
}


#searchresults div a {
    position: relative;
    width: 100%;
}

#searchresults .count {
    display: inline-block;
    float: right;
}

#search {
    display: inline-block;

}


#search button {
    height: calc(1.5em + 18px);;
    border-top: 1px solid #000;
}

#search input {
    max-width: 20em;
    height: calc(1.5em + 18px);
    border-top: 1px solid #000;
}

.mobile #search input {
    max-width: 16em;
}

.depth2 > * {
    position: absolute;
    margin-left: 1em;
}

.depth3 > * {
    position: absolute;
    margin-left: 2em;
}

.ami {
    position: absolute;
    width: 1em;
    display: inline-block;
}

.selected .ami:before {
    content: "▶";
}

.depth2 .ami {
    left: -1em;
}

.depth3 .ami {
    left: -2em;
}


@media (min-width: 1280px) {
    #search {
        left: 20vw;
        
    }


}

@media (max-width: 1279px) {
    .categories {
        /* why !important needed? */
        display: none !important;
    }

    #dosearch {
        display: none;
    }

    #searchresults {
        position: absolute;
        left: auto;
        right: 0;
        line-height: 2em;
    }
}


</style>