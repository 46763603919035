<template>
  <h1>
    <PixelarticonsDebug/>
    {{ $t("pages.debug") }}
  </h1>
  <hr/>

  <h2>{{ $t("debug.pidsettings") }}</h2>
  <div v-for="(idx, col) in store.pidsettings" :key="idx">
    <button @click="delete store.pidsettings[col]">
      {{ $t("debug.remove") }}
    </button>
    <pre>{{ col }}={{ JSON.stringify(store.pidsettings[col]) }}</pre>
  </div>
  <hr/>
  <h2>{{ $t("debug.reqlog") }}</h2>
  <a v-for="(log, id) in $logbuffer" :key="id" :href="log[2]" target="_blank">
    <pre :class="{ error: log[0] == 'error' }">{{ log[1] }}</pre>
  </a>
</template>

<script>
import {useStore} from "@/stores/page";
import {useFiltersStore} from "@/stores/filters";
import PixelarticonsDebug from "@/components/Icons/PixelarticonsDebug.vue";

export default {
  name: "DebugInfo",
  components: {PixelarticonsDebug},
  setup() {
    const store = useStore();
    const storefilters = useFiltersStore();
    return {
      store,
      storefilters,
    };
  },
};
</script>

<style scoped>
h1 svg {
  width: 36px;
  height: 36px;
}

div {
  white-space: nowrap;
}

pre {
  margin: 0;
  white-space: pre-wrap;
  padding-left: 1.5em;
  text-indent: -1.5em;
  display: inline-block;
}

a {
  text-decoration: none;
}

a:hover pre {
  text-decoration: underline;
}

button {
  display: inline-block;
  border: 1px solid red;
  margin-right: 1em;
}
</style>
