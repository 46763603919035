import {defineStore} from "pinia";

const myStorage = {
    setItem(key, state) {
        //console.log("setting item", key);
        localStorage.setItem(key, state);
    },
    getItem(key) {
        //console.log("getting item", key);
        return localStorage.getItem(key);
    },
};
import pjson from "../../package.json";

export const useStore = defineStore({
    id: "persistent-store",

    state: () => ({
        dark: window.matchMedia("(prefers-color-scheme: dark)").matches,
        page: null,
        path: null,
        tree: [],
        treep: {},
        searchparams: null,
        lang: "pl",
        grouplist: "img",
        plisthover: null,
        pidsettings: {},
        version: pjson.version,
        dev: (import.meta.env !== undefined && import.meta.env.MODE == 'development'),
        srr: import.meta.env.BASE_URL,
        username: '',
        userpic: '',
        lastfiltersloaded: '',
        basket: {}
    }),

    conserve: {
        enabled: true,
        strategies: [
            {
                storage: myStorage,
                states: ["dark", "grouplist", "pidsettings", "username", "userpic", "basket"],
            }, // Save custom state
        ],
    },
    actions: {},
});
