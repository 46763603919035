<template>
    <div id="pitem">
        <div class="toptables">
            <table class="pitem">
                <tr>
                    <th class="title" colspan="2">{{ itemname }}</th>
                </tr>
                <tr>
                    <th class="availquery" colspan="2">
                        <button @click="productQuery">{{ $t('product.availquery') }}</button>
                    </th>
                </tr>
                <tr>
                    <th>{{ $t("product.roplaindex") }}</th>
                    <td>{{ itemcode }}</td>
                </tr>
                <tr>
                    <th>{{ $t("product.manufacturer") }}</th>
                    <td><img v-if="item.man_code" :src="'/_mimg/' + item.man_code"/></td>
                </tr>
                <tr>
                    <th>
                        {{ $t('product.man_www') }}
                    </th>
                    <td>
                        <a
                                v-if="item.man_www"
                                :href="'//' + item.man_www"
                                class="man_www"
                                target="_blank"
                        >{{ item.man_www }}</a>
                    </td>
                </tr>
                <tr v-if="item.man_code">
                    <th>{{ $t('product.man_other_products') }}</th>
                    <td>
                        <a
                                v-if="item.man_code"
                                :href="'/mfr/' + item.man_code"
                                class="man_others"
                                target="_blank"
                        >{{ item.man_name }}</a>
                        <span v-else>{{ item.man_name }}</span>
                    </td>
                </tr>
                <tr>
                    <th>{{ $t("product.mancode") }}</th>
                    <td>{{ item.partno }}</td>
                </tr>

                <tr>
                    <th class="img" colspan="2">
                        <img
                                v-if="item.imgs && item.imgs.length"
                                :src="'/_pimg/' + item.imgs[0]"
                        />
                    </th>
                </tr>

                <tr>
                    <th>{{ $t("product.orderno") }}</th>
                    <td>{{ item.orderno }}</td>
                </tr>

                <tr v-if="datasheet">
                    <th>{{ $t("product.datasheet") }}</th>
                    <td>
                        <a :href="'/_pdf/' + datasheet" target="_blank"
                        >
                            <TeenyiconsPdfOutline/>
                        </a>
                    </td>
                </tr>
            </table>
            <table class="quantum">
                <tr>
                    <th class="title" colspan="2">{{ $t("product.amount") }}</th>
                </tr>
                <tr>
                    <th>{{ $t("product.moq") }}</th>
                    <td>{{ item.moq }}</td>
                </tr>
                <tr>
                    <th>{{ $t("product.multi") }}</th>
                    <td>{{ item.multi }}</td>
                </tr>
                <tr>
                    <th>{{ $t("product.spq") }}</th>
                    <td>{{ item.spq }}</td>
                </tr>
                <tr></tr>
            </table>
            <table class="avail">
                <tr>
                    <th class="title" colspan="2">{{ $t("product.quantities") }}</th>
                </tr>

                <tr v-for="(quantity, date) in item.avail" :key="date">
                    <th>
                        <span v-if="date.length">{{ date }}</span>
                        <span v-else>{{ $t("product.onstock") }}</span>
                    </th>
                    <td class="quantity">{{ quantity }}</td>
                </tr>
                <tr></tr>
            </table>
        </div>
        <table>
            <tr>
                <th class="title">{{ $t("product.usertext") }}</th>
            </tr>
            <td>
                <pre>{{ usertext }}</pre>
            </td>
        </table>
        <!--
        <span v-if="item.params && item.params[lang]">
          {{ item.params[lang] }}</span
        >-->
        <hr/>
        <!-- {{ item.paths }} -->{{ itemparams }}
        <table v-if="item.params && item.params[lang]" class="itemparams">
            <tr>
                <th class="title" colspan="4">{{ $t("product.techdata") }}</th>
            </tr>
            <tr v-for="(param, idx) in item.params[lang]" :key="idx">
                <th>
                    <label :for="'ck' + param.param_id"
                    ><span v-if="head[lang] && head[lang][param.param_id]"
                           v-html="head[lang][param.param_id].name"></span>
                        <span v-else>ERR {{ lang }}/{{ param.param_id }}</span>
                        <span v-if="head[lang] && head[lang][param.param_id]"
                              v-html="head[lang][param.param_id].symbol"></span>
                        <span v-else>ERR {{ lang }}/{{ param.param_id }}</span></label>
                </th>
                <td class="val">
                    <label :for="'ck' + param.param_id">{{ param.val }}&nbsp;</label>
                </td>
                <td v-if="head[lang] && head[lang][param.param_id]">
                    <label :for="'ck' + param.param_id"
                    >{{ param.val_remarks }}&nbsp;</label
                    >
                </td>
                <td class="checkbox">
                    <label :for="'ck' + param.param_id">
                        <input
                                :id="'ck' + param.param_id"
                                v-model="itemparams"
                                :value="param.val_id"
                                type="checkbox"
                        /></label>
                </td>
            </tr>
            <tr>
                <th class="title" colspan="4">
                    <button class="button" @click="searchParams()">
                        {{ $t("product.searchsimilar") }}
                    </button>
                </th>
            </tr>
        </table>

        <table>
            <tr>
                <th class="title">{{ $t("product.documents") }}</th>
            </tr>
            <tr v-for="(pdf, idx) in item.pdfs" :key="idx">
                <a :href="'/_pdf/' + pdf.code" target="_blank"
                >
                    pdf duda
                    <TeenyiconsPdfOutline/>
                    {{ $t("documents." + pdf.type) }}</a
                >
            </tr>
        </table>

        <table v-if="item.alts">
            <tr>
                <th class="title" colspan="3">{{ $t("product.equivalents") }}</th>
            </tr>
            <tr v-for="(equiv, idx) in equivs" :key="idx">
                <th>
                    <a :href="'/p/' + equiv.itemcode">{{ equiv.itemcode }}</a>
                </th>
                <td>{{ Math.round(equiv.match) }}%</td>
                <td>{{ equiv.itemname }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import {useStore} from "@/stores/page";
import {inject, ref, toRaw} from "vue";
import TeenyiconsPdfOutline from "@/components/Icons/TeenyiconsPdfOutline.vue";
import {openModal} from "jenesius-vue-modal";
import ProductQuery from "@/components/Product/ProductQuery.vue";

export default {
    name: "ProductItem",
    components: {TeenyiconsPdfOutline, ProductQuery},
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },
    data() {
        return {
            itemcode: this.$route.params.itemcode,
            avail: {},
            item: {},
            lang: "pl",
            head: {},
            itemparams: ref([]),
        };
    },
    methods: {
        searchParams() {
            console.log(this.item.paths[this.lang], toRaw(this.itemparams).join(","))
            this.$router.push({path: '/s/' + this.item.paths[this.lang], query: {v: toRaw(this.itemparams).join(",")}})
            /*
            this.$router.push({
                name: "stock",
                params: {
                    category: this.item.paths[this.lang],
                },
                query: {
                    v: toRaw(this.itemparams).join(","),
                },
            });*/
        },
        productQuery() {
            openModal(ProductQuery, {_product: this.item, _avail: this.avail})
        }
    },
    mounted() {
        this.lang = this.store.lang ? this.store.lang : "pl";
        var that = this;
        this.$root.coverstages["product"] = "loading.product";

        this.$axios
            .get("/_i/" + this.itemcode, {
                params: {
                    ver: this.store.version,
                },
            })
            .then((response) => {
                delete this.$root.coverstages["product"];
                console.log(
                    "product received",
                    response.data.product,
                    that.lang,
                    that.store.lang
                );
                console.log("lodefak");
                that.item = response.data.product;
                that.head = response.data.head;
            });
        this.$root.coverstages["avail"] = "loading.avail";
        this.$axios
            .get("/_pd/", {
                params: {
                    ics: this.itemcode,
                    ver: this.store.version,
                },
            })
            .then((response) => {
                delete this.$root.coverstages["avail"];
                console.log(
                    "avail received",
                    response.data.avail
                );
                that.avail = response.data.avail;
            });

        this.mitt.on("chlang", (lang) => {
            //console.log('recv lang', lang)
            this.lang = lang;

            if (this.$route.path.startsWith("/p/")) {
                var newquery = {...this.$route.query};
                newquery.l = lang;
                //console.log('recv chlang', this.item.lang[lang])
                this.$router.push({
                    path: "/p/" + this.itemcode,
                    query: newquery,
                });
            }
        });
    },
    computed: {
        usertext() {
            if (
                this.item &&
                this.item.lang &&
                this.item.lang[this.lang] &&
                this.item.lang[this.lang].usertext
            )
                return this.item.lang[this.lang].usertext.replace(
                    /(\r|\r\n|\n)/g,
                    "\r\n"
                );
            return "";
        },
        itemname() {
            if (
                this.item &&
                this.item.lang &&
                this.item.lang[this.lang] &&
                this.item.lang[this.lang].itemname
            ) {
                this.$setPageTitle(this.item.lang[this.lang].itemname);
                return this.item.lang[this.lang].itemname;
            }
            return "";
        },
        datasheet() {
            if (this.item && this.item.pdfs)
                for (var i = 0; i < this.item.pdfs.length; i++) {
                    if (this.item.pdfs[i].type == "DataSheet")
                        return this.item.pdfs[i].code;
                }
            return "";
        },
        equivs() {
            var equivs = [];
            if (this.item && this.item.alts) {
                console.log("alts");
                for (const key in this.item.alts) {
                    if (Object.prototype.hasOwnProperty.call(this.item.alts, key)) {
                        for (var i = 0; i < this.item.alts[key].length; i++) {
                            var equiv = this.item.alts[key][i];
                            if (equiv.lang == this.lang) {
                                equiv.itemcode = key;
                                equivs.push(equiv);
                            }
                        }
                    }
                }
            }
            return equivs;
        },
    },
};
</script>

<style>
#pitem table {
    margin-bottom: 2em;
}

#pitem table {
    margin-right: 1em;
}

#pitem label {
    display: inline-block;
    width: 100%;
}

#pitem .avail tr:not(:last-child),
#pitem .quantum tr:not(:last-child) {
    height: 1em;
}

#pitem .toptables {
    display: flex;
    flex-wrap: wrap;
}

#pitem .itemparams tr:hover td,
#pitem .itemparams tr:hover th:not(.title) {
    background: #ff9090;
}

#pitem .itemparams tr:nth-child(even) {
    background: #eee;
}

.dark #pitem .itemparams tr:nth-child(even) {
    background: #666;
}

.dark #pitem .itemparams tr:hover td:not(:last-child),
.dark #pitem .itemparams tr:hover th:not(.title) {
    background: #e30016;
}

.dark img {
    background: #eee;
}

#pitem th.title {
    width: 30vw;
}

#pitem .toptables th.title {
    width: auto;
}

#pitem .man_www {
    display: block;
}

#pitem .quantity {
    text-align: right;
}

#pitem table .title {
    background: #f4f4f4;
    border-top: 1px solid #000;
}

.dark #pitem table .title {
    background: #3d3d3d;
    border-top: 1px solid #eee;
}

#pitem table.itemparams .checkbox {
    text-align: left;
}

#pitem .button {
    background: #e30016;
}

.button {
    border-radius: 12px;
    border: 1px solid #ff9090;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    padding: 1em 1em;
    text-decoration: none;
    font-size: 0.8em;
}

.button:hover {
    background-color: #5cbf2a;
}

.button:active {
    position: relative;
    top: 1px;
}

/* spacing before symbol */
#pitem .itemparams label span:not(:first-child) {
    margin-left: 1em;
}

#pitem .itemparams button {
    float: right;
    margin-right: 2em;
}

#pitem .itemparams .val {
    text-align: right;
}

.availquery button {
    width: 100%;
}
</style>
