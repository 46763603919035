<template>
    <h3>{{ getLocalized().title }}</h3>
  dis
    <xmp>{{ dist }}</xmp>
  is
    <div v-html="getLocalized().html"></div>
</template>

<script>
import {useStore} from "@/stores/page.js";
import {inject, toRaw} from "vue";
import CategoryTreeItem from "@/components/CategoryTree/CategoryTreeItem.vue";

export default {
    name: "DistributionsPage",
    props: {
        company: {
            type: String,
        }
    },
    setup() {
        const store = useStore();
        const mitt = inject("mitt");
        return {store, mitt};
    },
    data: function () {
        return {
            dist: {}
        }
    },
    mounted() {
        this.getDist(this.company)
    },
    methods: {
        getLocalized() {
            var lang = this.store.lang;
            if (this.dist && this.dist[lang])
                return this.dist[lang];
            return {title: 'eno ' + lang};
        },
        getDist(company) {
            var page = (typeof company == 'string' ? '.' + company : '');
            console.log('getdist', page);
            this.$axios

                .get("/_pg/distributions" + page + '?c=1', {})
                .then((response) => {
                    console.log(response);
                    this.dist = typeof company == 'string' ? response.data['distributions' + page] : response.data;
                });
        },
    }
}
</script>

<style scoped>
</style>
