<template>
    <div>
        <img
                ref="img"
                :alt="alt"
                :src="src"
                :title="alt"
                class="pim"
                @mouseleave="hide()"
                @mouseover="show($event)"
        />
    </div>
</template>
<script>
import {inject, ref} from "vue";
import {useStore} from "@/stores/page";

export default {
    name: "StockImage",
    setup() {
        const mitt = inject("mitt");
        const store = useStore();
        const img = ref(null);

        return {mitt, store, img};
    },
    props: {
        src: String,
        alt: String,
        row: String,
    },
    methods: {
        show: function (event) {
            var o = {
                x: event.clientX - event.target.offsetLeft,
                y: event.clientY - event.target.offsetTop,
                src: this.src,
                title: this.alt,
            };
            if (!this.$isMobile()) this.mitt.emit("showprodimg", o);
        },
        hide: function () {
            if (!this.$isMobile()) this.mitt.emit("hideprodimg");
        },
    },
    mounted() {
    },
};
</script>
<style>
img.pim {
    max-width: 5em;
    max-height: 4em;
}

img.hidden {
    display: none;
}

</style>
