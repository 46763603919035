<template>
    <li>
        <div :class="{ bold: isFolder, sub: depth }" @click="toggle()">
            <!-- class="stock" -->
            <!-- <a :href="'/s/' + item.path + '/'" @click.prevent.self="gohere">
              <span v-if="isFolder" :class="{ selected: selected }">{{
                item.open || isOpen ? "&#128449;" : "&#128448;"
              }}</span>
              {{ item.name }}
              <span v-if="item.count">({{ item.count }})</span>
            </a>-->
            <router-link :to="'/s/' + item.path + '/'">
        <span v-if="isFolder" :class="{ selected: selected }">{{
            item.open || isOpen ? "&#128449;" : "&#128448;"
            }}</span>
                {{ item.name }}
                <span v-if="item.count">({{ item.count }})</span>
            </router-link>
        </div>
        <ul v-if="isFolder" v-show="isOpen || item.open">
            <CategoryTreeItem
                    v-for="(child, index) in item.children"
                    :key="index"
                    :depth="1"
                    :item="child"
                    class="item"
                    @opencat="opencat()"
            ></CategoryTreeItem>
        </ul>
    </li>
</template>
<script>
import {inject, toRaw} from "vue";
import {useStore} from "@/stores/page";

export default {
    name: "CategoryTreeItem",
    emits: ["opencat"],
    setup() {
        const mitt = inject("mitt");
        const store = useStore();

        return {mitt, store};
    },
    props: {
        item: Object,
        name: String,
        depth: Number,
    },
    computed: {
        isFolder: function () {
            return this.item.children && this.item.children.length;
        },
    },
    data: function () {
        return {
            isOpen: false,
            selected: false,
            // localitem: {}
        };
    },
    methods: {
        toggle: function () {
            if (this.isFolder) {
                this.isOpen = !this.isOpen;
            }
        },
        opencat: function () {
            this.isOpen = true;
            console.log("opencat", toRaw(this.item));

            this.$emit("opencat");
        },
    },
    mounted() {
        var that = this;
        this.mitt.on("closecat", function (but) {
            var pathparts = but.split("/");
            var found = false;
            for (var i = 0; i <= pathparts.length; i++)
                if (that.item.path == pathparts.slice(0, i).join("/")) found = true;
            if (!found) that.isOpen = false;
        });
        if (this.$route.path.replace(/^\/s\//, "") == this.item.path) {
            this.mitt.emit("closecat", this.item.path);
            this.opencat();
        }
    },
};
</script>
<style scoped>
li {
    list-style-type: none;
}

a {
    text-decoration: none;
    display: inline-block;
    width: 100%;
}

li:hover > div > a:hover {
    background: #aaa;
}

.dark li:hover > div > a:hover {
    background: #555;
}
</style>
